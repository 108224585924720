import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from './../AppPool';
import "./CapaWorkLikeButton.css";
import { isSet } from '../util/typeu';
import { lng } from '../ulanguages/language';

@observer
class CapaWorkLikeButton extends Component {
    constructor(props)
    {
        super(props);
        this.state = {animate:false};
        this.sendLike = this.sendLike.bind(this);
    }
  componentDidMount()
  {

  }
  sendLike(resourceType,resourceId,value)
  {
    if (MyAppPool.cAuth.isGuest())
    {
      MyAppPool.cAuth.activateGlobalLoginBox();
      return;
    }
      MyAppPool.cVotes.sendWorkResourceLikeDislike(resourceType,resourceId,value)
      .then(()=>{
        this.setState({animate:true});
        setTimeout(()=>{this.setState({animate:false})},1000);
      })
      .catch((e)=>{ console.error("Error sendind resource like/dislike",e);});
      
  }

  render(){

    let {resourceType,resourceId} = this.props;
    let {animate} = this.state;

    let userVote = MyAppPool.session.user.votes[''+resourceType][''+resourceId];
    if (typeof userVote ==='undefined')
      userVote=0;

    let icon = "fas fa-heart likeicon no-love";
    if (userVote)
    {
      icon = "fas fa-heart likeicon full-love";
    }

    let myClassName = "uk-button uk-button-default comp_capalikebtn lovebutton ";
    let receivingClassName = this.props.className;
    if (isSet(receivingClassName))
      myClassName = myClassName+ receivingClassName;
    return (
        <button aria-label={lng('Favoritar')} className={myClassName} onClick={()=>this.sendLike(resourceType,resourceId,1)}>
              <i className={"relative "+icon}>
              {animate?
              <div className="outerCicle">
              </div> : <></>
              }
              {animate?
              <div className="innerCircle">
              </div> : <></>
              }
              {animate?
                <div className="rotatingStuffies"> 
                  <div className="littleBall b1"></div>
                  <div className="littleBall b2"></div>
                  <div className="littleBall b3"></div>
                  <div className="littleBall b4"></div>
                  <div className="littleBall b5"></div>
                  <div className="littleBall b6"></div>
                  <div className="littleBall b7"></div>
                  <div className="littleBall b8"></div>
                  <div className="littleBall b9"></div>
                  <div className="littleBall b10"></div>
                  <div className="littleBall b11"></div>
                  <div className="littleBall b12"></div>
                </div> : <></>}
                <i className={"shadowBtn far fa-heart likeicon"}></i></i> 
        </button>
        );
}
}

CapaWorkLikeButton.propTypes = {
  resourceType: PropTypes.number.isRequired,
  resourceId: PropTypes.number.isRequired
};
CapaWorkLikeButton.defaultProps = {
    resourceType: 0,
    resourceId: 1
};

export default CapaWorkLikeButton;
