import MyAppPool from '../AppPool';
import { animeFastResourceType, isSet, mangaFastResourceType, mobToObj } from './../util/typeu';
import { convertAnimeStatusNumberToName, convertMangaStatusNumberToName, getImgAddress, getPrettyUrlName } from './../util/text';
import { lng } from '../ulanguages/language';
import { getCharacterRoute, getCompanyRoute, getMagazineRoute, getObraRoute, getPersonRoute } from '../store/AppRoutes';
import { getCurrentDomain } from '../util/windowUtil';


function renderElemInfo(info)
{
    if (!Array.isArray(info))
        return '';
    return  (info.map((element, index) => {
        if (element.type=='url')
        {
            return ('<a href="https://'+getCurrentDomain()+element.value+'">'+getPrettyUrlName(element.display)+'</a>');
        }
        return ('<span>'+element.display+'</span>') ;
        }));
}

export default class PageJsonSchemaController
{
    makeAnimeJsonSchema()
    {
        let data = MyAppPool.session.anime.animes[MyAppPool.session.anime.currentAnime];
        if (!isSet(data) || data.empty)
            return;
        //console.log("medata::",mobToObj(data));
        let ldType='TVSeries';
        if (data.type==='Movie')
            ldType='Movie';
        let ldQuestions = [];
        let ldActors = [];
        let ldCharacters = [];
        for(let i=0; i<data.characters.length; i++)
        {
            let myChar = data.characters[i];
            let ldCharacter = {
                "@type": "Person",
                "name": myChar.name + ' '+myChar.familyname,
                "givenName": myChar.name,
                "familyName":myChar.familyname,
                "alternateName":myChar.namejp,
                "description":myChar.description,
                "mainEntityOfPage": getCurrentDomain()+ getCharacterRoute(myChar.characterid, myChar.name + ' '+myChar.familyname),
            }
            ldCharacters[ldCharacters.length] = ldCharacter;
            for(let k=0; k<myChar.voiceactors.length; k++)
            {
                let voiceActor = myChar.voiceactors[k];
               
                let ldActor = {
                    "@type": "Person",
                    "name": voiceActor.name + ' '+voiceActor.familyname,
                    "givenName": voiceActor.name,
                    "familyName":voiceActor.familyname,
                    "alternateName":voiceActor.namejp,
                    "nationality":voiceActor.nationality,
                    "description":voiceActor.description,
                    "mainEntityOfPage": getCurrentDomain()+ getPersonRoute(voiceActor.personid, voiceActor.name + ' '+voiceActor.familyname),
                }
                ldActors[ldActors.length] = ldActor;
            }
        }

        let ldDirectors = [];
        let ldMusicPerformances = [];
        let ldAuthors = [];
        let ldContributors = [];
        for(let i=0; i<data.people.length; i++)
        {
            let person = data.people[i];
            let personOccupations = [];
            if (person.roles)
            {
                for (let k=0;k<person.roles.length;k++)
                {
                    let myRole = person.roles[k];
                    let salaryValue = myRole.salary;
                    let salary = {
                        "@type": "MonetaryAmountDistribution",
                        "name": "base",
                        "currency": "JPY",
                        "duration": "P1Y",
                        "percentile10": ''+(salaryValue/2),
                        "percentile25": ''+(salaryValue/1.5),
                        "median": salaryValue,
                        "percentile75": ''+(salaryValue*1.5),
                        "percentile90": ''+(salaryValue*2)
                    };

                    personOccupations[personOccupations.length] = {
                        "@type": "Occupation",
                        "name": myRole.role,
                        "description":myRole.description,
                        "estimatedSalary": salary,
                        "occupationLocation": {
                            "@type": "Country",
                            "name": lng('Japão')
                        }
                    };
                }
            }else
            {
                let salaryValue = person.salary;
                let salary = {
                    "@type": "MonetaryAmountDistribution",
                    "name": "base",
                    "currency": "JPY",
                    "duration": "P1Y",
                    "percentile10": ''+(salaryValue/2),
                    "percentile25": ''+(salaryValue/1.5),
                    "median": salaryValue,
                    "percentile75": ''+(salaryValue*1.5),
                    "percentile90": ''+(salaryValue*2)
                };

                personOccupations[personOccupations.length] = {
                    "@type": "Occupation",
                    "name": person.role,
                    "description":person.roledescription,
                    "estimatedSalary": salary,
                        "occupationLocation": {
                            "@type": "Country",
                            "name": lng('Japão')
                        }
                };
            }

            let ldPerson = {
                "@type": "Person",
                "name": person.name + ' '+person.familyname,
                "givenName": person.name,
                "familyName":person.familyname,
                "alternateName":person.namejp,
                "description":person.description,
                "hasOccupation":personOccupations,
                "mainEntityOfPage": getCurrentDomain() + getPersonRoute(person.personid,person.name + ' '+person.familyname)
            }
            if (person.rolesTogether)
            {
                if (person.rolesTogether.toLowerCase().indexOf('creator')!==-1||
                person.rolesTogether.toLowerCase().indexOf('criador')!==-1||
                person.rolesTogether.toLowerCase().indexOf('creador')!==-1||
                person.rolesTogether.toLowerCase().indexOf('ursprünglicher schöpfer')!==-1)
                {
                    ldAuthors[ldAuthors.length] = ldPerson;
                }
                if (person.rolesTogether.toLowerCase().indexOf('theme')!==-1||
                person.rolesTogether.toLowerCase().indexOf('temá')!==-1||
                person.rolesTogether.toLowerCase().indexOf('tema')!==-1||
                person.rolesTogether.toLowerCase().indexOf('tema')!==-1||
                person.rolesTogether.toLowerCase().indexOf('music')!==-1)
                {
                    ldMusicPerformances[ldMusicPerformances.length] = ldPerson;
                }
                if (person.rolesTogether.toLowerCase().indexOf('dire')!==-1)
                {
                    ldDirectors[ldDirectors.length] = ldPerson;
                }
            }
            ldContributors[ldContributors.length] = ldPerson;
        }

        let ldProductionCompanies = [];
        for(let i=0; i<data.producers.length; i++)
        {
            let ldProducer = {
                "@type": "Organization",
                "name":data.producers[i].name,
                "mainEntityOfPage": getCurrentDomain() + getCompanyRoute(data.producers[i].id,data.producers[i].name)
            };
            ldProductionCompanies[ldProductionCompanies.length]=ldProducer;
        }

        let ldPublishers = [];
        for(let i=0; i<data.licensors.length; i++)
        {
            let ldPublisher = {
                "@type": "Organization",
                "name":data.licensors[i].name,
                "mainEntityOfPage": getCurrentDomain() + getCompanyRoute(data.licensors[i].id,data.licensors[i].name)
            };
            ldPublishers[ldPublishers.length]=ldPublisher;
        }
        let ldTitles = [];
        for(let i=0; i<data.titles.length; i++)
        {
            ldTitles[i] = data.titles[i].title;
        }

        let ldGenres = [];
        for(let i=0; i<data.genres.length; i++)
        {
            ldGenres[i] = data.genres[i].name;
        }

        let ldRating=undefined;
        if (data.grades.totalvotes>0)
        {
            ldRating={
                "@type": "AggregateRating",
                "ratingCount":data.grades.totalvotes,
                "ratingValue":data.grades.averagegrade,
                "itemReviewed":{
                    "@type":  "Thing",
                    "name":data.mainTitle
                }
            }
        }   
        let starterQuestion = '';
        let question = '';
        let useName = '';
        let ldQuestion = {};
        for(let i=0;i<data.columItems.length;i++)
        {
            let item = data.columItems[i];
            let titleFinisher = ' '+lng('de') +' '+data.mainTitle + ' ?';
            let male=false;
            useName=item.name;
            if (item.name===lng('Finale'))
                useName=lng('Data do Fim da Exibição');
            if (item.name===lng('Estúdio(s)'))
                male=true;
            if (item.name===lng('Exibição'))
            {
                useName=lng('dia de exibição');
                male=true;
            }
            if (item.name===lng('Tipo'))
                male=true;
            
            if (item.info.length>1)
            {
                starterQuestion=lng('Quais são as') + ' ';
                if (male)
                    starterQuestion=lng('Quais são os') + ' ';
            }else
            {
                starterQuestion=lng('Qual é a') + ' ';
                if (male)
                    starterQuestion=lng('Qual é o') + ' ';
            }
            if (item.name===lng('Episódios'))
            {
                starterQuestion=lng('Quantos')+' ';
                titleFinisher = ' '+lng('_tem') +' '+data.mainTitle + ' ?';
            }
            question=starterQuestion+useName+titleFinisher;
            let answer = useName + ':' +'<b>'+ renderElemInfo(item.info)+'</b>';
            ldQuestion={
                "@type": "Question",
                "name": question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": answer
                }
            }
            ldQuestions.push(ldQuestion);         
        }

        
        let retVal = [{
            "@context": "https://schema.org",
            "@type": "TVSeries",
            "actor": ldActors,
            "director": ldDirectors,
            "character": ldCharacters,
            "author":ldAuthors,
            "contributor":ldContributors,
            "description":data.description,
            "name":data.mainTitle,
            "startDate":data.airfrom,
            "endDate":data.airto,
            "numberOfEpisodes":data.episodecount,
            "musicBy":ldMusicPerformances,
            "productionCompany":ldProductionCompanies,
            "producer":ldProductionCompanies,
            "publisher":ldPublishers,
            "image":getImgAddress(data.mainimg),
            "aggregateRating":ldRating,
            "alternativeHeadline":ldTitles,
            "headline":data.mainTitle,
            "contentRating":data.rating,
            "creativeWorkStatus":convertAnimeStatusNumberToName(data.animestatus),
            "creator":ldAuthors,
            "dateCreated":data.airfrom,
            "genre":ldGenres,
            "mainEntityOfPage": getCurrentDomain() + getObraRoute(data.id,data.mainTitle,"anime")
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": ldQuestions
          }
        ];
          //console.log(retVal);
          MyAppPool.session.jsonld = retVal;

          return retVal;
    }



    makeMangaJsonSchema()
    {
        let data = MyAppPool.session.manga.mangas[MyAppPool.session.manga.currentManga];
        if (!isSet(data) || data.empty)
            return;
        let ldActors = [];
        let ldCharacters = [];
        for(let i=0; i<data.characters.length; i++)
        {
            let myChar = data.characters[i];
            let ldCharacter = {
                "@type": "Person",
                "name": myChar.name + ' '+myChar.familyname,
                "givenName": myChar.name,
                "familyName":myChar.familyname,
                "alternateName":myChar.namejp,
                "description":myChar.description,
                "mainEntityOfPage": getCurrentDomain() + getCharacterRoute(myChar.characterid,myChar.name + ' '+myChar.familyname)
            }
            ldCharacters[ldCharacters.length] = ldCharacter;
        }

        let ldAuthors = [];
        let ldContributors = [];
        for(let i=0; i<data.people.length; i++)
        {
            let person = data.people[i];
            let personOccupations = [];
            if (person.roles)
            {
                for (let k=0;k<person.roles.length;k++)
                {
                    let myRole = person.roles[k];
                    let salaryValue = myRole.salary;
                    let salary = {
                        "@type": "MonetaryAmountDistribution",
                        "name": "base",
                        "currency": "JPY",
                        "duration": "P1Y",
                        "percentile10": ''+(salaryValue/2),
                        "percentile25": ''+(salaryValue/1.5),
                        "median": salaryValue,
                        "percentile75": ''+(salaryValue*1.5),
                        "percentile90": ''+(salaryValue*2)
                    };
                    personOccupations[personOccupations.length] = {
                        "@type": "Occupation",
                        "name": myRole.role,
                        "description":myRole.description,
                        "estimatedSalary": salary,
                        "occupationLocation": {
                            "@type": "Country",
                            "name": lng('Japão')
                        }
                    };
                }
            }else
            {
                let salaryValue = person.salary;
                let salary = {
                    "@type": "MonetaryAmountDistribution",
                    "name": "base",
                    "currency": "JPY",
                    "duration": "P1Y",
                    "percentile10": ''+(salaryValue/2),
                    "percentile25": ''+(salaryValue/1.5),
                    "median": salaryValue,
                    "percentile75": ''+(salaryValue*1.5),
                    "percentile90": ''+(salaryValue*2)
                };
                personOccupations[personOccupations.length] = {
                    "@type": "Occupation",
                    "name": person.role,
                    "estimatedSalary": salary,
                    "description":person.roledescription,
                    "occupationLocation": {
                        "@type": "Country",
                        "name": lng('Japão')
                    }
                };
            }
            let ldPerson = {
                "@type": "Person",
                "name": person.name + ' '+person.familyname,
                "givenName": person.name,
                "familyName":person.familyname,
                "alternateName":person.namejp,
                "description":person.description,
                "hasOccupation":personOccupations,
                "mainEntityOfPage": getCurrentDomain() +getPersonRoute(person.personid, person.name + ' '+person.familyname)
            }
            if (person.rolesTogether)
            {
                if (person.rolesTogether.toLowerCase().indexOf('creator')!==-1||
                person.rolesTogether.toLowerCase().indexOf('criador')!==-1||
                person.rolesTogether.toLowerCase().indexOf('creador')!==-1||
                person.rolesTogether.toLowerCase().indexOf('ursprünglicher schöpfer')!==-1)
                {
                    ldAuthors[ldAuthors.length] = ldPerson;
                }
            }

            ldContributors[ldContributors.length] = ldPerson;
        }

        let ldPublishers = [];
        for(let i=0; i<data.publishers.length; i++)
        {
            let ldPublisher = {
                "@type": "Organization",
                "name":data.publishers[i].name,
                "mainEntityOfPage": getCurrentDomain() + getMagazineRoute(data.publishers[i].id,data.publishers[i].name)
            };
            ldPublishers[ldPublishers.length]=ldPublisher;
        }
        let ldTitles = [];
        for(let i=0; i<data.titles.length; i++)
        {
            ldTitles[i] = data.titles[i].title;
        }

        let ldGenres = [];
        for(let i=0; i<data.genres.length; i++)
        {
            ldGenres[i] = data.genres[i].name;
        }

        let ldRating=undefined;
        if (data.grades.totalvotes>0)
        {
            ldRating={
                "@type": "AggregateRating",
                "ratingCount":data.grades.totalvotes,
                "ratingValue":data.grades.averagegrade,
                "itemReviewed":{
                    "@type":  "Thing",
                    "name":data.mainTitle
                }
            }
        }

        let starterQuestion = '';
        let question = '';
        let useName = '';
        let ldQuestion = {};
        let ldQuestions = [];
        for(let i=0;i<data.columItems.length;i++)
        {
            let item = data.columItems[i];
            let titleFinisher = ' '+lng('de') +' '+data.mainTitle + ' ?';
            let male=false;
            useName=item.name;
            if (item.name===lng('Finale'))
                useName=lng('Data do Fim da Exibição');
            if (item.name===lng('Tipo'))
                male=true;
            
            if (item.info.length>1)
            {
                starterQuestion=lng('Quais são as') + ' ';
                if (male)
                    starterQuestion=lng('Quais são os') + ' ';
            }else
            {
                starterQuestion=lng('Qual é a') + ' ';
                if (male)
                    starterQuestion=lng('Qual é o') + ' ';
            }
            if (item.name===lng('Capítulos'))
            {
                if (Number(item.info[0].display)==0)
                    continue;
                starterQuestion=lng('Quantos')+' ';
                titleFinisher = ' '+lng('_tem') +' '+data.mainTitle + ' ?';
            }
            if (item.name===lng('Volumes'))
            {
                if (Number(item.info[0].display)==0)
                    continue;
                starterQuestion=lng('Quantos')+' ';
                titleFinisher = ' '+lng('_tem') +' '+data.mainTitle + ' ?';
            }
            question=starterQuestion+useName+titleFinisher;
            let answer = useName + ':' +'<b>'+ renderElemInfo(item.info)+'</b>';
            ldQuestion={
                "@type": "Question",
                "name": question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": answer
                }
            }
            ldQuestions.push(ldQuestion);         
        }
        
        let retVal = [{
            "@context": "https://schema.org",
            "@type": "ComicSeries",
            "character": ldCharacters,
            "author":ldAuthors,
            "contributor":ldContributors,
            "description":data.description,
            "name":data.mainTitle,
            "startDate":data.airfrom,
            "endDate":data.airto,
            "publisher":ldPublishers,
            "image":getImgAddress(data.mainimg),
            "aggregateRating":ldRating,
            "alternativeHeadline":ldTitles,
            "headline":data.mainTitle,
            "creativeWorkStatus":convertMangaStatusNumberToName(data.mangastatus),
            "creator":ldAuthors,
            "dateCreated":data.airfrom,
            "genre":ldGenres,
            "mainEntityOfPage": getCurrentDomain() + getObraRoute(data.id,data.mainTitle,"manga")
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": ldQuestions
          }]
          MyAppPool.session.jsonld = retVal;
          return retVal;
    }


    makePersonJsonSchema()
    {
        let data = MyAppPool.session.person.persons[MyAppPool.session.person.currentPerson];
        if (!isSet(data) || data.empty)
            return;
        let personOccupations=[];
        for (let i=0;i<data.personAnimePositions;i++)
        {
            let salaryValue = data.personAnimePositions[i].salary;
            let salary = {
                "@type": "MonetaryAmountDistribution",
                "name": "base",
                "currency": "JPY",
                "duration": "P1Y",
                "percentile10": ''+(salaryValue/2),
                "percentile25": ''+(salaryValue/1.5),
                "median": salaryValue,
                "percentile75": ''+(salaryValue*1.5),
                "percentile90": ''+(salaryValue*2)
            };
            personOccupations[personOccupations.length] = {
                "@type": "Occupation",
                "name": data.personAnimePositions[i],
                "estimatedSalary": salary,
                "description":data.personAnimePositions[i].roledescription,
                "occupationLocation": {
                    "@type": "Country",
                    "name": lng('Japão')
                }
            };
        }
        for (let i=0;i<data.personMangaPositions;i++)
        {
            let salaryValue = data.personMangaPositions[i].salary;
            let salary = {
                "@type": "MonetaryAmountDistribution",
                "name": "base",
                "currency": "JPY",
                "duration": "P1Y",
                "percentile10": ''+(salaryValue/2),
                "percentile25": ''+(salaryValue/1.5),
                "median": salaryValue,
                "percentile75": ''+(salaryValue*1.5),
                "percentile90": ''+(salaryValue*2)
            };
            personOccupations[personOccupations.length] = {
                "@type": "Occupation",
                "name": data.personMangaPositions[i],
                "estimatedSalary": salary,
                "description":data.personMangaPositions[i].roledescription,
                "occupationLocation": {
                    "@type": "Country",
                    "name": lng('Japão')
                }
            };
        }

        let starterQuestion = '';
        let question = '';
        let useName = '';
        let ldQuestion = {};
        let ldQuestions = [];
        for(let i=0;i<data.columItems.length;i++)
        {
            let item = data.columItems[i];
            let titleFinisher = ' '+lng('de') +' '+data.fullName + ' ?';
            let male=false;
            useName=item.name;
            if (item.name===lng('Website'))
                male=true;
            if (item.name.indexOf('tipo')!==-1 || item.name.indexOf('Tipo')!==-1 || item.name.indexOf('avorito')!==-1)
                male=true;

            if (item.info.length>1)
            {
                starterQuestion=lng('Quais são as') + ' ';
                if (male)
                    starterQuestion=lng('Quais são os') + ' ';
            }else
            {
                starterQuestion=lng('Qual é a') + ' ';
                if (male)
                    starterQuestion=lng('Qual é o') + ' ';
            }

            let answer = useName + ':' +'<b>'+ renderElemInfo(item.info)+'</b>';
            if (item.name==lng('Nasceu em'))
            {
                useName='';
                starterQuestion=lng('Qual a data de nascimento') + ' ';
            }
            question=starterQuestion+useName+titleFinisher;
            ldQuestion={
                "@type": "Question",
                "name": question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": answer
                }
            }
            ldQuestions.push(ldQuestion);         
        }
        

        let retVal = [{
            "@context": "https://schema.org",
            "@type": "Person",
            "name": data.name + ' '+data.familyname,
            "givenName": data.name,
            "familyName":data.familyname,
            "alternateName":data.namejp,
            "description":data.description,
            "hasOccupation":personOccupations,
            "image":getImgAddress(data.mainimg),
            "mainEntityOfPage": getCurrentDomain() + getPersonRoute(data.id,data.name + ' '+data.familyname)
        },
        {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": ldQuestions
        }]
        MyAppPool.session.jsonld = retVal;
        return retVal;
    }

    makeCharacterJsonSchema()
    {
        let data = MyAppPool.session.character.characters[MyAppPool.session.character.currentCharacter];
        if (!isSet(data) || data.empty)
            return;
        let starterQuestion = '';
        let question = '';
        let useName = '';
        let ldQuestion = {};
        let ldQuestions = [];
        for(let i=0;i<data.columItems.length;i++)
        {
            let item = data.columItems[i];
            let titleFinisher = ' '+lng('de') +' '+data.fullName + ' ?';
            let male=false;
            useName=item.name;
            try{
                if (item.info[0].display.trim().length===0)
                    continue;
            }catch(error){
                console.error(error);
            }
            if (item.name===lng('Website'))
                male=true;
            if (item.name.indexOf('tipo')!==-1 || item.name.indexOf('Tipo')!==-1 || item.name.indexOf('avorito')!==-1)
                male=true;

            if (item.info.length>1)
            {
                starterQuestion=lng('Quais são as') + ' ';
                if (male)
                    starterQuestion=lng('Quais são os') + ' ';
            }else
            {
                starterQuestion=lng('Qual é a') + ' ';
                if (male)
                    starterQuestion=lng('Qual é o') + ' ';
            }

            let answer = useName + ':' +'<b>'+ renderElemInfo(item.info)+'</b>';
            if (item.name==lng('Nasceu em'))
            {
                useName='';
                starterQuestion=lng('Qual a data de nascimento') + ' ';
            }
            question=starterQuestion+useName+titleFinisher;
            ldQuestion={
                "@type": "Question",
                "name": question,
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": answer
                }
            }
            ldQuestions.push(ldQuestion);         
        }

        let retVal = [{
            "@context": "https://schema.org",
            "@type": "Person",
            "name": data.name + ' '+data.familyname,
            "givenName": data.name,
            "familyName":data.familyname,
            "alternateName":data.namejp,
            "description":data.description,
            "image":getImgAddress(data.mainimg),
            "mainEntityOfPage": getCurrentDomain() + getCharacterRoute(data.id,data.name + ' '+data.familyname)
        },
        {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": ldQuestions
        }]

        
        MyAppPool.session.jsonld = retVal;
        return retVal;
    }

    makeCompanyJsonSchema()
    {
        let data = MyAppPool.session.company.companies[MyAppPool.session.company.currentCompany];
        if (!isSet(data) || data.empty)
            return;
        let rating = undefined;
        if (data.grades.totalvotes>0)
            rating = {
                "@type": "AggregateRating",
                "ratingCount":data.grades.totalvotes,
                "ratingValue":data.grades.averagegrade,
                "itemReviewed":{
                    "@type":  "Thing",
                    "name":data.name
                }};

        let retVal = {
            "@type": "Organization",
            "name":data.name,
            "description":data.description,
            "aggregateRating":rating,
            "mainEntityOfPage": getCurrentDomain() + getCompanyRoute(data.id,data.name)
        };
        MyAppPool.session.jsonld = retVal;
        return retVal;
    }

    makeMagazineJsonSchema()
    {
        let data = MyAppPool.session.publisher.publishers[MyAppPool.session.publisher.currentPublisher];
        if (!isSet(data) || data.empty)
            return;
        let rating = undefined;
        if (data.grades.totalvotes>0)
            rating = {
                "@type": "AggregateRating",
                "ratingCount":data.grades.totalvotes,
                "ratingValue":data.grades.averagegrade,
                "itemReviewed":{
                    "@type":  "Thing",
                    "name":data.name
                }};

        let retVal = {
            "@type": "Organization",
            "name":data.name,
            "description":data.description,
            "aggregateRating":rating,
            "mainEntityOfPage": getCurrentDomain() + getMagazineRoute(data.id,data.name)
        };
        MyAppPool.session.jsonld = retVal;
        return retVal;
    }

}