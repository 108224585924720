
import { computed, toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getCompanyRoute } from '../store/AppRoutes';
import { getCurrentLanguage } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { contentTypeToFastType, isValidIndex, isSet } from '../util/typeu';
import { isServer } from '../util/windowUtil';
import { convertDataToShowable } from '../util/text';



export default class CompanyController
{
    fetchCompanyById(index)
    {
        let loadId;
        if (!isSet(index) || !isValidIndex(index))
            return Promise.resolve(true);
        if (Number(index)===Number(MyAppPool.session.company.currentCompany) && MyAppPool.session.company.currentCompany.ready)
        {
            loadId = MyAppPool.cGlobalLoadingBar.start();
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(true);
        }
        MyAppPool.session.company.companies[index] = {ready:0};
        MyAppPool.session.company.currentCompany= index;
        loadId = MyAppPool.cGlobalLoadingBar.start(1500);

        if (!isServer())
            brasRequest(routes.resourcePopularCount,false,{resourceType:contentTypeToFastType('producer'),resourceId:index});
      
        return fetchAndProcess(this.setSessionCompany,
            routes.getCompanyByIdLng,true,{cid:index,lng: getCurrentLanguage()})
            .then((data) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,true);
                return Promise.resolve(data);
            })
            .catch((e) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,false);
                MyAppPool.session.companyFetchEmpty[''+index]=true;
                console.log("Error Fetching company",e)
            });
    }

    setSessionCompany(data0,ready)
    {
        if (!data0.ok)
            return Promise.reject();
        
        let data = data0.data;

        let relatedCompanyAnimeTypes = {};
        let relatedCompanyAnimeRelations = {};
        if (!data.empty)
        {
            for(let i =0; i<data.related.length; i++)
            {
            relatedCompanyAnimeTypes[data.related[i].type]=1;
            relatedCompanyAnimeRelations[data.related[i].reltype]=1;
            }
        
        relatedCompanyAnimeTypes = Object.keys(relatedCompanyAnimeTypes);
        relatedCompanyAnimeRelations = Object.keys(relatedCompanyAnimeRelations);

        data.columItems = [];
        for(let i=0; i< data.extra.length; i++)
        {
            let item = data.extra[i];
            let content = {};
            if (item.type==='url')
            {
                let displayVal = item.mvalue;
                displayVal = displayVal.replace('/',' ');
                
                content =  {name:item.mkey,info:[{type:item.type,value:item.url,display:displayVal}]};
            }
            else
            {
                let displayVal = item.mvalue;
                if (item.type=='date')
                    displayVal = convertDataToShowable(item.mvalue);
                if (displayVal=='Invalid Date')
                    continue;
                content =  {name:item.mkey,info:[{type:item.type,value:item.mvalue,display:displayVal}]};
            }
            data.columItems[data.columItems.length] =content; 
        }
        
        //console.log(data);
        data.relatedCompanyAnimeTypes = relatedCompanyAnimeTypes;
        data.relatedCompanyAnimeRelations = relatedCompanyAnimeRelations;
        data.baseUrl = getCompanyRoute(data.id,data.name);
        data.resourceType = contentTypeToFastType('producer');
        MyAppPool.session.grades[contentTypeToFastType('producer')][data.id] = data.grades;
        MyAppPool.session.company.companies[data.id] = {...MyAppPool.session.company.companies[data.id],...data} ;
        MyAppPool.session.company.companies[data.id].ready = ready?1:0;
        //console.log(data.people);
        
        MyAppPool.session.company.currentCompany=data.id;
        MyAppPool.session.companyFetchEmpty[''+data.id]=false;


        MyAppPool.cPageJsonSchema.makeCompanyJsonSchema();

        return Promise.resolve(true);
        }
        return Promise.reject("empty");
    }

    @computed get currentCompany()
    {
        let data = toJS(MyAppPool.session.company.companies[MyAppPool.session.company.currentCompany]);
        if (!isSet(data))
            return {ready:0};
        data.grades = MyAppPool.session.grades[contentTypeToFastType('producer')][MyAppPool.session.company.currentCompany];
        return data;//MyAppPool.session.company.companies[MyAppPool.session.company.currentCompany];
    }
    
}