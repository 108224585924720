import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { extractYoutubeId } from '../util/text';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import FileSelect from './FileSelect';
class DataImageUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purpose: 'cover image'
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here

    let youtube = extractYoutubeId(this.state.link);
    if (youtube==='')
        return MyAppPool.cAlert.notifyError(lng("Link inválido"),'','data video upload form');
    
    //console.log('Form submitted:', this.state);
  };
  onUpload = (link) => {
    MyAppPool.cModal.deactivate();
  }

  render() {
    return (
        <div>
        <label>
          Purpose:
          <select
            name="purpose"
            value={this.state.purpose}
            onChange={this.handleChange}
            required
          >
            <option value="cover image">{lng('cover image')}</option>
            <option value="banner">{lng('banner')}</option>
          </select>
        </label>
        <br />
        <br /><br />
        <FileSelect service="mabdata" purpose={this.state.purpose} text={lng("Carregar do Dispositivo")} onUpload={this.onUpload} resourceType={this.props.resourceType} resourceId={this.props.resourceId}/>
        </div>
    );
  }
}

DataImageUploadForm.propTypes = {
    resourceType: PropTypes.number.isRequired,
    resourceId: PropTypes.number.isRequired,
};
DataImageUploadForm.defaultProps = {
    resourceType:0,
    resourceId:0
};

export default DataImageUploadForm;