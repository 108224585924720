import React, { Component } from 'react';
import "./WYSIWYGWriter.css";
import WYSIWYGButton from './WYSIWYGButton';
import { realCaretAndSelectionPos, getCurrentCursorPosition, getCurrentCursorSelectionStart, removeEmptyKnownTags, prepareStartContent, prepareContentToSend } from './WYSIWYGWriterUtils';
import { lng } from '../ulanguages/language';
import SpinnerBox from './SpinnerBox';
import { PropTypes } from 'prop-types';

class WYSIWYGWriter extends Component {
    constructor(props) {
        super(props);
        this.state = {htmlCaretPosition: 0,
            htmlSelectionStart:0,
            openTagList:[], 
            activeList: [], 
            actionClasses: {}, 
            actions: [], 
            caretPosition:0,
            currentSelection:'',
            sending:false,
            sendError:''
        };
        this.textChanged = this.textChanged.bind(this);
        this.focusOut = this.focusOut.bind(this);
        this.focus = this.focus.bind(this);
        this.updateTextInfo = this.updateTextInfo.bind(this);
        this.updateTextInfoFinish = this.updateTextInfoFinish.bind(this);
        this.closeClick = this.closeClick.bind(this);
        this.sendClick = this.sendClick.bind(this);

    }
  componentDidMount()
  {
    let propsActions = this.props.actions ? this.props.actions : [];
    let propsActionsClass = this.props.actions ? this.props.actionClasses : {};
    let visible = false;
    if (this.props.visible)
        visible=true;
    let editor = document.getElementById(this.props.id);
    //console.log("START:::",this.props.startContent);
    if (this.props.startContent)
    {
        //console.log("START:::",this.props.startContent);
        editor.innerHTML = prepareStartContent(this.props.startContent);
    }

    this.setState({
        actionClasses :{'bold':'bold', ...propsActionsClass},
        actions : ['bold','italic','underline','strikethrough', ...propsActions],
        caretPosition:0,
        visible
      });
      document.execCommand('styleWithCSS',false,false);
      document.addEventListener("click", this.textChanged, false);
  }


  textChanged(e)
  {
    let editorId = this.props.id;
    let list = [];
    let pos = getCurrentCursorPosition(editorId);
    if (pos===-1) //not this editor bugfix
        return;

    let editor = document.getElementById(editorId);
    removeEmptyKnownTags(['.youtubeEnclosure','.spoiler','.wsyWriterimageWrapper'],editor);

    this.state.actions.map((value,index) => {
        if(document.queryCommandState(value))
            list[list.length] = value;
        return true;
    });

    let startSelection = getCurrentCursorSelectionStart(editorId);
    let editorHtml = editor.innerHTML;
    let editorData = realCaretAndSelectionPos(editorHtml, pos,startSelection);

    this.setState({
        activeList:list,
        caretPosition: pos,
        htmlCaretPosition: editorData.realPos,
        htmlSelectionStart:editorData.selectionRealStart,
        openTagList: editorData.open,
        currentSelection: window.getSelection().toString(),
    });
    this.updateActive = false;
    if (this.props.onUpdateText)
        this.props.onUpdateText(editorHtml);
    //console.log('openTags:',editorData.open);
    //console.log('selection:',this.state.htmlSelectionStart);
    //console.log('caret:',this.state.htmlCaretPosition);
    //console.log(document.getElementById(editorId).innerHTML);
    //console.log(editorId);
    //console.log('sel:',this.state.currentSelection);
    //console.log(this.state.htmlCaretPosition);
    //let lastActive = document.activeElement;
  }
  focusOut(){
    this.setState({active:false});
  }
  focus(){
    this.setState({active:true});
  }
  updateTextInfo(e)
  {
      setTimeout((e)=>this.updateTextInfoFinish(e),400);
  }
  updateTextInfoFinish(e)
  {
      if (this.updateActive)
        return;
      this.updateActive = true;
      this.textChanged(e);
  }
  closeClick()
  {
    let editor = document.getElementById(this.props.id); 
    this.props.onCloseClick(editor.innerHTML);
  }
  sendClick()
  {
    if (this.state.sending)
        return;
    let editor = document.getElementById(this.props.id); 
    if (editor.innerHTML.length===0)
        return;
    this.setState({sending:true});
    let content = prepareContentToSend(editor.innerHTML);
    
    //console.log("before:",editor.innerHTML)
    //console.log('after:',content);
    this.props.onSendClick(content).then(
        (res) => {
            this.setState({sending:false});
            editor.innerHTML = '';
         }
    )
    .catch(e => 
        {console.error(e);
        this.setState({sending:false, sendError:lng('Houve um erro enviando esse conteúdo.')})});
  }
  render(){
    let {actions, actionClasses, active, sending} = this.state;
    let {actionTags} = this.props;
    let activeWriterClass = '';
    let {hasCloseButton,hasSendButton,placeHolder} = this.props;

    //document.activeElement===document.getElementById(editorId)
    if (active)
        activeWriterClass='activeWriter';
    
        
    return (
        <div className="comp_wsywriter">
        <div className="answerWriter">
            <div className={"writeBox "+activeWriterClass} placeholder={placeHolder} id={this.props.id} onKeyDown={this.updateTextInfo} onInput={this.updateTextInfo} onBlur={this.focusOut} onFocus={this.focus} contentEditable></div>
            <div>
                <div className={"uk-flex uk-flex-row bottom "+activeWriterClass}>
                    <div className="toolbox" style={{width:hasCloseButton==false && hasSendButton==false ? "100%" :"70%"}}>
                        {actions.map( (value, index) => {
                            return <WYSIWYGButton onClick={this.textChanged} currentSelection={this.state.currentSelection}htmlSelectionStart={this.state.htmlSelectionStart} htmlCaretPosition={this.state.htmlCaretPosition} openTagList={this.state.openTagList} editorId={this.props.id} key={index} caretPosition={this.state.caretPosition} myTag={actionTags[value]?actionTags[value]:value} iconClass={actionClasses[value]?actionClasses[value]:value} action={value} activeList={this.state.activeList}/>
                         } )}
                    </div>
                    {hasCloseButton==false && hasSendButton==false ? <></> :
                    <div className="buttons">
                        {hasCloseButton? <button aria-label={lng('Fechar')} onClick={this.closeClick} className="uk-button uk-button-secondary closeButton">{lng('Fechar')}</button> : <></> }
                        {hasSendButton? <button aria-label={lng('Enviar')}  onClick={this.sendClick} className="uk-button uk-button-primary sendButton">{sending ? <SpinnerBox/> : lng('Enviar')+'!' }</button> : <></> }
                    </div>
                    }
                </div>
            </div>
            <span>{this.state.sendError}</span>
        </div>
    </div>);
}
}


WYSIWYGWriter.propTypes = {
    currentUserId: PropTypes.number.isRequired,
    actions: PropTypes.arrayOf(PropTypes.string),
    actionClasses: PropTypes.object,
    actionTags: PropTypes.object,
    placeHolder: PropTypes.string
  };

  WYSIWYGWriter.defaultProps = {
    ready:0,
    placeHolder:'',
    hasCloseButton:0,
    hasSendButton:0,
    actions:
    [
        'superscript',
        'link',
        'image',
        'youtube',
        'emoji',
        'spoiler',
        'insertOrderedList',
        'insertUnorderedList'
    ],
    actionClasses:{
        insertOrderedList:'list-ol',
        insertUnorderedList:'list-ul',
        table:'border-all',
        spoiler: 'eye-slash',
        emoji:'grin-squint-tears',
        youtube:'fab fa-youtube'
        },
    actionTags:{
        link:'<a ',
        spoiler:'<span class="spoiler">'
    }
  };

export default WYSIWYGWriter;
