import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
import './ThreadMain2.css'; // Ensure this file exists for styling
import { mobToObj, threadFastResourceType, userFastResourceType } from '../util/typeu';
import MyAppPool from '../AppPool';
import { getImgAddress, getSiteShortName } from '../util/text';
import { getUserAvatar } from '../util/icon';
import { observer } from 'mobx-react';
import htmlToReact from 'html-to-react';
import { lng } from '../ulanguages/language';
import { delay, timeSinceString } from '../util/timeUtil';
import ThreadTag from './ThreadTag';
import LinkV2 from './LinkV2';
import { getForumSectionPath, getForumTagPath, getForumThreadPage, getUserRoute } from '../store/AppRoutes';
import TitleBadge from './TitleBadge';
import YoutubeVideo from './YoutubeVideo';
import { genRandId } from './WYSIWYGWriterUtils';
import WYSIWYGWriter from './WYSIWYGWriter';
import ButtonsPanel from './ButtonsPanel';
import { ThreadContent } from './ThreadContent';
import { getCurrentDomain, getCurrentPageTitleUrlEncoded, getCurrentUrlUrlEncoded, isServer } from '../util/windowUtil';
import { toJS } from 'mobx';
import ThreadPinModal from './ThreadPinModal';
import ResourceLinkBox from './ResourceLinkBox';


function getPreview(displayContent) {
  const text = displayContent.replace(/<[^>]+>/g, '');
  const preview = text.substring(0, 200);
  return preview.length === 200 ? preview + '...' : preview;
}

@observer
class ThreadMain2 extends Component {
  // Helper function to format the relative time
  constructor(props) {
    super(props);
    this.state = {
      revealContent: false,
      revealHovered: false,
      editing:false,
      editorEditId:genRandId('the-'), 
      editorId:genRandId('tae-'), 

      title: '',
      content: '',
      isNSFW: false,
      isInappropriate: false,
      isSpoiler: false,
      linkMax: isServer()?99:3
    };
  }


  toggleEditing = () =>
  {
    const { thread } = this.props;
    const { title, content,isNSFW,isInappropriate,isSpoiler } = thread.thread.properties;

    this.setState({
        editing: !this.state.editing,
        title: title,
        content: content,
        isNSFW: isNSFW,
        isInappropriate: isInappropriate,
        isSpoiler: isSpoiler
    });
  }
  sendEditContent= async (content) =>{
    const { thread } = this.props;
    const { title} = this.state;
    const { isNSFW,isInappropriate,isSpoiler } = this.state;
    const { id } = thread.thread;

    return MyAppPool.cNeoComu.updateThread(title,content,id,{isNSFW,isInappropriate,isSpoiler})
    .then(()=>{
      MyAppPool.cAlert.notifyUser(lng("Update Successful")+"!","",200);
      this.setState({
        editing: false
      });
      return MyAppPool.cNeoComu.getThread(id);
    })
    .catch((e) => {
      MyAppPool.cAlert.notifyUser(lng("Update Failed, try again later")+"!","",500);
    });
  }
  deletePost = () => {
    const { thread } = this.props;
    const { id } = thread.thread;
    MyAppPool.cNeoComu.deleteThread(id,false).then(()=>{
      MyAppPool.cAlert.notifyUser(lng("Deletion Successful")+"!","",200);
      return MyAppPool.cNeoComu.getThread(id);

    }).catch((err)=>{
      MyAppPool.cAlert.notifyUser(lng("Delete Failed, try again later")+"!","",500);
    });
  }
  deletePostPermanent = () => {
    const { thread } = this.props;
    const { id } = thread.thread;
    MyAppPool.cNeoComu.deleteThread(id,true).then(()=>{
      MyAppPool.cAlert.notifyUser(lng("Deletion Successful")+"!","",200);
      return MyAppPool.cLocation.updateUrl(getForumSectionPath(thread.section.id,thread.section.properties.name));
    }).catch((err)=>{
      MyAppPool.cAlert.notifyUser(lng("Delete Failed, try again later")+"!","",500);
    });
  }

  handleCheckboxChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  pin = () =>{
    const { thread } = this.props;
    MyAppPool.cModal.set(lng('Pin'),<ThreadPinModal threadId={thread.thread.id}/>,null,'',null);
  }

  render() {
    const { thread } = this.props;
    const { title,votes, content, datecreated, repliesAmount, views,isNSFW,isInappropriate,isSpoiler } = thread.thread.properties;
    const { id } = thread.thread;
    const { tags,user,section } = thread;
    let {revealContent, revealHovered, linkMax} = this.state;
    const {disableNSFWProtection, disableSpoilerProtection, disableInapropriateContentProtection} = MyAppPool.session.browserOptions;
    

    //let sectionName = thread.section.properties.name;
    //let sectionId = thread.section.id;
    let useTitle = title;//.slice(0,64);
    if (useTitle.length !== title.length)
      useTitle+='...';
    let useTags = [];
    if (Array.isArray(tags)) {
      useTags = tags;
    }
    let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+userFastResourceType][''+user.id]);
    sampleAuthor.titles = sampleAuthor.titles.slice(0,2);
    let useName = sampleAuthor.name.slice(0,32);
    if (useName.length !== sampleAuthor.name.length)
      useName+='...';

    let hideContent = '';
    let why='';
    if ((isSpoiler && disableSpoilerProtection)
    || (isNSFW && disableNSFWProtection)
    || (isInappropriate && disableInapropriateContentProtection)
    )
    {
      revealContent=true;
    }

    if (isSpoiler && revealContent===false)
    {
      hideContent='hideSpoiler';
      why = lng('Contains Spoilers');
    }
    if (isNSFW && revealContent===false)
    {
      
      hideContent='hideSpoiler';
      why = lng('NSFW Content');
    }
    if (isInappropriate && revealContent===false)
    {
      hideContent='';
      why = lng('Contains Inappropriate Content');
    }
    let userVote = MyAppPool.session.user.votes[''+threadFastResourceType][''+id];
    let userVotePositiveClass = '';
    let userVoteNegativeClass = '';
    if (userVote===1)
      userVotePositiveClass=' activePositiveVote';
    if (userVote===-1)
      userVoteNegativeClass=' activeNegativeVote';

    let linkCount = 0;
    let linkAvailable = 0;

    let showEditIcons = false;
    let shareUrl = encodeURIComponent(getCurrentDomain()+ getForumThreadPage(id,title,"","#threadTop"));
    let date = datecreated;
    if (MyAppPool.currentUser.userid===user.id || MyAppPool.cNeoComu.isModeratorOf(section.id))
      showEditIcons=true;

    return (
      <div className="compthread-main uk-card uk-card-default">
        {!this.state.editing?
        <h2 className="thread-title" >{useTitle} </h2>
        :<input type="text" className="titleInput" value={this.state.title} onChange={(e) => {this.setState({title: e.target.value})} }/>}
        <div className="thread-header">
          <LinkV2 to={getUserRoute(sampleAuthor.id,sampleAuthor.name,"#top")} className="user-area">
          <div className="thread-author">
            <img className="author-avatar" src={getUserAvatar(sampleAuthor.img,sampleAuthor.hash)} alt="Author Avatar" />
            <div className="author-info">
              <span className="author-name" data-uk-tooltip={sampleAuthor.name}>{useName}</span>
              <div className="author-titles">
                {sampleAuthor.titles.map((title, index) => <TitleBadge key={index} tag={title}/>)}</div>
            </div>
          </div>
          </LinkV2>
        </div>
        
        <div className={"thread-content-section"} 
        onMouseEnter={() => this.setState({ revealHovered: true })}
        onMouseLeave={() => this.setState({ revealHovered: false })}
        onClick={()=>{
          if (isNSFW || isInappropriate || isSpoiler)
            this.setState({ revealContent: true });
        }}
        >
        {((isNSFW || isInappropriate || isSpoiler) && !revealContent)?
          <div className={`borderedText reveal-content-text ${revealHovered ? 'show' : ''}`}>
          {lng("Reveal Content")} ({why})
          </div> :<></>
        } {!isInappropriate || (isInappropriate  && revealContent)?
          
          <div className={"thread-content "+hideContent}>
          
          {!this.state.editing?
        <p className="postContent" >
            <ThreadContent content={content} />
            {//displayContent
            }

        </p>
        :
        <div>
          <div className="radio-group-flex">
            <label><input type="checkbox" name="isNSFW" checked={this.state.isNSFW} onChange={this.handleCheckboxChange} /> {lng("NSFW Content")} </label>
            <label><input type="checkbox" name="isInappropriate" checked={this.state.isInappropriate} onChange={this.handleCheckboxChange} /> {lng("Contains Inappropriate Content")} </label>
            <label><input type="checkbox" name="isSpoiler" checked={this.state.isSpoiler} onChange={this.handleCheckboxChange} /> {lng("Contains Spoilers")} </label>
          </div>
        <WYSIWYGWriter id={this.state.editorEditId} hasSendButton={1}
        placeHolder={lng("O que esta pensando ?")}
        actions={
            [
            'superscript',
            'link',
            'image',
            'youtube',
            'emoji',
            'spoiler',
            'insertOrderedList',
            'insertUnorderedList'
            ]} 
            actionClasses={{
                insertOrderedList:'list-ol',
                insertUnorderedList:'list-ul',
                table:'border-all',
                spoiler: 'eye-slash',
                emoji:'grin-squint-tears',
                youtube:'fab fa-youtube'
                }}
            actionTags={{
                link:'<a ',
                spoiler:'<span class="spoiler">'
            }}
            hasCloseButton={1}
            onCloseClick = {this.toggleEditing}
            onSendClick = {this.sendEditContent}
            startContent = {content}
                />
          </div>
        }
          
          </div> : <div style={{"height":"60px"}}></div>}
          
        </div>

        <div className="linkresflow">
        {useTags.map((element, index) => {
                      let tag = element.properties.name;
                      if (tag.indexOf("lnkres_")==-1)
                        return <></>;
                        linkAvailable+=1;
                      if (linkCount >=linkMax)
                        return <></>;
                      linkCount+=1;
                      let extractInfo = tag.split("_");
                      let resourceId = Number(extractInfo[2]);
                      let resourceType = Number(extractInfo[1]);
                      return <div className="linkresspace"><ResourceLinkBox resourceType={resourceType} resourceId={resourceId} /></div> ;       
          })}
          {linkAvailable>linkMax?<button className="uk-button uk-button-default" onClick={() => this.setState({ linkMax: linkMax + 3 })}>{lng("Mais")}</button> :<></>}
          </div>

        <div className="thread-footer">
          <div className="thread-counters">
          <span className="thread-views"><span className="fas fa-eye"/> {views} </span>
          <span className="thread-replies"><span className="fas fa-comments"/> {repliesAmount}  </span>
          <span className="thread-date">{timeSinceString(datecreated * 1000)}</span>
          </div>
          <div>
          <ThreadTag backgroundColor={"#33ccff"} name={thread.section.properties.name} url={getForumSectionPath(thread.section.id,thread.section.properties.name)}/>
          {useTags.map((element, index) => {
            if (element.properties.name.indexOf("lnkres_")!==-1)
            return <></>;
            return <ThreadTag name={element.properties.name} url={getForumTagPath(element.id,element.properties.name)}/>;
          })}
          </div>
          
          </div>
          

        <div>

        <div className="thread-footer-extras">
            { userVote==-1? <span className="votesText activeNegativeVote">{votes}</span> 
            : userVote==1? <span className="votesText activePositiveVote">{votes}</span> 
            : <span className="votesText ">{votes}</span> 
            }
            <span aria-label={"+1"} className={"fas fa-arrow-up clickable arrowButton "+userVotePositiveClass } onClick={()=>MyAppPool.cVotes.sendNeoComuResourceVote(threadFastResourceType,id, 1).then((data)=>{
              delay(500).then(() => {
              MyAppPool.cNeoComu.getThread(id);
              });
            })}/>
            <span aria-label={"-1"} className={"fas fa-arrow-down clickable arrowButton "+userVoteNegativeClass } onClick={()=>MyAppPool.cVotes.sendNeoComuResourceVote(threadFastResourceType,id,-1).then((data)=>{
              delay(500).then(() => {
              MyAppPool.cNeoComu.getThread(id);
              });
            })}/>

            <span className="iconButton clickable smalllink">  <span className="fas fa-share"/> {lng("Compartilhar")}. </span>
            <div data-uk-drop="pos: top-center; mode:click" className="uk-card uk-card-default sharePop">
            <ButtonsPanel
                buttons={[
                    {tooltip:lng('Compartilhar no Facebook'),ukicon:'facebook', url:"https://www.facebook.com/share.php?u="+ MyAppPool.currentUrlEncoded }
                    ,{text:"X",tooltip:lng('Compartilhar no Twitter'), url:"http://twitter.com/share?related="+getCurrentDomain()+"&via="+getSiteShortName()+"&url="+getCurrentUrlUrlEncoded()+"&text="+getCurrentPageTitleUrlEncoded()+"&hashtags=anime,nani,"+lng('meusanimesbrasili')}
                    ,{tooltip:lng('Compartilhar no Reddit'),ukicon:'reddit', url:"http://reddit.com/submit?url="+getCurrentUrlUrlEncoded()+"&title="+getCurrentPageTitleUrlEncoded()}
                    ,{tooltip:lng('Compartilhar no Tumblr'),ukicon:'tumblr', url:"http://www.tumblr.com/share/link?url="+getCurrentUrlUrlEncoded()+"&name="+getCurrentPageTitleUrlEncoded()}             
                ,{tooltip:lng('Copiar para à Area de Colagem'),ukicon:'link', onClick: (e) => {e.preventDefault(); MyAppPool.cTextInteraction.copyToClipBoard(shareUrl); }}
                ]}/>
            </div>
            
            {showEditIcons?
            [<span onClick={()=>this.toggleEditing()} className="fas fa-pen iconButton clickable"/>,
             <span onClick={()=>MyAppPool.cModal.set(lng('Deletar'),lng('Tem certeza?'),this.deletePost)} className="fas fa-trash-alt iconButton clickable"/>
            ]
            :<></>}
            {MyAppPool.cNeoComu.isModeratorOf(section.id)? <i className="fas fa-thumbtack link-button" onClick={this.pin}></i>:<></> }
            {MyAppPool.cNeoComu.isAdministratorOf(section.id)?
            <span style={{color:"red"}} onClick={()=>MyAppPool.cModal.set(lng('Deletar Thread e Respostas(Permanente)'),lng('Tem certeza?'),this.deletePostPermanent)} className="fas fa-trash-alt iconButton clickable"/>
            :<></>}
          </div>
            

          
        </div>
      </div>
    );
  }
}

ThreadMain2.propTypes = {
  thread: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    datecreated: PropTypes.number.isRequired,
    repliesAmount: PropTypes.number.isRequired,
    views: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      properties: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,
};

export default ThreadMain2;