

import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
//import "./ThreadPinModal.css";
import LinkV2 from './LinkV2';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { delay } from '../util/timeUtil';
import { isServer } from '../util/windowUtil';
import { isSet } from '../util/typeu';

class ThreadPinModal extends Component {
    constructor(props){
        super(props);
        this.state = {sections:[], selectedSection:0};

    }

  componentDidMount()
  {
    ThreadPinModal.getInitialValues();
  }
  static async getInitialValues()
  {
    let baseSection = MyAppPool.cNeoComu.getBaseSection();
    let p4 = MyAppPool.cNeoComu.getSection(baseSection);
    return p4;
  }
  componentWillReceiveProps()
  {
    ThreadPinModal.getInitialValues();
  }

  pinThread = () => {
      let {selectedSection} = this.state;
      if (selectedSection===0)
        return;

      MyAppPool.cNeoComu.pinThread(this.props.threadId, selectedSection).then((data) => {
          MyAppPool.cAlert.notifyOk(lng('Sucesso!'));
          MyAppPool.cModal.deactivate();
      }).catch(err => {
          console.error(err);
          MyAppPool.cAlert.notifyGenericError('','pin');
      })
  }
  selectSection = (sectionId) => {
    this.setState({selectedSection:sectionId});
  }

  render(){
    let {threadId} = this.props;

    let section = MyAppPool.cNeoComu.getBaseSection();
    let sectionData = MyAppPool.session.neocomu.sections[section];
    if (!isSet(sectionData)){
      sectionData = {
        subsections:[],
        admins: [],
        mods: [],
        owner: 0
      };
    };
    let useSubSections = [MyAppPool.session.neocomu.sections[section],...sectionData.subsections];

    if (threadId<=0)
        return <></>;
    
    return (
      <div>
        <select className="uk-select" value={this.state.selectedSection} onChange={(e) => {this.selectSection(e.target.value);}}>
        <option value={0}>{lng("Select a section")}</option>
        {useSubSections.map((elemId, index) => (
            <option key={index} value={Number(elemId.id)}>
                {elemId.properties.name}
            </option>
        ))}
        </select>
         <button aria-label={lng('Pin')} onClick={()=>{this.pinThread();}} className="uk-button uk-button-primary sendButton">{lng('Pin')}</button>
      </div>
        );
}
}


ThreadPinModal.propTypes = {
    threadId: PropTypes.number.isRequired
  };
  ThreadPinModal.defaultProps = {
    threadId: 0
  };


export default ThreadPinModal;
