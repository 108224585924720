import React, { Component } from 'react';
import {observer} from 'mobx-react';
import './AppFooter.css';
import { getImgAddress, getSiteName } from '../util/text';
import MyAppPool from '../AppPool';
import {  isSet } from '../util/typeu';
import { Link } from 'react-router-dom';
import { getObraRoute, getSupportPage } from '../store/AppRoutes';
import ObraTopTextList from '../view_component/ObraTopTextList';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { isMobile } from '../util/mobile';
import { getSwitchFlags } from '../util/icon';
import { getCurrentPath, isServer } from '../util/windowUtil';
import { serverRegisterFetch } from '../util/updateManager';
import { toJS } from 'mobx';

@observer class AppFooter extends Component {
  constructor(props){
    super(props);
    this.setDesignTheme = this.setDesignTheme.bind(this);
    serverRegisterFetch(AppFooter.getInitialValues);
  }
  setDesignTheme(val){
      MyAppPool.session.theme=val;
      MyAppPool.cPreference.setTheme(val);
  }
  componentDidMount()
  {
    AppFooter.getInitialValues();
  }

  static async getInitialValues()
  {
    let p1 = MyAppPool.cTops.getTopAnimeGradePageByType('TV',0,5);
    let p2 = MyAppPool.cTops.getTopAnimeGradePageByType('Movie',0,5);
    let p3 = MyAppPool.cTops.getTopAnimeGradePageByType('Special',0,5);
    let p4 = MyAppPool.cTops.getTopAnimeGradePageByType('OVA',0,5);
    let p5 = MyAppPool.cTops.getTopMangaGradePageByType('Novel',0,5);
    let p6 = MyAppPool.cTops.getTopMangaGradePageByType('Manga',0,5);

    await Promise.all([p1,p2,p3,p4,p5,p6]);
    
    return Promise.resolve();
  }
  componentWillReceiveProps()
  {
    AppFooter.getInitialValues();
  }


  render(){

    let pageSize = 5;
    let animeTvTops=[];
    let animeTvTopPage = MyAppPool.cTops.getTopGradePage(MyAppPool.session.tops,'anime','TV','0',pageSize);
    
    if (isSet (animeTvTopPage))
        animeTvTops=animeTvTopPage.itens;

    let animeMovieTops=[];
    
    let animeMovieTopPage = MyAppPool.cTops.getTopGradePage(MyAppPool.session.tops,'anime','Movie','0',pageSize);
    if (isSet (animeMovieTopPage))
        animeMovieTops=animeMovieTopPage.itens;

    let animeOVATops=[];
    let animeOVATopPage = MyAppPool.cTops.getTopGradePage(MyAppPool.session.tops,'anime','OVA','0',pageSize);
    if (isSet (animeOVATopPage))
        animeOVATops=animeOVATopPage.itens;

    let animeSpecialTops=[];
    let animeSpecialTopPage = MyAppPool.cTops.getTopGradePage(MyAppPool.session.tops,'anime','Special','0',pageSize);
    if (isSet (animeSpecialTopPage))
        animeSpecialTops=animeSpecialTopPage.itens;

    let mangaTops=[];
    
    let mangaTopPage = MyAppPool.cTops.getTopGradePage(MyAppPool.session.tops,'manga','Manga','0',pageSize);
    if (isSet (mangaTopPage))
        mangaTops=mangaTopPage.itens;
    let siteMapUrl = "/"+getCurrentLanguage()+"0IndexSiteMap.xml";
    let newsRssUrl = "/"+getCurrentLanguage()+"_rss.xml";
    let novelTops=[];
    let novelTopPage = MyAppPool.cTops.getTopGradePage(MyAppPool.session.tops,'manga','Novel','0',pageSize);
    if (isSet (novelTopPage))
        novelTops=novelTopPage.itens;
    let mobileFooterExtra = [<li className="themeSelectionMenu">
    {lng("Tema")}<span>&nbsp;&nbsp;</span>
    <button aria-label={lng('Tema')+" "+lng("Claro")} style={{"background-color":"white"}} className="uk-button uk-button-default themeSelector"
    onClick={()=>{this.setDesignTheme("lightTheme")}}></button>
    <button aria-label={lng('Tema')+" "+lng("Escuro")}  style={{"background-color":"black"}} className="uk-button uk-button-default themeSelector"
    onClick={()=>{this.setDesignTheme("darkTheme")}}></button>
    </li>];
    let mobile = isMobile.any;
    let flags = getSwitchFlags();
    
    let ptUrl=  MyAppPool.getAlternativeUrlsForThiPage.pt;
    let enUrl = MyAppPool.getAlternativeUrlsForThiPage.en;
    let deUrl = MyAppPool.getAlternativeUrlsForThiPage.de;
    let esUrl = MyAppPool.getAlternativeUrlsForThiPage.es;

      return(
        <div className="uk-section-default nav_footer">
          <div className="topsContainer">
                      <div className="topSection">
                          <div className="titulo">{lng('Top Anime')}</div>
                          <ol className="topListContainer">
                            <ObraTopTextList items={animeTvTops} type='anime'/>
                          </ol>
                      </div>
                        <div className="topSection">
                          <div>{lng('Top Filmes')}</div>
                          <ol className="topListContainer">
                            <ObraTopTextList items={animeMovieTops} type='anime'/>
                          </ol>
                          </div>
                        <div className="topSection">
                          <div>{lng('Top Especiais')}</div>
                          <ol className="topListContainer">
                          <ObraTopTextList items={animeSpecialTops} type='anime'/>
                          </ol>
                      </div>

                      <div className="topSection">
                          <div>{lng('Top OVAs')}</div>
                          <ol className="topListContainer">
                          <ObraTopTextList items={animeOVATops} type='anime'/>
                          </ol>
                      </div>
                      <div className="topSection">
                          <div>{lng('Top Mangas')}</div>
                          <ol className="topListContainer">
                          <ObraTopTextList items={mangaTops} type='manga'/>
                          </ol>
                      </div>
                      <div className="topSection">
                        <div>{lng('Top Romances')}</div>
                        <ol className="topListContainer">
                          <ObraTopTextList items={novelTops} type='manga'/>
                          </ol>
                      </div>
              </div>
              <hr className="uk-divider-icon"></hr>
          <div className="uk-container">
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav termsNav">
                <li><Link to={getSupportPage()}>{lng("Apoie o NossoAnime!")}</Link></li>
                <li><Link to="/termsofusepage#top">{lng('Termos de Uso')}</Link></li>
                <li><Link to="/privacypolicypage#top">{lng('Politica de Privacidade')}</Link></li>
                <li><Link to="/cookiepolicypage#top">{lng('Politica de Cookies')}</Link></li>
                <li><a href={siteMapUrl}>Sitemap</a></li>
                <li><a href={newsRssUrl}>{lng("News RSS")}</a></li>
                
                {mobile?<></>: mobileFooterExtra}
            </ul>
            <ul className="uk-navbar-nav termsNav">
                {mobile?mobileFooterExtra :<></> }
            </ul>
        </div>
        <div className="flagsContainer">
          <div className="countryGrouper">
          {flags.pt.map((elem,key) =>{
            return <a href={ptUrl} hreflang={"pt-"+elem}><i key={key} className={elem+" footerflag"}></i></a>
          })}
          </div>
          <div className="countryGrouper">
          {flags.en.map((elem,key) =>{
            return <a href={enUrl} hreflang={"en-"+elem}><i key={key} className={elem+" footerflag"}></i></a>
          })}</div>
          <div className="countryGrouper">
          {flags.de.map((elem,key) =>{
            return <a href={deUrl} hreflang={"de-"+elem}><i key={key} className={elem+" footerflag"}></i></a>
          })}</div>
          <div className="countryGrouper">
          {flags.es.map((elem,key) =>{
            return <a href={esUrl} hreflang={"es-"+elem}><i key={key} className={elem+" footerflag"}></i></a>
          })}</div>
        </div>
            
            
            
          </div>
          <div className="uk-section uk-background-cover footerPadding multiBackgroundText" style={{backgroundImage: 'url("'+getImgAddress("imgs/defaults/footer-original.webp")+'")'}}>
          <div className="direitosText smalltext">
                    {getSiteName()} © {lng("Todos Direitos Reservados")} 2019-{new Date().getFullYear()} <br/>
                    {lng("Design e Engenharia por")} LeFerdie
                  </div>
          </div>
      </div>
      ); 
    }
}

export default AppFooter;
