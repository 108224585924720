
import { merge } from 'lodash';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { fetchAndProcess } from '../util/net';
import { getDefaultStarter, isSet, mobToObj } from '../util/typeu';
import { lng } from '../ulanguages/language';

function updateGradeCount(previousResourceCount,lastGrade,newGradeArg)
{
    let newVote=0;
    let newGrade = newGradeArg;
    if (!isSet(lastGrade))
      newVote=1;
    else
      newGrade =newGrade-lastGrade;

    let previousAverageGrade = previousResourceCount.averagegrade;
    let previousAmountOfVotes = previousResourceCount.totalvotes;

    let newAverage = (previousAverageGrade*previousAmountOfVotes)/(previousAmountOfVotes+newVote) + (newGrade/(previousAmountOfVotes+newVote)) ;
                     //(averagegrade*totalvotes)/(totalvotes+?) + ?/(totalvotes+?)
    let newAmountOfUserVotes = previousAmountOfVotes + newVote;   
    
    let gradeCount=[0,0,0,0,0,0];
    let previousGradeCount=[0,0,0,0,0,0];

    if (newGradeArg>=5 && newGradeArg<6)
        gradeCount[1] = 1;
    if (newGradeArg>=6 && newGradeArg<7)
        gradeCount[2] = 1;
    if (newGradeArg>=7 && newGradeArg<8)
        gradeCount[3] = 1;
    if (newGradeArg>=8 && newGradeArg<9)
        gradeCount[4] = 1;   
    if (newGradeArg>=9 && newGradeArg<=10)
        gradeCount[5] = 1; 
    
    if (lastGrade>=5 && lastGrade<6)
        previousGradeCount[1] = 1;
    if (lastGrade>=6 && lastGrade<7)
        previousGradeCount[2] = 1;
    if (lastGrade>=7 && lastGrade<8)
        previousGradeCount[3] = 1;
    if (lastGrade>=8 && lastGrade<9)
        previousGradeCount[4] = 1;   
    if (lastGrade>=9 && lastGrade<=10)
        previousGradeCount[5] = 1; 
    for(let i=0;i<6;i++)
        gradeCount[i] -= previousGradeCount[i];
    
    let newResourceCount = JSON.parse(JSON.stringify(previousResourceCount));
    newResourceCount.averagegrade = newAverage;
    newResourceCount.totalvotes = newAmountOfUserVotes;
    newResourceCount.grade0 += gradeCount[0];
    newResourceCount.grade1 += gradeCount[1];
    newResourceCount.grade2 += gradeCount[2];
    newResourceCount.grade3 += gradeCount[3];
    newResourceCount.grade4 += gradeCount[4];
    newResourceCount.grade5 += gradeCount[5];

    return newResourceCount;
}

export default class GradeController
{
    sendWorkResourceGrade
    (resourceType,resourceId,value)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);

           //data0.data.userGrade (number), data0.data.resourceGrades (array of objects)
           let userGrade = data0.data.userGrade;
           let previousResourceGradeCount = MyAppPool.session.grades[resourceType][resourceId];
           if (isSet(previousResourceGradeCount))
           {
            let previousUserGrade = MyAppPool.session.user.grades[''+resourceType][''+resourceId];
            let newResourceCount = [updateGradeCount(previousResourceGradeCount[0],previousUserGrade,value)];
            MyAppPool.session.grades[resourceType][resourceId] = newResourceCount;
           }
           MyAppPool.cUser.updateResourceGradePage(resourceType,resourceId,userGrade);
           MyAppPool.session.user.grades[''+resourceType][''+resourceId] = userGrade;
           MyAppPool.cAlert.notifyOk(lng("Nota salva!"));
           return Promise.resolve();
        }
        ,
        routes.sendWorkResourceGrade,false,
        {resourceType,resourceId,grade:value})
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(e,"grade, send work resource grade");
            console.error(("Error Sending Work Resource Grade"),e);
        });
    }

    fetchUserGrades(useCache=true)
    {
        return fetchAndProcess ((datas,ready)=>{
            if (!datas.ok)
                return Promise.reject(datas.msg);

            let data = datas.data;
            if (data.length>0)
            {
                let result = getDefaultStarter();
                for (let i=0; i < data.length; i++)
                {
                    let item = data[i];
                    result[''+item.resourcetype][item.resourceid]=item.grade;
                }
                //web client should be more updated, therefore we merge server cached fetch with current 
                //web client user votes.
                let oldGrades = MyAppPool.session.user.grades;
                let newRes = merge(result,oldGrades);
                
                MyAppPool.session.user.grades = newRes;
                return Promise.resolve('ok');
            }
            return Promise.resolve('no items');
        },
        routes.getUserGrades,
        useCache,
        {uid:MyAppPool.currentUser.userid}
        )
        .catch(e => console.error("Error Getting User Grades",e));
    }

}