import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../../AppPool';
import { lng } from '../../ulanguages/language';
import { getImgAddress } from '../../util/text';
import { isSet } from '../../util/typeu';
//import LoginBox from '../view_component/LoginBox';
import { isServer } from '../../util/windowUtil';
import SpinnerBox from '../../view_component/SpinnerBox';

import './home.css';
import ContentBannerSetter from '../../view_component/ContentBannerSetter';
import ThreadPreview from "../../view_component/ThreadPreview";
import InfiniteScrollBlock from "../../view_component/InfiniteScrollBlock";
import { serverRegisterFetch } from "../../util/updateManager";
import ThreadPinned from "../../view_component/ThreadPinned";
import ThreadTag from "../../view_component/ThreadTag";
import { getForumSectionPath } from "../../store/AppRoutes";
import { toJS } from "mobx";
import ScrollToTopButtom from "../../view_component/ScrollToTopButton";

const newPageSize = 3;
@observer
class ForumHome extends Component {

  constructor(props) {
    super(props);

    this.state = {currentPage:1,pageSize:10, lastFetchSize:1};
    serverRegisterFetch(ForumHome.getInitialValues);
  }

  componentDidMount()
  {
    ForumHome.getInitialValues();
  }
  static async getInitialValues()
  {
    let baseSection = MyAppPool.cNeoComu.getBaseSection()
    let p1 = MyAppPool.cNeoComu.getLatestActivitiesThreads(1,10);
    let p2 = MyAppPool.cNeoComu.getNewlyCreatedThreads(1,newPageSize);
    let p3 = MyAppPool.cNeoComu.getSectionPinnedThreads(
      baseSection,1,10);
    
    return Promise.all([p1,p2,p3]);
  }
  componentWillReceiveProps()
  {
    ForumHome.getInitialValues();
  }

  loadNextPage = ()=>{
    let {lastFetchSize,currentPage,pageSize} = this.state;
    if (lastFetchSize>0)
    {
        return MyAppPool.cNeoComu.getLatestActivitiesThreads(currentPage+1,pageSize).then(data=>{
            this.setState({lastFetchSize:data.length,currentPage:currentPage+1});
            return Promise.resolve();
        });
    }
    return Promise.resolve();
  }

  render(){
    let {pageSize,currentPage} = this.state;
    let meta = {title:lng('Forum')+" "+lng('Home')+' | NANI',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};

   
    let newlyCreatedIDPages = MyAppPool.session.neocomu.newlyCreatedThreads[newPageSize];
    let newlyCreatedPages=[];
    if (isSet(newlyCreatedIDPages)) 
    {
      newlyCreatedPages = Object.values(newlyCreatedIDPages).flat();
    }
    
    let latestIDPages = MyAppPool.session.neocomu.latestThreads[pageSize];
    let allPages=[];
    if (isSet(latestIDPages)) 
    {
        allPages = Object.values(latestIDPages).flat();
    }
    let section = MyAppPool.cNeoComu.getBaseSection();
    let sectionData = MyAppPool.session.neocomu.sections[section];
    if (!isSet(sectionData)){
      sectionData = {
        subsections:[],
        admins: [],
        mods: [],
        owner: 0
      };
    };
    let pinnedThreads = [];
    if (isSet(MyAppPool.session.neocomu.sectionPinnedThreads[""+section]))
    {
        pinnedThreads = MyAppPool.session.neocomu.sectionPinnedThreads[""+section];
    }
    //console.log("LOOKTHREADS:::", pinnedThreads);
    //console.log("Section:::", section);
    
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div >
            <div  className=' contentArea uk-flex uk-flex-row'>
            <ContentBannerSetter />
            <div className="threadsSection">
                <div className="pinSection">
                  {pinnedThreads.map((elemId,index)=>{
                  let elem = MyAppPool.session.neocomu.threads[elemId];
                  return  <div style={{"width":"48%", "marginRight":"1%","marginBottom":"10px"}}>
                    <ThreadPinned key={index} thread={elem} /> 
                    </div>
                  })}
                </div>
                <h2>{lng('New Discussions')}</h2>
                {newlyCreatedPages.map((elemId,index)=>{
                let elem = MyAppPool.session.neocomu.threads[elemId];
                return  <ThreadPreview key={index} thread={elem} /> 
                })}
                <h2>{lng('Latest Discussions')}</h2>
                <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                {allPages.map((elemId,index)=>{
                let elem = MyAppPool.session.neocomu.threads[elemId];
                return  <ThreadPreview key={index} thread={elem} /> 
                })}
                </InfiniteScrollBlock>
            </div>
            <div className="scrollSection">
              <ScrollToTopButtom />
            </div>
            </div>
          </div>
        </div>
      );
}

}

export default ForumHome;
