

import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
//import "./ThreadUnpinModal.css";
import LinkV2 from './LinkV2';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { delay } from '../util/timeUtil';
import { isServer } from '../util/windowUtil';

class ThreadUnpinModal extends Component {
    constructor(props){
        super(props);
        this.state = {sections:[], selectedSection:0};

    }
  componentDidMount()
  {
    MyAppPool.cNeoComu.getThreadPinnedSections(this.props.threadId).then((sections) =>{
      this.setState({sections:sections});
      if (sections.length>0)
        this.setState({selectedSection:sections[0].id});
      
    });
  }
  unpinThread = () => {
      let {selectedSection} = this.state;
      if (selectedSection===0)
        return;

      MyAppPool.cNeoComu.unpinThread(this.props.threadId, selectedSection).then((data) => {
          MyAppPool.cAlert.notifyOk(lng('Sucesso!'));
          MyAppPool.cModal.deactivate();
          delay(1000).then(()=>{
            if (!isServer())
                window.location.reload();
          });
      }).catch(err => {
          console.error(err);
          MyAppPool.cAlert.notifyGenericError('','unpin');
      })
  }
  selectSection = (sectionId) => {
    this.setState({selectedSection:sectionId});
  }

  render(){
    let {threadId} = this.props;
    let {sections} = this.state;

    if (threadId<=0)
        return <></>;
    
    return (
      <div>
        <select className="uk-select" onChange={(e) => {this.selectSection(e.target.value);}} >
          {
            sections.map((item, index) => {
              return (
                <option key={index} value={item.id}>{item.name}</option>
              );
            })
          }
         </select>
         <button aria-label={lng('Unpin')} onClick={()=>{this.unpinThread();}} className="uk-button uk-button-primary sendButton">{lng('Unpin')}</button>
      </div>
        );
}
}


ThreadUnpinModal.propTypes = {
    threadId: PropTypes.number.isRequired
  };
  ThreadUnpinModal.defaultProps = {
    threadId: 0
  };


export default ThreadUnpinModal;
