import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { serverRegisterFetch } from '../util/updateManager';
import { isServer } from './../util/windowUtil';
import Spinner from './Spinner';
import { lng } from '../ulanguages/language';

function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

@observer
class InfiniteScrollBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {finishedLoading:false,loading: false };
    this.createObserver = this.createObserver.bind(this);
    this.disconnectObserver = this.disconnectObserver.bind(this);
    this.handleIntersection = this.handleIntersection.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.observer = null;
    this.loadingRef = React.createRef();
    this.tryFetch = this.tryFetch.bind(this);
  }

  tryFetch(){
    try{
    let element = document.getElementById("scrollEnd");
    if (element)
    {
        if (isElementInViewport(element))
        {
            this.loadMore();
        }
    }}catch(e)
    {
        console.error("scroll loading err:",e);
    }
  }
  handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !this.state.loading) {
        this.loadMore();
      }
    });
    
  };
  componentDidMount()
  {
    if (!isServer())
    {
        this.createObserver();
        document.addEventListener('scroll', this.tryFetch);
    }
  }
  componentWillUnmount() {
    if (!isServer())
    {
        this.disconnectObserver();
        document.removeEventListener('scroll', this.tryFetch);
    }
  }

  loadMore() {
    if (this.state.loading===true)
        return;
    this.setState({ loading: true });
    this.props.fetchFunction()
      .then((data) => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
  }
  
  createObserver() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });
    this.observer.observe(this.loadingRef.current);
  }
  disconnectObserver() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
  render(){
    return (
      <div {...this.props} className={"component_infinitescrollblock "+this.props.className}>
            {this.props.children}
            <div id="scrollEnd" ref={this.loadingRef} className="uk-button uk-button-default" style={{  width:"100%", textAlign: 'center' }} onClick={this.tryFetch}>
            {this.state.loading ? <Spinner /> : lng('Scroll to load more (you may have reached end of content)')}
            </div>
      </div>
    );
  }
}
InfiniteScrollBlock.propTypes = {
    children:PropTypes.array.isRequired,
    fetchFunction: PropTypes.func.isRequired,
};

InfiniteScrollBlock.defaultProps = {
    children: undefined,
    fetchFunction:()=>{return Promise.resolve()}
};

export default InfiniteScrollBlock;
