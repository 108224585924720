import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { lng } from '../../ulanguages/language';
import BannerReaction from '../../view_component/BannerReaction';
import BreadCumbs from '../../view_component/BreadCumbs';
import CapaSection from '../../view_component/CapaSection';
import Erro404 from '../../view_component/Erro404';
import SkeletonBlock from '../../view_component/SkeletonBlock';
import SubNavigation from '../../view_component/SubNavigation';
import TagList from '../../view_component/TagList';
import TextContentBox from '../../view_component/TextContentBox';
import { isMobile } from './../../util/mobile';
import { getCurrentUrl, isServer } from './../../util/windowUtil';
import CSSTransition2 from './../../view_component/CSSTransition2';

import ContentBannerSetter from '../../view_component/ContentBannerSetter';

import './createThread.css';
import { getImgAddress, getRouteParamAtIndex } from '../../util/text';
import MyAppPool from '../../AppPool';
import { isSet, mobToObj } from '../../util/typeu';
import ThreadTag from '../../view_component/ThreadTag';
import { toJS } from 'mobx';
import LinkV2 from '../../view_component/LinkV2';
import { getForumHomePage, getForumSectionPath, getForumThreadPage } from '../../store/AppRoutes';
import WYSIWYGWriter from '../../view_component/WYSIWYGWriter';
import SearchLinkResourceBox from '../../view_component/SearchLinkResourceBox';
import ResourceLinkBox from '../../view_component/ResourceLinkBox';


@observer
class CreateThread extends Component {

  constructor(props) {
    super(props);

    this.state = {
        createSection:-1,
        title: '',
        content: '',
        tags: [],
        tagInput: '',
        isNSFW: false,
        isInappropriate: false,
        isSpoiler: false,
        isFeatured:false,
        autoCompleteOptions: []
    };
    this.pendingAutoComplete = false;
  }
    handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    };

    onUpdateText = (text) => {
        this.setState({content:text});
    }

    onAddResourceLink = (resource) =>{
      let tagName = "lnkres_"+resource.type+"_"+resource.id;
      this.setState(prevState => ({
        tags: [...prevState.tags, tagName.trim().replace(",","")],
        tagInput: ''
      }));
    };

    handleTagInputChange = (event) => {
        const value = event.target.value;
        if (value.indexOf(",")!==-1 && value.replace(",","").trim().length>0)
        {
          this.setState(prevState => ({
            tags: [...prevState.tags, value.trim().replace(",","")],
            tagInput: ''
          }));
          setTimeout(() => {
              this.setState({tagInput:''});
          },10);
        }
        this.setState({ tagInput: value });
        if (!this.pendingAutoComplete) {
            this.pendingAutoComplete = true;
            MyAppPool.cNeoComu.getAutoCompleteTags(value).then(options => {
                
                this.setState({ autoCompleteOptions: options });
                this.pendingAutoComplete = false;
            });
        }
    };

    handleTagRemove = (index) => {
        this.setState(prevState => ({
            tags: prevState.tags.filter((_, i) => i !== index)
        }));
    };

    handleCheckboxChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleSelectChange = (event) => {
      this.setState({ createSection: event.target.value });
    };

    handleSubmit = () => {
        const { createSection,title, content, tags, isNSFW, isInappropriate, isSpoiler,isFeatured } = this.state;
        if (title.trim().length === 0 || content.trim().length === 0) {
          MyAppPool.cAlert.notifyUser(lng("You need to write a title and content to create a Thread."),"",400);
          return;
        }

        if (MyAppPool.cAuth.isGuest())
        {
          MyAppPool.cAuth.activateGlobalLoginBox();
          return;
        }
        
        let validSection = false;
        let section = MyAppPool.cNeoComu.getBaseSection();
        let sectionData = MyAppPool.session.neocomu.sections[section];
        if (!isSet(sectionData)){
          sectionData = {
            subsections:[],
            admins: [],
            mods: [],
            owner: 0
          };
        };
        sectionData.subsections.forEach(element => {
          if (validSection===true) return true;
          if (Number(element.id)===Number(createSection))
          {
            validSection=true;
            return true;
          }
        });
        if (validSection===false)
        {
          MyAppPool.cAlert.notifyUser(lng("You need to select a section create a Thread."),"",400);
          return;
        }
        
        MyAppPool.cAlert.notifyUser(lng("Creating Thread..."),"",200);
        MyAppPool.cNeoComu.createThread(title, content, createSection,{tags:tags.join(","), isNSFW, isInappropriate, isSpoiler, isFeatured}).then(
          (data)=>{
            if (data.ok)
            {
              MyAppPool.cAlert.notifyUser(lng("Thread Created, redirecting!"),"",200);
              MyAppPool.cLocation.updateUrl(getForumThreadPage(data.data.id,data.data.properties.title,"","#threadTop"),"");
            }else{
              MyAppPool.cAlert.notifyUser(lng("Houve um erro na criação da Thread, tente novamente!"),"",500);
            }
          }
        ).catch(
          (error)=>{
            MyAppPool.cAlert.notifyUser(lng("Houve um erro na criação da Thread, tente novamente!"),"",500);
        });
        // Submit the data (implementation depends on your API)
        //console.log({ title, content, tags, isNSFW, isInappropriate, isSpoiler });
    };

  componentDidMount()
  {
    CreateThread.getInitialValues();
    let parametersUrl = MyAppPool.cLocation.formatedUrlParameters;
    if (isSet(parametersUrl.section))
    {
      let sectionId = parametersUrl.section;
      this.setState({createSection:sectionId});
    }
    if (isSet(parametersUrl.tagNames))
    {
      let tagNames = parametersUrl.tagNames;
      this.setState({tags:tagNames.split(",")});
    }
  }
  static async getInitialValues()
  {
    let baseSection = MyAppPool.cNeoComu.getBaseSection();
    let p4 = MyAppPool.cNeoComu.getSection(baseSection);
    return p4;
  }
  componentWillReceiveProps()
  {
    CreateThread.getInitialValues();
  }

  render(){
    let meta = {title:lng('Create Thread')+' | NossoAnime',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    let url = getCurrentUrl();
    let sectionArgForThreadCreation="";
    if (url.indexOf("section")!==-1)
    {
      let sectionId = getRouteParamAtIndex(url,3);
      sectionArgForThreadCreation = "?section="+sectionId;
    }
    let section = MyAppPool.cNeoComu.getBaseSection();
    let sectionData = MyAppPool.session.neocomu.sections[section];
    if (!isSet(sectionData)){
      sectionData = {
        subsections:[],
        admins: [],
        mods: [],
        owner: 0
      };
    };
    
    
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div className="contentpage">
            <div  className=' contentArea'>
            <ContentBannerSetter />
            <div className="create-thread-form uk-card uk-card-default">
                <h2 style={{marginLeft:"10px", marginBottom:"0px"}}>/{lng("Criar Thread")}</h2>
                <input
                    style={{marginTop:'6px'}}
                    className="uk-input threadTextInput"
                    type="text"
                    name="title"
                    value={this.state.title}
                    onChange={this.handleInputChange}
                    placeholder={lng("Titulo da Thread")}
                />
                <select className="threadInputSelect" value={this.state.createSection} onChange={this.handleSelectChange}>
                <option value="-1">{lng("Select a section")}</option>
                {sectionData.subsections.map((elemId, index) => (
                    <option key={index} value={elemId.id}>
                        {elemId.properties.name}
                    </option>
                ))}
                </select>
                <WYSIWYGWriter id={"createThread"} hasSendButton={false}
                    placeHolder={lng("Contéudo da Thread")}
                    actions={
                        [
                        'superscript',
                        'link',
                        'image',
                        'youtube',
                        'emoji',
                        'spoiler',
                        'insertOrderedList',
                        'insertUnorderedList'
                        ]} 
                        actionClasses={{
                            insertOrderedList:'list-ol',
                            insertUnorderedList:'list-ul',
                            table:'border-all',
                            spoiler: 'eye-slash',
                            emoji:'grin-squint-tears',
                            youtube:'fab fa-youtube'
                            }}
                        actionTags={{
                            link:'<a ',
                            spoiler:'<span class="spoiler">'
                        }}
                        onUpdateText = {this.onUpdateText}
                        onCloseClick = {()=>{}}
                        onSendClick = {()=>{}}
                        startContent = {""}
                />

                <input
                    className="uk-input threadTextInput"
                    type="text"
                    name="tagInput"
                    list="autoCompleteOptions"
                    value={this.state.tagInput}
                    onChange={this.handleTagInputChange}
                    placeholder={lng("Enter tags, separated by commas")}
                    style={{ textAlign: 'left' }}
                />
                <datalist id="autoCompleteOptions">
                    {this.state.autoCompleteOptions.map((option, index) => (
                        <option key={index} value={option+","} />
                    ))}
                </datalist>

                <div className="endSection">
                <div className="tags-list">
                {this.state.tags.map((tag, index) => {
                      if (tag.indexOf("lnkres_")!==-1)
                        return <></>;
                        return <span key={index} className="tag-badge">
                            {tag} <button type="button" onClick={() => this.handleTagRemove(index)}>X</button>
                            </span>
                })}
                {this.state.tags.map((tag, index) => {
                      if (tag.indexOf("lnkres_")==-1)
                        return <></>;
                        let extractInfo = tag.split("_");
                        let resourceId = Number(extractInfo[2]);
                        let resourceType = Number(extractInfo[1]);
                        return <span key={index} className="tag-badge">
                            <ResourceLinkBox resourceType={resourceType} resourceId={resourceId} /> 
                            <button type="button" onClick={() => this.handleTagRemove(index)}>X</button>
                            </span>
                })}
                </div>
                <div style={{marginBottom:"4px"}}>
                  <label>{lng("Link Resources to Thread")}</label>
                  <SearchLinkResourceBox onAdd = {this.onAddResourceLink}/>
                </div>

                <div className="radio-group">
                    {lng("_warningThreadText")}
                    <label><input type="checkbox" name="isNSFW" checked={this.state.isNSFW} onChange={this.handleCheckboxChange} /> {lng("NSFW Content")} <span className="explainText">({lng("_NSFWExplanation")})</span></label>
                    <label><input type="checkbox" name="isInappropriate" checked={this.state.isInappropriate} onChange={this.handleCheckboxChange} /> {lng("Contains Inappropriate Content")} <span className="explainText">({lng("_InapropriateContentExplanation")})</span></label>
                    <label><input type="checkbox" name="isSpoiler" checked={this.state.isSpoiler} onChange={this.handleCheckboxChange} /> {lng("Contains Spoilers")} <span className="explainText">({lng("_SpoilerExplanation")})</span></label>
                    {MyAppPool.cAuth.isDataMod()?
                    <label><input type="checkbox" name="isFeatured" checked={this.state.isFeatured} onChange={this.handleCheckboxChange} /> {lng("Featured")} <span className="explainText">({lng("_isFeaturedExplanation")})</span></label>
                    :<></>}
                  </div>

                <button className="create-button" type="button" onClick={this.handleSubmit}>{lng("Criar Thread")}</button>
            </div>
            </div>
            
            </div>
          </div>
        </div>
      );
}

}

export default CreateThread;
