import React, { Component } from 'react';
import "./Erro404.css";
import MyAppPool from '../AppPool';
import SearchBox from './SearchBox';
import LinkV2 from './LinkV2';
import { lng } from '../ulanguages/language';

class Erro404 extends Component {
  constructor(props){
    super(props);
    MyAppPool.setCurrentHttpStatus(404);
  }

  render(){
    return (

            <div className="comp_err404">
            <h1>{lng("404 Land")}</h1>
            <span>{lng("If you are here, it probably means you are lost!")}</span> <br/>
            <img src="/zorolost.gif"></img> <br/>
            <span>{lng("If you are not lost, then it means a Panda ate this Bamboo Page!")}</span>
            <img src="/Panda404.png"></img> <br/>
            {lng("Either way, this is not the page you were looking for.")} <br/> <br/>
            
            <LinkV2 className="uk-button uk-button-primary" to="/#top">{lng("Go Home")}</LinkV2><br/><br/>
            {lng("Or Find Something Else:")}
            <SearchBox />
            <br/> <br/>
            Cod: Error 404 Not Found - {lng("Pandas are Eating Bamboo Made Pages")}<br/> <br/>
            <br/> <br/><br/> <br/><br/> <br/><br/> <br/><br/> <br/>
       </div>

      );
}

}

Erro404.propTypes = {
  
};
Erro404.defaultProps = {
  
};

export default Erro404;
