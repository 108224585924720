import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import YouTube from 'react-youtube';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { convertDataToShowable, getImgAddress, getRouteParamAtIndex, getSiteShortName } from '../util/text';
import { contentTypeToFastType, isSet, mobToObj } from '../util/typeu';
import BreadCumbs from '../view_component/BreadCumbs';
import CapaSection from '../view_component/CapaSection';
import Erro404 from '../view_component/Erro404';
import SkeletonBlock from '../view_component/SkeletonBlock';
import SubNavigation from '../view_component/SubNavigation';
import TextContentBox from '../view_component/TextContentBox';
import { isMobile } from './../util/mobile';
import { getCurrentUrl, isServer } from './../util/windowUtil';
import CSSTransition2 from './../view_component/CSSTransition2';

import { getUserConfigRoute, getUserFavoriteRoute, getUserListRoute, getUserRoute } from '../store/AppRoutes';
import CharacterGeralView from './charactersub/CharacterGeralView';
import CapaSectionUserPage from '../view_component/CapaSectionUserPage';
import { getUserAvatar } from '../util/icon';
import UserGeralView from './usersub/UserGeralView';
import UserListView from './usersub/UserListView';
import UserFavoritesView from './usersub/UserFavoritesView';
import UserConfigView from './usersub/UserConfigView';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import WYSIWYGWriter from '../view_component/WYSIWYGWriter';
import { genRandId } from '../view_component/WYSIWYGWriterUtils';
import EditableDescription from '../view_component/EditableDescription';
import { serverRegisterFetch } from '../util/updateManager';

  

function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}

@observer
class User extends Component {
  constructor(props)
  {
    super(props);
    this.state = {ready:true, editorId:genRandId(), editBio:false};
    serverRegisterFetch(User.getInitialValues);
  }
  static  getUserWallPageSize(){return 3;}
  componentDidMount()
  {
    User.getInitialValues();
  }
  static async getInitialValues()
  {
    let userId = getRouteParamAtIndex(getCurrentUrl(),2);
    if (isSet(userId))
    {
      MyAppPool.session.usersWallItems[userId] = {};
      let p1 = MyAppPool.cUser.fetchUserById(userId);
      let p2 = MyAppPool.cUser.getUserRecentTasks();
      let p3 = MyAppPool.cLatestContent.getUserWall(userId,0,User.getUserWallPageSize());
      let p4 = MyAppPool.cNeoComu.getUserThreadRepliesWithHotRanking(userId,1,User.getUserWallPageSize());
      let p5 = MyAppPool.cNeoComu.getThreadsByUserId(userId,1,User.getUserWallPageSize());
      await Promise.all([p1,p2,p3,p4,p5]);
      
     //console.log("User:::",userId);
    }
    return Promise.resolve();
  }
  componentWillReceiveProps()
  {
    User.getInitialValues();
  }

  render(){
    let {ready,editBio} = this.state;
    let userId = getRouteParamAtIndex(getCurrentUrl(),2);
    if (MyAppPool.session.userFetchEmpty[''+userId]===true)
      return <Erro404/>;
    let current = MyAppPool.cUser.currentUserPage;
    let tabBaseUrl = current.baseUrl;
    let configItem = undefined;
    if (current.id===MyAppPool.currentUser.userid)
      configItem={name:lng('Configurações/Preferências'),url:tabBaseUrl+'/'+lng('configuracoes')};
    let tabs = [
      {name:lng('Geral'),url:tabBaseUrl},
      {name:lng('Listas'),url:tabBaseUrl+'/'+lng('listas')},
      {name:lng('Favoritos'),url:tabBaseUrl+'/'+lng('favoritos')},
    ];
    if (isSet(configItem))
      tabs.push(configItem);

    let lists = [];
    let listPresence = [];
    let reactionPosts = [];
    let reactionReady = 0;
    let resourceType = contentTypeToFastType('user');
    if (current.ready)
    {
      reactionPosts = MyAppPool.session.reactionPost[resourceType][current.id];
      if (typeof reactionPosts ==='undefined')
        {
          reactionPosts=[];
          reactionReady = 0;
        }else
          reactionReady = 1;   
        lists = MyAppPool.session.user.lists[resourceType];
        listPresence = MyAppPool.cLists.getListPresence(resourceType,current.id);
        if (!Array.isArray(lists))
          lists = [];
        
    }
    

    let meta = {title:lng('Usuário')+' | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    let displayTitles = [];
    let userName = '';
    let userDescription = lng('Resumo ainda não foi escrito.');
    let canEdit = false;
    if (current.ready)
    {
      let desc = lng('Usuário')+' '+current.name ;
      if (current.description.length>0)
      {
        userDescription = current.description;
        desc=current.description;
      }
      meta =  {
        title:current.name +' - ' + lng("Usuário") + ' | '+getSiteShortName()+'',
        desc:desc,
        keywords:current.titles.join(","),
        img:getUserAvatar(current.mainimg,current.hash),
        imgwidth:'225',
        imgheight:'338',
        imgtype:'jpg'
      };
      displayTitles=current.displayTitles;
      userName =current.name;
      canEdit = (current.id === MyAppPool.currentUser.userid);
    }
    //console.log("CURRENT:::",mobToObj(current));
    
    if (current.empty)
      return <Erro404/>;
  
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
        <div className="contentpage">
          <ContentBannerSetter images={[current.banner]} modes={["bg"]}/>
          {isMobile.any?
            <div className="mobileUserAvatar"><img className="mobileUserAvatarImg" src={getUserAvatar(current.mainimg,current.hash)} alt={lng("User")+ " "+userName+" "+lng("avatar")}></img></div>
            :<></>
            }
          <div className='uk-flex uk-flex-row topsection'>
          {!isMobile.any?
            <div className="capa_left_column">
            <CapaSectionUserPage ready={ready} img={getUserAvatar(current.mainimg,current.hash)}
            resourceType={contentTypeToFastType('user')}
            resourceId={current.id}
            lists={lists}
            listsPresence ={listPresence}
            canEdit={canEdit}
            /> </div>
            :<></>
            }
            <div className="contentInfo">
              <BreadCumbs />
              {current.ready ?
               <>
                <h1 className="smallerheading"> <span id='title'>{userName} &nbsp;</span>
                </h1>
                <h2 className="smallerheading2"> {
                  displayTitles.map((elem,index)=>{
                    return <span className={elem.rainbow?'rainbowText':''}>{elem.title} </span>
                  })
                }</h2>
                <EditableDescription startContent={userDescription} 
                onSend={(content)=>MyAppPool.cUser.updateAuthedUserProfile({bio:content})}
                extraActions={[
                  'image',
                  'youtube',
                  'emoji'
                ]}
                canEdit={canEdit}
                />                
                </>
                :<>
                <SkeletonBlock style={{'width':'250px',height:'50px', 'marginTop':'8px'}}/>
                <SkeletonBlock style={{'width':'300px',height:'25px', 'marginTop':'8px'}}/>
                <SkeletonBlock style={{'width':'95%',height:'150px', 'marginTop':'15px'}}/>
                <SkeletonBlock style={{'width':'250px',height:'30px', 'marginTop':'15px'}}/>
                <SkeletonBlock style={{'width':'350px',height:'30px', 'marginTop':'15px'}}/>
                </>
                }
                </div>
          </div>
          
          <div className="naveg" data-uk-sticky="offset: 0">
            <div className = "uk-flex uk-flex-row"> 
            <SubNavigation items={tabs}/> 
            {!isMobile.any?
            <button aria-label={lng('MAB por MaxBrasi')} className="link-button tinylink">{lng('MAB por MaxBrasi')}</button>
            :<></>}
            </div>
            {isMobile.any?
            <button aria-label={lng('MAB por MaxBrasi')} className="link-button tinylink">{lng('MAB por MaxBrasi')}</button>
            :<></>}
          </div>

          <div className="authortag">
                    </div>
          {//!isMobile.any?
          //:<></>
        }
          <Route render={({ location }) => {
            const { pathname, key } = location;
            return (
              <div style={{position:'relative'}}>
                <CSSTransition2
                  in={true}
                  key={key}
                  classNames="switch-node"
                  timeout={{enter: 750, exit: 0}}
                >
                  <div>
                  <Switch>
                    <Route path={getUserRoute(':id',':name','')} exact={true}>
                      <UserGeralView current={current}/>
                    </Route>
                    <Route path={getUserListRoute(':id',':name','')} exact={true}>
                    <UserListView current={current}/>
                    </Route>
                    <Route path={getUserFavoriteRoute(':id',':name','')} exact={true}>
                    <UserFavoritesView  current={current}/>
                    </Route>
                    <Route path={getUserConfigRoute(':id',':name','')} exact={true}>
                    <UserConfigView  current={current}/>
                    </Route>
                    <Route path="*" render={() => <Erro404 />} />
                  </Switch>
                  </div>
                </CSSTransition2>
              </div>
          )
        }}/>

        </div>
        </div>
      );

}
}

export default User;
