import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../../AppPool';
import { lng } from '../../ulanguages/language';
import { getImgAddress, getRouteParamAtIndex } from '../../util/text';
import { getIfIsSetElems, isSet } from '../../util/typeu';
//import LoginBox from '../view_component/LoginBox';
import { getCurrentUrl, isServer } from '../../util/windowUtil';
import SpinnerBox from '../../view_component/SpinnerBox';

import './home.css';
import ContentBannerSetter from '../../view_component/ContentBannerSetter';
import ThreadPreview from "../../view_component/ThreadPreview";
import InfiniteScrollBlock from "../../view_component/InfiniteScrollBlock";
import { serverRegisterFetch } from "../../util/updateManager";
import ThreadPinned from "../../view_component/ThreadPinned";
import ThreadTag from "../../view_component/ThreadTag";
import { getForumThreadsByTagPath } from "../../store/AppRoutes";
import { toJS } from "mobx";
import Erro404 from "../../view_component/Erro404";

let pageSizeGlob = 10;
@observer
class ForumThreadsByTag extends Component {

  constructor(props) {
    super(props);

    this.state = {currentPage:1,pageSize:pageSizeGlob, lastFetchSize:1};
    serverRegisterFetch(ForumThreadsByTag.getInitialValues);
  }

  componentDidMount()
  {
    ForumThreadsByTag.getInitialValues();
  }
  static async getInitialValues()
  {
    let tagId = getRouteParamAtIndex(getCurrentUrl(),3);
    if (isSet(tagId))
    {
        let p1 = MyAppPool.cNeoComu.getTagThreads(tagId,1,pageSizeGlob);
        return Promise.all([p1]);
    }else
    {
    return Promise.resolve();
}
  }
  componentWillReceiveProps()
  {
    ForumThreadsByTag.getInitialValues();
  }

  loadNextPage = ()=>{
    let tagId = getRouteParamAtIndex(getCurrentUrl(),3);
    if (isSet(tagId))
    {
        let {lastFetchSize,currentPage,pageSize} = this.state;
        if (lastFetchSize>0)
        {
            return MyAppPool.cNeoComu.getTagThreads(tagId,currentPage+1,pageSize).then(data=>{
                this.setState({lastFetchSize:data.length,currentPage:currentPage+1});
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    }
  }

  render(){
    let {pageSize,currentPage} = this.state;
    let tag = Number(getRouteParamAtIndex(getCurrentUrl(),3));
    if (isNaN(tag))
    {
        //console.log("TAG IS NaN");
        return <Erro404 />
    }
    let tagThreadIDPages = getIfIsSetElems(MyAppPool.session.neocomu.tagThreads,[""+tag,""+pageSize]);
    let allPages=[];
    let name='';
    if (isSet(tagThreadIDPages)) 
    {
        allPages = Object.values(tagThreadIDPages).flat();
        if (allPages.length>0)
        {
          let threadData = MyAppPool.session.neocomu.threads[allPages[0]];
          if (isSet(threadData.tag))
            name= threadData.tag.properties.name;
        }else{
            return <Erro404 />
        }
    }
    let meta = {title:lng('Forum')+" Tag"+name+' | NANI',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};

    

      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div >
            <div  className=' contentArea'>
            <ContentBannerSetter />
            <div className="threadsSection">
                <h2>/tag:{name}</h2>
                
                <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                {allPages.map((elemId,index)=>{
                let elem = MyAppPool.session.neocomu.threads[elemId];
                return  <ThreadPreview key={index} thread={elem} /> 
                })}
                </InfiniteScrollBlock>
            </div>
            </div>
          </div>
        </div>
      );
}

}

export default ForumThreadsByTag;
