import React, { Component } from 'react';
import "./TextContentBox.css";
import { isSet } from '../util/typeu';
import HtmlToReact from 'html-to-react';
const HtmlToReactParser = HtmlToReact.Parser;

function splitStringAtFifthDot(inputString) {

  const dotsToSplitAt = 5;
  const dot = ".";
  const parts = [];
  let currentIndex = 0;
  let dotCount = 0;
  if (!isSet(inputString))
  return parts;

  while (currentIndex < inputString.length) {
      if (inputString[currentIndex] === dot) {
          dotCount++;
          if (dotCount === dotsToSplitAt) {
              const part = inputString.substring(0, currentIndex + 1); // Include the dot
              parts.push(part);
              inputString = inputString.substring(currentIndex + 1);
              currentIndex = 0;
              dotCount = 0;
              continue;
          }
      }
      currentIndex++;
  }

  if (inputString.length > 0) {
      parts.push(inputString);
  }

  return parts;
}

const isValidNode = function () {
    return true;
};

  
  
const htmlToReactParser = new HtmlToReactParser();

class TextContentBox extends Component {
    constructor(props){
    super(props);
    let {removeRb} = props;
    
    }
  componentDidMount()
  {
    
  }

  render(){
      let {content} = this.props;
      let contentFix = content.replaceAll('< rb >','').replaceAll('</ rb >','')
      .replaceAll('<rb>','').replaceAll('</rb>','').replaceAll('<br>','<br />')
      .replaceAll('< br >','<br />');
      let splitContent = splitStringAtFifthDot(contentFix);
      let renderedParts = [];
      for(let i=0;i<splitContent.length;i++)
      {
            let elem = splitContent[i];
            renderedParts.push(elem = htmlToReactParser.parse(
              elem
            ));
      }
    return (
        <p className="comp_textcontentbox">
            {renderedParts}
        <br/>
        
        </p>);
}
}

export default TextContentBox;
