import React, { Component } from 'react';
import { isServer } from '../util/windowUtil';
import "./ChangeableImg.css";
import { PropTypes } from 'prop-types';
import { getImgAddress } from '../util/text';

class ChangeableImg extends Component {

  constructor(props)
  {
    super(props);
    this.state = {loadClass:'comp_changeableimgloading',addPadding: false, src:this.props.src};
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.imageRef = React.createRef();
  }
  componentDidMount()
  {

  }
  componentWillReceiveProps(nextProps) {
    this.setState({src:nextProps.src});
  }

  componentDidUpdate(prevProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    if (prevProps.src !== this.props.src){
        this.setState({loadClass:'comp_changeableimgloading'});
    }
  }
  handleLoadError = () =>{
    this.setState({src:getImgAddress(null)});
  }

  handleImageLoaded()
  {
    this.setState({loadClass:''});
    if (this.imageRef.current) {
      if (this.imageRef.current.height < this.props.minHeight) {
        this.setState({ addPadding: true });
      } else {
        this.setState({ addPadding: false });
      }
    }
  }

  render(){
    let paddingAmount= this.props.usePadding?this.props.paddingAmount:0;
    let style = this.props.style;
    
    style = style? {...style,...{"paddingTop":paddingAmount+"px"}}:{"paddingTop":paddingAmount+"px"}
    if (isServer())
      return <img {...this.props} src={this.state.src} className={this.props.className}/>; //return normal img on server load

        return this.state.addPadding?
          <img {...this.props} src={this.state.src} className={this.props.className + ' ' + this.state.loadClass} style={style} ref={this.imageRef}  onLoad={this.handleImageLoaded} onError={this.handleLoadError}/>
          :
          <img {...this.props} src={this.state.src} className={this.props.className + ' ' + this.state.loadClass} ref={this.imageRef} onLoad={this.handleImageLoaded}  onError={this.handleLoadError}/>
        ;
    }
}

ChangeableImg.propTypes = {
  minHeight:PropTypes.number,
  paddingAmount:PropTypes.number,
  usePadding:PropTypes.number
};
ChangeableImg.defaultProps = {
  minHeight:225,
  paddingAmount:80,
  usePadding:false
};

export default ChangeableImg;
