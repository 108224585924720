
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { getRelatedTypeIcon } from '../util/icon';
import { lng } from '../ulanguages/language';
import ScrollToTopButtom from '../view_component/ScrollToTopButton';
import SkeletonBlock from '../view_component/SkeletonBlock';
import MyAppPool from './../AppPool';
import { getForumCreateThreadPage, getObraRoute } from './../store/AppRoutes';
import { isMobile } from './../util/mobile';
import { getAnchorName, getImgAddress, getSiteShortName, localeDateTime } from './../util/text';
import { isSet } from './../util/typeu';
import { isServer } from './../util/windowUtil';
import NoRecordsFoundCard from './../view_component/NoRecordsFoundCard';
import RelatedRowCard from './../view_component/RelatedRowCard';
import './resourceMedia.css';
import { serverRegisterFetch } from '../util/updateManager';
import { PropTypes } from 'prop-types';
import TrailerYoutubePlayBig from '../view_component/TrailerYoutubePlayBig';
import { prettyDateTimeStringNoSecond } from '../util/timeUtil';
import DropDown from '../view_component/DropDown';
import DataVideoUploadForm from '../view_component/DataVideoUploadForm';
import FileSelect from '../view_component/FileSelect';
import DataImageUploadForm from '../view_component/DataImageUploadForm';
import ForumThreadsByTagBox from '../view_component/ForumThreadsByTagBox';
import LinkV2 from '../view_component/LinkV2';

let currentResourceType=0;
let currentResourceId=128642;
let pageSizeGlob = 10;
@observer
class NewsByTagSubPage extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    }
    }

    componentWillMount()
    {

    }
    render()
    {
        
        let meta = {title:lng('News')+' | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:''};
        
        let mainWidth="60%";
        let mainMarginLeft="15px";
        if (isMobile.any)
        {
          mainWidth="95%";
          mainMarginLeft="2.5%";
        }

        return (<div className=" contentArea uk-flex uk-flex-row">
        {!isMobile.any?
        <div style={{width:"21%",marginLeft:"15px"}}>
          {//left collum
          }
        </div>
        :<></>}

        <div style={{width:mainWidth,marginLeft:mainMarginLeft}}>
            <h3>{lng("Latest Discussions")}
            <LinkV2 to={getForumCreateThreadPage("?tagNames="+this.props.tag,"#top")} className="uk-button uk-button-default  sendButton">{lng("Criar uma Thread")}</LinkV2>
            </h3>

            <ForumThreadsByTagBox tag={this.props.tag}/>
        </div>

        {!isMobile.any?
        <div  style={{width:"15%",marginLeft:"15px"}}>
          <ScrollToTopButtom />
        </div>
        :<></>}

      </div>);


    }

}

NewsByTagSubPage.propTypes = {
    title: PropTypes.string,
    tag: PropTypes.string.isRequired,
};
NewsByTagSubPage.defaultProps = {
    tag:''
};

export default NewsByTagSubPage;