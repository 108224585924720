
import levenshtein from 'js-levenshtein';
import { toJS } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { lng } from '../ulanguages/language';
import { capitalizeFirstLetterAllWords, convertAnimeStatusNumberToName, convertMangaStatusNumberToName, convertSeasonNumberToName } from '../util/text';
import MyAppPool from '../AppPool';
import { getCurrentLanguage } from '../ulanguages/language';
import { fetchAndProcess } from '../util/net';
import { contentTypeToFastType, isSet, mobToObj } from '../util/typeu';


function tryCastStringToNumber(val)//should return a number or undefine the variable
{
    let result = parseInt(val);
    if (isNaN(result))
        return undefined;
    return result;
}
let sortSearchingForText=false;//code smell, yeah
let searchText = '';

function animeSortFunction(a,b)
{
    if (sortSearchingForText)
    {
        let aScore=99999999;
        let bScore=99999999;
        let tempScore=99999999;
        for(let i=0; i<a.titleList.length;i++)
        {
            tempScore = levenshtein(a.titleList[i],searchText);
            if (tempScore<aScore)
                aScore=tempScore;
        }
        for(let i=0; i<b.titleList.length;i++)
        {
            tempScore = levenshtein(b.titleList[i],searchText);
            if (tempScore<bScore)
                bScore=tempScore;
        }
        if (aScore < bScore)
            return -1;
        if (aScore > bScore)
            return 1;
        return 0;
    }
    if (a.genres.indexOf('Hentai')!==-1 && b.genres.indexOf('Hentai')===-1)
        return 1;
    if (a.genres.indexOf('Hentai')===-1 && b.genres.indexOf('Hentai')!==-1)
        return -1;

    if ( a.info.airfrom < b.info.airfrom ){
        return 1;
      }
      if ( a.info.airfrom > b.info.airfrom ){
        return -1;
      }
      return 0;
}

export default class SearchAnimeMangaController
{
    getAnimeSearch(name,yearArg,seasonArg,type,airingArg,genres,genreIsOrArg,dateFrom,dateTo)
    {
        let year = yearArg;
        if (year==='')
            year=undefined;
        if (isSet(name) && name!=='')
        {
            sortSearchingForText=true;
            searchText=name;
        }
        else
            sortSearchingForText=false;
        
        let season = tryCastStringToNumber(seasonArg);
        let airing = tryCastStringToNumber(airingArg);
        let genreIsOr = tryCastStringToNumber(genreIsOrArg);
        let lang = getCurrentLanguage();
        let filtersParam = JSON.stringify({name,year,season,type,airing,genres,genreIsOr,dateFrom,dateTo});
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let animes = data0.data;
            
            for(let i=0;i<animes.length;i++)
            {
                let item = animes[i];
                item.genreList = item.genres.split('|');
                item.titleList = item.titles.split('|');
                for(let k=0;k<item.titleList.length;k++)
                    item.titleList[k]=capitalizeFirstLetterAllWords(item.titleList[k]);
            }
            let sortedAnime = animes.sort(animeSortFunction)

            let pageSize=40;
            let contentLength = animes.length;
            let sortType='year_desc';
            let pagesNumber=Math.ceil(sortedAnime.length/pageSize);
            let pages = Array.from(Array(pagesNumber).keys());
            if (pages.length==0)
                pages[0]=0;
            //console.log("pages",pages);

            let contentType = contentTypeToFastType('anime');
            MyAppPool.session.search.content[contentType]=sortedAnime;
            MyAppPool.session.search.contentInfo[contentType]={pages,pageSize,contentLength,sortType};
            //console.log('sortedanime:',mobToObj(MyAppPool.session.search.content[contentType]));
            return Promise.resolve();
        },
        routes.searchAnime,true,
        {lng:lang,filters:filtersParam})
        .catch(e => console.error(("Error Getting Anime Search "),e));

        //{lng:"pt",filters:{name:"teste123",year:2020,season:0,type:"ova",airing:1,genres:["acao","aventura"],genreIsOr:1}}
    }
    getSearchAnimeFilters()
    {
        let lang = getCurrentLanguage();
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let {types,genres} = data0.data;
            let dataYear = data0.data.years;
            dataYear = mobToObj(dataYear);
            let years = dataYear.reverse();
            let typesArr = [];
            let typesTexts={},airingTexts={},filterTypeTexts={},seasonsTexts={};
            filterTypeTexts={'':lng('Filtro: Exclusivo'),'1':lng('Filtro: Inclusivo')};
            

            for(let i=0;i<types.length;i++)
            {
                types[i].text = lng(types[i].type);
                typesTexts[types[i].type]=lng(types[i].type);
            }
            let yearsArr = [];
            for(let i=0;i<years.length;i++)
            {
                yearsArr[i]=years[i]["year"];
                if (yearsArr[i]==null)
                    yearsArr[i]="null";
            }
            yearsArr[0] = "<"+yearsArr[0];
            yearsArr[yearsArr.length]="null";
            let seasons = [];
            for(let i=0;i<4;i++)
            {
                seasons[i] ={id:i,name:convertSeasonNumberToName(i)};
                seasonsTexts[''+i]=seasons[i].name;
            }
            let airing = [];
            for(let i=0;i<3;i++)
            {
                airing[i] ={id:i,name:convertAnimeStatusNumberToName(i)};
                airingTexts[''+i]=airing[i].name;
            }
            let filterTexts = {filterTypeTexts,typesTexts,airingTexts,seasonsTexts};
            let filters = {types,genres,seasons,airing,years:yearsArr,filterTexts};

            let contentType = contentTypeToFastType('anime');
            MyAppPool.session.search.filters[contentType]=filters;

            return Promise.resolve();
        },
        routes.searchAnimeFilters,true,
        {lng:lang})
        .catch(e => console.error(("Error Getting Anime Search Filters"),e));
    }
    getAnimeSearchWhenEmpty()
    {
        let contentType = contentTypeToFastType('anime');
        let lang = getCurrentLanguage();
        //console.log("Categories:",toJS(MyAppPool.session.search.contentEmpty[contentType]));
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let categories = data0.data;
            let {popularThisSeason,comingNext,allTimePopular} = categories;

            for(let i=0;i<popularThisSeason.length;i++)
            {
                let item = popularThisSeason[i];
                item.genreList = item.genres.split('|');
            }
            for(let i=0;i<comingNext.length;i++)
            {
                let item = comingNext[i];
                item.genreList = item.genres.split('|');
            }
            for(let i=0;i<allTimePopular.length;i++)
            {
                let item = allTimePopular[i];
                item.genreList = item.genres.split('|');
            }

            
            MyAppPool.session.search.contentEmpty[contentType]=categories;
            
            return Promise.resolve();
        },
        routes.searchAnimeWhenEmpty,true,
        {lng:lang})
        .catch(e => console.error(("Error Getting Anime Search "),e));

        //{lng:"pt",filters:{name:"teste123",year:2020,season:0,type:"ova",airing:1,genres:["acao","aventura"],genreIsOr:1}}
    }

    getMangaSearch(name,yearArg,seasonArg,type,airingArg,genres,genreIsOrArg,dateFrom,dateTo)
    {
        let year = yearArg;
        if (year==='')
            year=undefined;
        if (isSet(name) && name!=='')
        {
            sortSearchingForText=true;
            searchText=name;
        }
        else
            sortSearchingForText=false;
        
        let season = tryCastStringToNumber(seasonArg);
        let airing = tryCastStringToNumber(airingArg);
        let genreIsOr = tryCastStringToNumber(genreIsOrArg);
        let lang = getCurrentLanguage();
        let filtersParam = JSON.stringify({name,year,season,type,airing,genres,genreIsOr,dateFrom,dateTo});
        //console.log("filters:",filtersParam);
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let mangas = data0.data;
        
            for(let i=0;i<mangas.length;i++)
            {
                let item = mangas[i];
                item.genreList = item.genres.split('|');
                item.titleList = item.titles.split('|');
                for(let k=0;k<item.titleList.length;k++)
                    item.titleList[k]=capitalizeFirstLetterAllWords(item.titleList[k]);
            }
            let sortedAnime = mangas.sort(animeSortFunction)

            let pageSize=40;
            let contentLength = mangas.length;
            let sortType='year_desc';
            let pagesNumber=Math.ceil(sortedAnime.length/pageSize);
            let pages = Array.from(Array(pagesNumber).keys());
            if (pages.length==0)
                pages[0]=0;
            //console.log("pages",pages);

            let contentType = contentTypeToFastType('manga');
            MyAppPool.session.search.content[contentType]=sortedAnime;
            MyAppPool.session.search.contentInfo[contentType]={pages,pageSize,contentLength,sortType};
            //console.log('sortedmanga:',mobToObj(MyAppPool.session.search.content[contentType]));
            return Promise.resolve();
        },
        routes.searchManga,true,
        {lng:lang,filters:filtersParam})
        .catch(e => console.error(("Error Getting Anime Search "),e));

        //{lng:"pt",filters:{name:"teste123",year:2020,season:0,type:"ova",airing:1,genres:["acao","aventura"],genreIsOr:1}}
    }
    getSearchMangaFilters()
    {
        let lang = getCurrentLanguage();
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let {types,genres} = data0.data;
            let dataYear = data0.data.years;
            dataYear = mobToObj(dataYear);
            let years = dataYear.reverse();
            let typesArr = [];
            let typesTexts={},airingTexts={},filterTypeTexts={},seasonsTexts={};
            filterTypeTexts={'':lng('Filtro: Exclusivo'),'1':lng('Filtro: Inclusivo')};
            

            for(let i=0;i<types.length;i++)
            {
                types[i].text = lng(types[i].type);
                typesTexts[types[i].type]=lng(types[i].type);
            }
            let yearsArr = [];
            for(let i=0;i<years.length;i++)
            {
                yearsArr[i]=years[i]["year"];
                if (yearsArr[i]==null)
                    yearsArr[i]="null";
            }
            yearsArr[0] = "<"+yearsArr[0];
            yearsArr[yearsArr.length]="null";
            let seasons = [];
            for(let i=0;i<4;i++)
            {
                seasons[i] ={id:i,name:convertSeasonNumberToName(i)};
                seasonsTexts[''+i]=seasons[i].name;
            }
            let airing = [];
            for(let i=0;i<3;i++)
            {
                airing[i] ={id:i,name:convertMangaStatusNumberToName(i)};
                airingTexts[''+i]=airing[i].name;
            }
            let filterTexts = {filterTypeTexts,typesTexts,airingTexts,seasonsTexts};
            let filters = {types,genres,seasons,airing,years:yearsArr,filterTexts};

            let contentType = contentTypeToFastType('manga');
            MyAppPool.session.search.filters[contentType]=filters;

            return Promise.resolve();
        },
        routes.searchMangaFilters,true,
        {lng:lang})
        .catch(e => console.error(("Error Getting Anime Search Filters"),e));
    }
    getMangaSearchWhenEmpty()
    {
        let lang = getCurrentLanguage();
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let categories = data0.data;
            let {popularThisSeason,comingNext,allTimePopular} = categories;

            for(let i=0;i<popularThisSeason.length;i++)
            {
                let item = popularThisSeason[i];
                item.genreList = item.genres.split('|');
            }
            for(let i=0;i<comingNext.length;i++)
            {
                let item = comingNext[i];
                item.genreList = item.genres.split('|');
            }
            for(let i=0;i<allTimePopular.length;i++)
            {
                let item = allTimePopular[i];
                item.genreList = item.genres.split('|');
            }

            let contentType = contentTypeToFastType('manga');
            MyAppPool.session.search.contentEmpty[contentType]=categories;
            //console.log(toJS(MyAppPool.session.search.contentEmpty[contentType]));
            return Promise.resolve();
        },
        routes.searchMangaWhenEmpty,true,
        {lng:lang})
        .catch(e => console.error(("Error Getting Manga Search "),e));

        //{lng:"pt",filters:{name:"teste123",year:2020,season:0,type:"ova",airing:1,genres:["acao","aventura"],genreIsOr:1}}
    }

    getFiltersByContentType(contentType)
    {
        let fastContentType = contentTypeToFastType(contentType);
        return MyAppPool.session.search.filters[fastContentType];
    }


    

}