
import { merge } from 'lodash';
import { routes } from '../store/ApiRoutes';
import MyAppPool from '../AppPool';
import { fetchAndProcess, multipleFetchAndProcess } from '../util/net';
import { contentTypeToFastType, getDefaultStarter, mobToObj } from '../util/typeu';

export default class UserVotesController
{
    sendReviewVote
    (reviewId,reviewOfType,reviewOf)
    {
        if (MyAppPool.cAuth.isGuest())
        {
        MyAppPool.cAuth.activateGlobalLoginBox();
        return Promise.reject();
        }
        let resourceType = contentTypeToFastType('review');

        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);

            MyAppPool.session.user.votes[''+resourceType][''+reviewId] = 1;

            let temp = MyAppPool.session.reviewData[''+reviewId];
            if (temp)
                MyAppPool.session.reviewData[''+reviewId].likes = MyAppPool.session.reviewData[''+reviewId].likes + 1;

            return Promise.resolve();
        }
        ,
        routes.sendReviewVote,false,
        {reviewId})
        .catch((e)=>{
            MyAppPool.cAlert.notifyGenericError(e,"send review vote");
            console.error("Error sending review vote",e);
        });
    }

    sendReactionPostVote
    (reactId,reactionOfType,reactionOf,value)
    {
        if (MyAppPool.cAuth.isGuest())
        {
        MyAppPool.cAuth.activateGlobalLoginBox();
        return Promise.reject();
        }
        let resourceType = contentTypeToFastType('reactionPost');

        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);

            let finalVoteValue = data0.data;
            if (finalVoteValue+value==0)
                MyAppPool.session.user.votes[''+resourceType][''+reactId] = 0;
            else
                MyAppPool.session.user.votes[''+resourceType][''+reactId] = value;

            let temp = MyAppPool.session.reactionData[''+reactId];
            if (temp)
                MyAppPool.session.reactionData[''+reactId].votes = MyAppPool.session.reactionData[''+reactId].votes + finalVoteValue;

            return Promise.resolve();
        }
        ,
        routes.sendReactionPostVote,false,
        {reactionPostId:reactId,voteValue:value})
        .catch((e)=>{
            MyAppPool.cAlert.notifyGenericError(e,"send reaction post vote");
            console.error("Error sending reaction post vote",e);
        });
    }


    sendWorkResourceLikeDislike
    (resourceType,resourceId,value)
    {
        if (MyAppPool.cAuth.isGuest())
        {
        MyAppPool.cAuth.activateGlobalLoginBox();
        return Promise.reject();
        }

        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);

            let finalVoteValue = data0.data;
            MyAppPool.session.user.votes[''+resourceType][''+resourceId] = finalVoteValue;

            return Promise.resolve();
        }
        ,
        routes.sendWorkResourceLike,false,
        {resourceType,resourceId,voteValue:value});
    }

    sendNeoComuResourceVote
    (resourceType,resourceId,value)
    {
        if (MyAppPool.cAuth.isGuest())
        {
        MyAppPool.cAuth.activateGlobalLoginBox();
        return Promise.reject();
        }
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);

            let finalVoteValue = data0.data;
            MyAppPool.session.user.votes[''+resourceType][''+resourceId] = finalVoteValue;

            return Promise.resolve();
        },
        routes.sendNeoComuResourceLike,false,
        {resourceType,resourceId,voteValue:value});
    }

    fetchUserVotes(useCache=true)
    {
        return multipleFetchAndProcess ((datas,ready)=>{
            let datardata = datas['datarvotes'];
            let comudata = datas['comuvotes'];
            if (!comudata.ok && !datardata.ok)
                return Promise.reject(comudata.msg + ' ' + datardata.msg);
            let data = [...datardata.data,...comudata.data];
            if (data.length>0)
            {
                let result = getDefaultStarter();
                for (let i=0; i < data.length; i++)
                {
                    let item = data[i];
                    result[''+item.resourcetype][item.resourceid]=item.votevalue;
                }
                //web client should be more updated, therefore we merge server cached fetch with current 
                //web client user votes.
                let oldVotes = MyAppPool.session.user.votes;
                let newRes = merge(result,oldVotes);
                MyAppPool.session.user.votes = newRes;
                return Promise.resolve('ok');
            }
            return Promise.resolve('no items');
        },
        [
            {name:'comuvotes',route:routes.getUserVotes,useCache,
            params:{uid:MyAppPool.currentUser.userid},headers:{}},
            {name:'datarvotes',route:routes.getWorkResourceUserVotes,useCache,
            params:{uid:MyAppPool.currentUser.userid},headers:{}},
        ])
        .catch(e => console.error("Error Getting User Votes",e));
    }
}