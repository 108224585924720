
import { computed, toJS } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { getDefaultSession, getDefaultUser } from '../store/defaultSessionVals';
import { MD5 } from '../util/secUtil';
import { isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { fetchAndProcess } from '../util/net';
import { contentTypeToFastType, getIfIsSetElems, isSet, mobToObj, setIfNotSetObj, setIfNotSetObjElems } from '../util/typeu';
import { getCurrentSeason, getCurrentYear, getLastXYears } from '../util/timeUtil';
import { convertAnimeStatusNumberToName, convertMangaStatusNumberToName, convertSeasonNumberToName } from '../util/text';

function getMergeAll(tops,currentPage){
    let entries = Object.entries(tops);
    let res = [];
    for (let i=0;i<entries.length;i++)
    {
        if (Number(entries[i][0])<=currentPage)
            if (Array.isArray(entries[i][1].itens))
                res.push(...entries[i][1].itens);
    }
    res = res.sort((a,b)=>Number(a.position)-Number(b.position));
    return res;
}

export default class TopsController
{

    getTopAiringAnimePageByType(type='TV',page=0,pageSize=10)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            
            setIfNotSetObjElems(MyAppPool.session.tops.anime.airing,
                [typeSubKey,'pages',''+pageSize]);
            MyAppPool.session.tops.anime.airing[typeSubKey].pages[''+pageSize][''+page] = resData;

            return Promise.resolve();
        },
        routes.getTopAnimeAiringPage,true,
        {type,page,pageSize})
        .catch(e => console.error(("Error Getting Top Anime by Grade"),e));
    }

    getTopUpcomingAnimePageByType(type='TV',page=0,pageSize=10)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';

            setIfNotSetObjElems(MyAppPool.session.tops.anime.upcoming,
            [typeSubKey,'pages',''+pageSize]);

            MyAppPool.session.tops.anime.upcoming[typeSubKey].pages[''+pageSize][''+page] = resData;

            return Promise.resolve();
        },
        routes.getTopAnimeUpcomingPage,true,
        {type,page,pageSize})
        .catch(e => console.error(("Error Getting Top Anime Upcoming"),e));
    }

    getTopPopularAnimePageByPeriodAndType(period='',type='TV',page=0,pageSize=10)
    {
        let periodApiArg = period;
        if (periodApiArg==='all')
            periodApiArg='';
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let periodSubObjectKey=period;
            if (periodSubObjectKey==='')
                periodSubObjectKey='all';
            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';

            setIfNotSetObjElems(MyAppPool.session.tops.anime.popular,
            [periodSubObjectKey,typeSubKey,'pages',''+pageSize]);
            MyAppPool.session.tops.anime.popular[periodSubObjectKey][typeSubKey].pages[''+pageSize][''+page]= resData;

            return Promise.resolve();
        },
        routes.getTopAnimePopularPage,true,
        {periodo:periodApiArg,type,page,pageSize})
        .catch(e => console.error(("Error Getting Top Anime Popular"),e));
    }
    getTopAnimeFilters()
    {
        let years = getLastXYears(25);
        let typesTexts={},airingTexts={},seasonsTexts={};
        let types = [
        {id:'TV', name:lng('TV')},
        {id:'OVA', name:lng('OVA')},
        {id:'Special', name:lng('Special')},
        {id:'Movie', name:lng('Movie')},
        {id:'ONA', name:lng('ONA')}];

        let criteria = [{criteria:'Grade',text:lng('Nota')}
                       ,{criteria:'Popularity',text:lng('Popularidade')}];
        let periodo = [{criteria:'all',text:lng('Todos')}
                       ,{criteria:'airing',text:lng('Em exibição')}];
        typesTexts['TV']=lng('TV');
        typesTexts['OVA']=lng('OVA');
        typesTexts['Special']=lng('Special');
        typesTexts['Movie']=lng('Movie');
        typesTexts['ONA']=lng('ONA');

        let yearsArr = [];
        let seasons = [];
        let currentYear = getCurrentYear();
        let currentSeason = getCurrentSeason();
        for(let i=0;i<years.length;i++)
        {
            yearsArr.push(years[i]);
            for(let k=3;k>=0;k--)
            {
                if (years[i]===currentYear && k>currentSeason)
                    continue;
                seasons.push({id:years[i]+"-"+k,name:years[i]+":"+convertSeasonNumberToName(k)});
            }
        }
        
        let airing = [];
        for(let i=0;i<3;i++)
        {
            airing[i] ={id:i,name:convertAnimeStatusNumberToName(i)};
            airingTexts[''+i]=airing[i].name;
        }
        let filterTexts = {typesTexts,airingTexts,seasonsTexts};
        let filters = {periodo,criteria,types,seasons,airing,years:yearsArr,filterTexts};

        let contentType = contentTypeToFastType('anime');
        MyAppPool.session.tops.anime.filters[contentType]=filters;

        return filters;
    }
    getTopMangaFilters()
    {
        let years = getLastXYears(25);
        let typesTexts={},airingTexts={},seasonsTexts={};
        let types = [
        {id:'Manga', name:lng('Manga')},
        {id:'Light Novel', name:lng('Light Novel')},
        {id:'Novel', name:lng('Novel')},
        {id:'One-shot', name:lng('One-shot')},
        {id:'Manhwa', name:'Manhwa'},
        {id:'Manhua', name:'Manhwa'},
        {id:'Doujinshi', name:'Doujinshi'}];

        let criteria = [{criteria:'Grade',text:lng('Nota')}
                       ,{criteria:'Popularity',text:lng('Popularidade')}];
        let periodo = [{criteria:'all',text:lng('Todos')}
                       ,{criteria:'airing',text:lng('Em exibição')}];
        typesTexts['TV']=lng('TV');
        typesTexts['OVA']=lng('OVA');
        typesTexts['Special']=lng('Special');
        typesTexts['Movie']=lng('Movie');
        typesTexts['ONA']=lng('ONA');

        let yearsArr = [];
        let seasons = [];
        let currentYear = getCurrentYear();
        let currentSeason = getCurrentSeason();
        for(let i=0;i<years.length;i++)
        {
            yearsArr.push(years[i]);
            for(let k=3;k>=0;k--)
            {
                if (years[i]===currentYear && k>currentSeason)
                    continue;
                seasons.push({id:years[i]+"-"+k,name:years[i]+":"+convertSeasonNumberToName(k)});
            }
        }
        
        let airing = [];
        for(let i=0;i<3;i++)
        {
            airing[i] ={id:i,name:convertMangaStatusNumberToName(i)};
            airingTexts[''+i]=airing[i].name;
        }
        let filterTexts = {typesTexts,airingTexts,seasonsTexts};
        let filters = {periodo,criteria,types,seasons,airing,years:yearsArr,filterTexts};

        let contentType = contentTypeToFastType('manga');
        MyAppPool.session.tops.manga.filters[contentType]=filters;

        return filters;
    }
    getTopAnimePopularFilters()
    {
        let typesTexts={};
        let types = [
        {id:'TV', name:lng('TV')},
        {id:'OVA', name:lng('OVA')},
        {id:'Special', name:lng('Special')},
        {id:'Movie', name:lng('Movie')},
        {id:'ONA', name:lng('ONA')}];

        let criteria = [{criteria:'Grade',text:lng('Nota')}
                       ,{criteria:'Popularity',text:lng('Popularidade')}];
        let periodo = [{criteria:'all',text:lng('Todos')}
                       ,{criteria:'month',text:lng('Mês')},
                       {criteria:'week',text:lng('Semana')},
                       {criteria:'today',text:lng('Hoje')},
                       {criteria:'upcoming',text:lng('Esperado')}];

        let filterTexts = {typesTexts};
        let filters = {periodo,criteria,types,filterTexts};

        return filters;
    }


    getTopAnimeGradePageByType(type='TV',page=0,pageSize=25)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;

            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            setIfNotSetObjElems(MyAppPool.session.tops.anime.grade,
            [typeSubKey,'pages',''+pageSize]);
            MyAppPool.session.tops.anime.grade[typeSubKey].pages[''+pageSize][''+page] = resData;

            return Promise.resolve();
        },
        routes.getTopAnimeGradePage,true,
        {type,page,pageSize})
        .catch(e => console.error(("Error Getting Top Anime by Grade"),e));
    }
    getTopAnimeGradeSeasonPageByType(type='TV',year=getCurrentYear(),season=getCurrentSeason(),page=0,pageSize=25)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;

            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            let seasonKey = (''+year)+'_'+season;

            setIfNotSetObjElems(MyAppPool.session.tops.anime.gradeSeason,
            [typeSubKey,seasonKey,'pages',''+pageSize]);
            MyAppPool.session.tops.anime.gradeSeason[typeSubKey][seasonKey].pages[''+pageSize][''+page] = resData;

            return Promise.resolve();
        },
        routes.getTopAnimeGradeSeasonPage,true,
        {type,year,season,page,pageSize})
        .catch(e => console.error(("Error Getting Top Anime by Grade"),e));
    }
    getTopAnimeGradeYearPageByType(type='TV',year=getCurrentYear(),page=0,pageSize=25)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;

            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            
            setIfNotSetObjElems(MyAppPool.session.tops.anime.gradeYear,
            [typeSubKey,year,'pages',''+pageSize]);
            MyAppPool.session.tops.anime.gradeYear[typeSubKey][year].pages[''+pageSize][''+page] = resData;
            
            return Promise.resolve();
        },
        routes.getTopAnimeGradeYearPage,true,
        {type,year,page,pageSize})
        .catch(e => console.error(("Error Getting Top Anime by Grade"),e));
    }
    getTopMangaGradeYearPageByType(type='manga',year=getCurrentYear(),page=0,pageSize=25)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;

            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            
            setIfNotSetObjElems(MyAppPool.session.tops.manga.gradeYear,
            [typeSubKey,year,'pages',''+pageSize]);
            MyAppPool.session.tops.manga.gradeYear[typeSubKey][year].pages[''+pageSize][''+page] = resData;
            
            return Promise.resolve();
        },
        routes.getTopMangaGradeYearPage,true,
        {type,year,page,pageSize})
        .catch(e => console.error(("Error Getting Top Manga by Grade"),e));
    }
    getTopMangaGradeSeasonPageByType(type='Manga',year=getCurrentYear(),season=getCurrentSeason(),page=0,pageSize=25)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;

            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            let seasonKey = (''+year)+'_'+season;

            setIfNotSetObjElems(MyAppPool.session.tops.manga.gradeSeason,
            [typeSubKey,seasonKey,'pages',''+pageSize]);
            MyAppPool.session.tops.manga.gradeSeason[typeSubKey][seasonKey].pages[''+pageSize][''+page] = resData;

            return Promise.resolve();
        },
        routes.getTopMangaGradeSeasonPage,true,
        {type,year,season,page,pageSize})
        .catch(e => console.error(("Error Getting Top Manga by Grade"),e));
    }
    getTopAiringMangaPageByType(type='Manga',page=0,pageSize=10)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            
            setIfNotSetObjElems(MyAppPool.session.tops.manga.airing,
                [typeSubKey,'pages',''+pageSize]);
            MyAppPool.session.tops.manga.airing[typeSubKey].pages[''+pageSize][''+page] = resData;

            return Promise.resolve();
        },
        routes.getTopMangaAiringPage,true,
        {type,page,pageSize})
        .catch(e => console.error(("Error Getting Top Manga by Grade"),e));
    }
    getTopMangaGradePageByType(type='TV',page=0,pageSize=5)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;

            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            setIfNotSetObjElems(MyAppPool.session.tops.manga.grade,
            [typeSubKey,'pages',''+pageSize]);
            MyAppPool.session.tops.manga.grade[typeSubKey].pages[''+pageSize][''+page] = resData;
  
            return Promise.resolve();
        },
        routes.getTopMangaGradePage,true,
        {type,page,pageSize})
        .catch(e => console.error(("Error Getting Top Manga by Grade"),e));
    }

    getTopPopularMangaPageByPeriodAndType(period='',type='TV',page=0,pageSize=10)
    {
        let periodApiArg = period;
        if (periodApiArg==='all')
            periodApiArg='';
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let periodSubObjectKey=period;
            if (periodSubObjectKey==='')
                periodSubObjectKey='allTime';
            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';
            
            setIfNotSetObjElems(MyAppPool.session.tops.manga.popular,
            [periodSubObjectKey,typeSubKey,'pages',''+pageSize]);
            MyAppPool.session.tops.manga.popular[periodSubObjectKey][typeSubKey].pages[''+pageSize][''+page]= resData;

            return Promise.resolve();
        },
        routes.getTopMangaPopularPage,true,
        {periodo:periodApiArg,type,page,pageSize})
        .catch(e => console.error(("Error Getting Top Manga Popular"),e));
    }
    getTopUpcomingMangaPageByType(type='manga',page=0,pageSize=10)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let typeSubKey = type;
            if (typeSubKey==='')
                typeSubKey='all';

            setIfNotSetObjElems(MyAppPool.session.tops.manga.upcoming,
            [typeSubKey,'pages',''+pageSize]);

            MyAppPool.session.tops.manga.upcoming[typeSubKey].pages[''+pageSize][''+page] = resData;

            return Promise.resolve();
        },
        routes.getTopMangaUpcomingPage,true,
        {type,page,pageSize})
        .catch(e => console.error(("Error Getting Top Manga Upcoming"),e));
    }

     getTopGradeAllPage(tops,type,subtype,pageSize,currentPage){
        let elem = getIfIsSetElems(tops,[type,'grade',subtype,'pages',''+pageSize]);
        let maxPage = 0;
        if (isSet(elem))
        {
            return {itens:getMergeAll(elem,currentPage),maxPages:getIfIsSetElems(elem,[currentPage,'pages']) | 0};
     }
        return null;
    };
     getAnimeTopAiringAllPage(tops,type,subtype,pageSize,currentPage){
        let elem = getIfIsSetElems(tops,[type,'airing',subtype,'pages',''+pageSize]);
        if (isSet(elem))
            return {itens:getMergeAll(elem,currentPage),maxPages:getIfIsSetElems(elem,[currentPage,'pages']) | 0};
        return null;
    };
     getTopSeasonAllPage(tops,type,subtype,year=getCurrentYear(),season=getCurrentSeason(),pageSize,currentPage){
        let seasonKey = (''+year)+'_'+season;
        let elem = getIfIsSetElems(tops,[type,'gradeSeason',subtype,seasonKey,'pages',''+pageSize]);
        if (isSet(elem))
            return {itens:getMergeAll(elem,currentPage),maxPages:getIfIsSetElems(elem,[currentPage,'pages']) | 0};
        return null;
    };
     getTopYearAllPage(tops,type,subtype,year=getCurrentYear(),pageSize,currentPage){
        let elem = getIfIsSetElems(tops,[type,'gradeYear',subtype,year,'pages',''+pageSize]);
        if (isSet(elem))
            return {itens:getMergeAll(elem,currentPage),maxPages:getIfIsSetElems(elem,[currentPage,'pages']) | 0};
        return null;
    };
    getTopPopularAllPage(tops,type,subtype,period,pageSize,currentPage){
        let elem = getIfIsSetElems(tops,[type,'popular',period,subtype,'pages',''+pageSize]);
        if (isSet(elem))
            return {itens:getMergeAll(elem,currentPage),maxPages:getIfIsSetElems(elem,[currentPage,'pages']) | 0};
        return null;
    };
    
    getTopPopularUpcomingAllPage(tops,type,subtype,pageSize,currentPage){
        let elem = getIfIsSetElems(tops,[type,'upcoming',subtype,'pages',''+pageSize]);
        if (isSet(elem))
            return {itens:getMergeAll(elem,currentPage),maxPages:getIfIsSetElems(elem,[currentPage,'pages']) | 0};
        return null;
    };

    getTopGradePage(tops,type,subtype,page,pageSize){
        return getIfIsSetElems(tops,[type,'grade',subtype,'pages',''+pageSize,''+page]);
    };
    getTopUpcomingPage(tops,type,subtype,page,pageSize){
        return getIfIsSetElems(tops,[type,'upcoming',subtype,'pages',''+pageSize,''+page]);
    };
    
    getTopAiringPage(tops,type,subtype,page,pageSize){
        return getIfIsSetElems(tops,[type,'airing',subtype,'pages',''+pageSize,''+page]);
    };
    
    
    getTopPopularPage(tops,type,subtype,period,page,pageSize){
        return getIfIsSetElems(tops,[type,'popular',period,subtype,'pages',''+pageSize,''+page]);
    };
}





