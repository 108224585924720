
import { computed } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { getDefaultSession, getDefaultUser } from '../store/defaultSessionVals';
import { MD5 } from '../util/secUtil';
import { isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { fetchAndProcess } from '../util/net';
import { animeFastResourceType, isSet, mobToObj } from '../util/typeu';
import { getSEOUriPath } from '../util/text';
import { getObraRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';

export default class CurrentSeasonController
{

    getCurrentSeasonAnime()
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let showableItens = [];
            for(let i=0; i< resData.length;i++)
            {
                let element = resData[i];
                //console.log("Received anime::",mobToObj(element));
                showableItens[i] = {
                    url:  getObraRoute(element.id,getSEOUriPath (element.title),'anime','#top'),
                    //directUrl:getObraRoute(element.id,getSEOUriPath(element.title),'anime','#top'),
                    imgurl:element.mainimg, 
                    title:element.title,
                    icon: getRelatedTypeIcon(element.type),
                    iconText: lng (element.type),
                    resourceType:animeFastResourceType,
                    resourceId:element.id
                }
            }
            MyAppPool.session.currentSeason.anime=showableItens;

            return Promise.resolve();
        },
        routes.getCurrentSeasonAnime,true,
        {lng:getCurrentLanguage(),tvOnly:1})
        .catch(e => console.error(("Error Getting Recent Trailers"),e));
    }
    getCurrentSeasonAnimeOther()
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let showableItens = [];
            for(let i=0; i< resData.length;i++)
            {
                let element = resData[i];
                showableItens[i] = {
                    url:  getObraRoute(element.id,getSEOUriPath(element.title),'anime','#top'),
                    //directUrl:getObraRoute(element.id,getSEOUriPath(element.title),'anime','#top'),
                    imgurl:element.mainimg, 
                    title:element.title,
                    icon: getRelatedTypeIcon(element.type),
                    iconText: lng (element.type),
                    resourceType:animeFastResourceType,
                    resourceId:element.id
                }
            }
            MyAppPool.session.currentSeason.otherAnimation=showableItens;

            return Promise.resolve();
        },
        routes.getCurrentSeasonAnime,true,
        {lng:getCurrentLanguage(),tvOnly:0})
        .catch(e => console.error(("Error Getting Recent Trailers"),e));
    }
}