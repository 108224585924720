import React, { Component } from 'react';
import YoutubeVideo from './YoutubeVideo';

import { getCurrentUrl, getCurrentUrlUrlEncoded } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import HtmlToReact from 'html-to-react';
const HtmlToReactParser = HtmlToReact.Parser;

const isValidNode = function () {
  return true;
};

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions();

// Regular expression to extract the YouTube video ID from the iframe src URL
const youtubeIdRegex = /youtube\.com\/embed\/([\w-]+)/;
const twitterIdRegex = /twitter\.com\/[^\/]+\/status\/(\d+)(?:\?|$)/;
// Processing instructions
const processingInstructions = [
  {
    // Replace <iframe> elements with YouTube sources
    shouldProcessNode: function (node) {
      return (
        node.name === 'iframe' &&
        node.attribs &&
        node.attribs.src &&
        youtubeIdRegex.test(node.attribs.src)
      );
    },
    processNode: function (node, children, index) {
      const match = youtubeIdRegex.exec(node.attribs.src);
      const youtubeId = match ? match[1] : null;
      //console.log("ID:",youtubeId);
      return youtubeId
        ? <YoutubeVideo key={index} video={{ytid:youtubeId,title:""}} ready={true}/>
        : null;
    },
  },
    {
      // Replace Twitter embeds with Twitter widgets
      shouldProcessNode: function (node) {
        return (
          node.name === 'iframe' &&
          node.attribs &&
          node.attribs.src &&
          node.attribs.src.indexOf('twitter')!==-1
        );
      },
      processNode: (node, children, index) => {
        let urlObj = new URL(node.attribs.src);
        let theme = "light";
        if (MyAppPool.session.theme=="darkTheme")
          theme="dark";
        // Modify the `ref_url` parameter while keeping the others
        //urlObj.searchParams.set("ref_url", getCurrentUrlUrlEncoded());
        urlObj.searchParams.set("origin", getCurrentUrlUrlEncoded());
        urlObj.searchParams.set("theme", theme);
        // Get the updated URL
        node.attribs.src = urlObj.toString();
        return <div className="threadTwitterWrapper "><iframe className="threadTwitter appScroll" src={node.attribs.src}></iframe></div> ;
      }
    },
  {
    // Default processing for any other nodes
    shouldProcessNode: function (node) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

const htmlToReactParser = new HtmlToReactParser();


export class ThreadContent extends Component {
  render() {
    let {content} = this.props;
const reactComponent = htmlToReactParser.parseWithInstructions(
    content,
  isValidNode,
  processingInstructions
);

return reactComponent?reactComponent:null;
  }
}