import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import MyAppPool from '../AppPool';
import { getObraRoute, getPersonMangaRoute, getPersonPersonagensRoute, getPersonRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { convertDataToShowable, getImgAddress, getRouteParamAtIndex, getSiteShortName } from '../util/text';
import { contentTypeToFastType, isSet, personFastResourceType } from '../util/typeu';
import BannerReaction from '../view_component/BannerReaction';
import BreadCumbs from '../view_component/BreadCumbs';
import CapaSection from '../view_component/CapaSection';
import Erro404 from '../view_component/Erro404';
import SkeletonBlock from '../view_component/SkeletonBlock';
import SubNavigation from '../view_component/SubNavigation';
import TextContentBox from '../view_component/TextContentBox';
import { getPersonAnimeRoute } from './../store/AppRoutes';
import { isMobile } from './../util/mobile';
import { getCurrentUrl } from './../util/windowUtil';
import CSSTransition2 from './../view_component/CSSTransition2';

import PersonGeralView from './personsub/PersonGeralView';
import PersonObraView from './personsub/PersonObraView';
import PersonPersonagensView from './personsub/PersonPersonagemView';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import { serverRegisterFetch } from '../util/updateManager';
import ResourceMediaView from "./resourceMedia";
import Capa from './../view_component/Capa';
import CycleBanner from '../view_component/CycleBanner';

@observer
class Person extends Component {
  constructor(props){
    super(props);
    serverRegisterFetch(Person.getInitialValues);
  }
  componentDidMount()
  {
    Person.getInitialValues();
  }
  static async getInitialValues()
  {
    let personid = getRouteParamAtIndex(getCurrentUrl(),2);
    if (isSet(personid))
    {
      let p1 = MyAppPool.cPerson.fetchPersonById(personid);
      let p3 = MyAppPool.cReactionPosts.fetchReactionPosts(contentTypeToFastType('person'),personid);
      let p4 = MyAppPool.cResourceMedia.getResourceMedia(personFastResourceType,personid);
      await Promise.all([p1,p3,p4]);
    }
    return Promise.resolve();
  }
  componentWillReceiveProps()
  {
    Person.getInitialValues();
  }

  render(){
    let personid = getRouteParamAtIndex(getCurrentUrl(),2);
    if (isNaN(Number(personid)))
      return <Erro404 />;
    if (MyAppPool.session.personFetchEmpty[''+personid]===true)
      return <Erro404 />;
    
    let current = MyAppPool.cPerson.currentPerson;
    let tabBaseUrl = current.baseUrl;
    let tabs = [{name:lng('Geral'),url:tabBaseUrl},{name:'Media',url:tabBaseUrl+'/'+'media'}]
    let workRoles = [];
    if (current.ready)
    {
    if (current.acted.length>0)
      tabs[tabs.length] = {name:lng('Personagens'),url:getPersonPersonagensRoute(current.id,current.fullName,'')}
    if (current.staffed.length>0)
      tabs[tabs.length] = {name:lng('Anime'),url:getPersonAnimeRoute(current.id,current.fullName,'')};
    if (current.created.length>0)
      tabs[tabs.length] = {name:lng('Manga'),url:getPersonMangaRoute (current.id,current.fullName,'')};
      
      if (isSet(current.workAs))
        workRoles=current.workAs;
    }

    let lists = [];
    let listPresence = [];
    let reactionPosts = [];
    let reactionReady = 0;
    let resourceType = contentTypeToFastType('person');
    if (current.ready)
    {
      reactionPosts = MyAppPool.session.reactionPost[resourceType][current.id];
      if (typeof reactionPosts ==='undefined')
        {
          reactionPosts=[];
          reactionReady = 0;
        }else
          reactionReady = 1;
        lists = MyAppPool.session.user.lists[resourceType];
        listPresence = MyAppPool.cLists.getListPresence(resourceType,current.id);
        if (!Array.isArray(lists))
          lists = [];
    }
    
    
    let meta = {title:lng('Pessoa')+' | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    let bannerImages = [];
    if (current.ready)
    {
      let desc = current.description;
      meta =  {
        title:current.fullName +' - ' + lng('Pessoa') + ' | '+getSiteShortName()+'',
        desc:desc,
        keywords:current.fullName +  ', ' + current.otherTitles +', ' +current.remainingTitles,
        img:current.mainimg,
        imgwidth:'225',
        imgheight:'338',
        imgtype:'jpg'
      };
      for(let i=0;i<current.staffed.length;i++)
      {
          let anime = current.staffed[i];
          if (isSet(anime.bannerimg) && anime.bannerimg.length>0)
          {
            bannerImages.push({image:getImgAddress(anime.bannerimg), url:getObraRoute(anime.id,anime.title,'anime','#top')});
          }
      }
    }
    let baseImage = "random:sky";
    if (bannerImages.length==0)
      baseImage = "none";
    if (bannerImages.length>0)
      baseImage = "component:"+bannerImages.length;

    if (current.empty)
      return <Erro404/>;
  
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
        <div className="contentpage">
        <ContentBannerSetter images={[baseImage]} modes = {["component"]} component=
        {<CycleBanner images={bannerImages}/>}   />
          <div>
          {!isMobile.any?
          <div style={{position:'relative'}}>
            <div className="reactions">
              <BannerReaction
                items ={reactionPosts}
                ready={reactionReady}
              />
            </div>
          </div>
          : <></>}
          </div>
          <div className='uk-flex uk-flex-row topsection'>
            {!isMobile.any?
            <div className="capa_left_column">
            <Capa ready={current.ready} img={getImgAddress(current.mainimg,'/imgs/defaults/person.webp')}/>
            <CapaSection ready={current.ready} img={getImgAddress(current.mainimg,'/imgs/defaults/person.webp')}
            resourceType={contentTypeToFastType('person')}
            resourceId={current.id}
            lists={lists}
            listsPresence ={listPresence}
            /> </div>
            :<></>
            }
            <div className="contentInfo">
            {isMobile.any?
            <div style={{"width":"100%"}}>
            <Capa ready={current.ready} img={getImgAddress(current.mainimg,'/imgs/defaults/person.webp')}/>
            <CapaSection ready={current.ready} img={getImgAddress(current.mainimg,'/imgs/defaults/person.webp')} 
            resourceType={contentTypeToFastType('person')}
            resourceId={current.id}
            lists={lists}
            listsPresence ={listPresence} /> </div> 
            :<></>
            }
              <BreadCumbs/>
              {current.ready ?
               <>
                <h1 className="smallerheading"> <span id='title'>{current.fullName} &nbsp;</span>
                </h1>
                <h2 className="smallerheading2"> {current.otherTitles}</h2>
                {current.description===''? 
                workRoles.length===0?<span>{lng('Sem informações a mostrar')}</span>:<></>
                :
                <TextContentBox content={current.description}/>}
                <br/>
                {[
                workRoles.length>0?<span>{lng("Trabalha com:")} </span>:<></>,
                workRoles.map((elem)=><span>{elem}, </span>) 
                ]}
                </>
                :<>
                <SkeletonBlock style={{'width':'250px',height:'50px', 'marginTop':'8px'}}/>
                <SkeletonBlock style={{'width':'300px',height:'25px', 'marginTop':'8px'}}/>
                <SkeletonBlock style={{'width':'95%',height:'150px', 'marginTop':'15px'}}/>
                <SkeletonBlock style={{'width':'250px',height:'30px', 'marginTop':'15px'}}/>
                <SkeletonBlock style={{'width':'350px',height:'30px', 'marginTop':'15px'}}/>
                </>
                }
                </div>
          </div>
          
          <div className="naveg" data-uk-sticky="offset: 0">
            <div className = "uk-flex uk-flex-row"> 
            <SubNavigation items={tabs}/> 
            {!isMobile.any?
            <button aria-label={lng('MAB por MaxBrasi')} className="link-button tinylink">{lng('MAB por MaxBrasi')}</button>
            :<></>}
            </div>
            {isMobile.any?
            <button aria-label={lng('MAB por MaxBrasi')} className="link-button tinylink">{lng('MAB por MaxBrasi')}</button>
            :<></>}
          </div>

          <div className="authortag">
                    </div>
          {//!isMobile.any?
          //:<></>
        }
          
          
          <Route render={({ location }) => {
            const { pathname, key } = location;
            return (
              <div style={{position:'relative'}}>
                <CSSTransition2
                  in={true}
                  key={key}
                  classNames="switch-node"
                  timeout={{enter: 750, exit: 0}}
                >
                  <div>
                  <Switch>
                    <Route path={'/'+lng('pessoa')+'/:aid/:aname'} exact={true}>
                      <PersonGeralView current={current}/>
                    </Route>
                    <Route path={getPersonPersonagensRoute(':aid',':aname','')} exact={true}>
                      <PersonPersonagensView current={current}/>
                    </Route>
                    <Route path={getPersonAnimeRoute(':aid',':aname','')} exact={true}>
                      <PersonObraView current={current} items={current.animeRow} positions={current.personAnimePositions} pageType="Anime"/>
                    </Route>
                    <Route path={getPersonRoute(':aid',':aname','')+'/media'} exact={true}>
                      <ResourceMediaView resourceType={personFastResourceType} resourceId={current.id}/> 
                    </Route>
                    <Route path={getPersonMangaRoute (':aid',':aname','')} exact={true}>
                      <PersonObraView current={current} items={current.mangaRow} positions={current.personMangaPositions} pageType="Manga"/>
                    </Route>
                    <Route path="*" render={() => <Erro404 />} />
                  </Switch>
                  </div>
                </CSSTransition2>
              </div>
          )
        }}/>

        </div>
        </div>
      );

}
}

export default Person;
