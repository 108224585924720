import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getResourceRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { getRelatedTypeIcon, getUserAvatar } from '../util/icon';
import { getImgAddress, onlyLettersAndNumbers } from '../util/text';
import { animeFastResourceType, companyFastResourceType, isSet, magazineFastResourceType, mangaFastResourceType, userFastResourceType } from '../util/typeu';
import { getYoutubeThumbnail } from '../util/youtube';
import MyAppPool from './../AppPool';
import { isMobile } from './../util/mobile';
import { musicFastResourceType } from './../util/typeu';
import "./LFavoriteBox.css";
import MusicPlaylistCreator from './MusicPlaylistCreator';
import RelatedWorks from './RelatedWorks';
import SearchCard from './SearchCard';

@observer
class LFavoriteBox extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            open:true,
            useHeight:0,
            display:'block', 
            active:'listbox_active',
            delayedOpen:true,
            sendingAction:false,
            editingName:false,
            editName:'',
            mode:'list'
        };
        this.addAllToQueue = this.addAllToQueue.bind(this);
    }

    addAllToQueue(type=-1)
    {
        let {list} = this.props;
        if (list.resourcetype===musicFastResourceType)
        {
            let animeMusics = [];
            list.itemIds.map((item,index)=>{
                let itemData = MyAppPool.session.samples[list.resourcetype][''+item];
                animeMusics.push(itemData);
            });

            let items = animeMusics;
            for(let i=0; i < items.length; i++)
            {
                let music = items[i];
                if (type!==-1 && items[i].type!==type)
                continue;
                MyAppPool.cMusic.addToPlayQueue(music);
            }
            MyAppPool.cPopMenu.deactivate();
        }
    }
  componentDidMount()
  {
    //console.log(JSON.stringify(window.history.state));
    let {height,startOpen} = this.props;
    if (!startOpen)
    {
        let newUseHeight=0;
        let display = 'none';
        let active = '';
        return this.setState({open:false,useHeight:newUseHeight,active,display,delayedOpen:false});
    }
    this.setState({useHeight:height});
  }
  toggle()
  {
    let {open} = this.state;
    let {height} = this.props;
    let newUseHeight=0;
    let display = 'none';
    let active = '';
    open = !open;
    if (open)
    {
        newUseHeight=height;
        display='block';
        active='listbox_active';
        this.setState({delayedOpen:open});
    }else
    {
        setTimeout(() => {
            this.setState({delayedOpen:open});
        }, 400);
    }
    this.setState({open:open,useHeight:newUseHeight,active,display});
  }

  render()
  {
    let {list,listsOwner,mode} = this.props;
    
    let isMusicType = list.resourcetype===musicFastResourceType;
    let musicItens = [];
    if (isMusicType)
    {
        list.itemIds.map((item,index)=>{
            let itemData = MyAppPool.session.samples[list.resourcetype][''+item];
            musicItens.push(itemData);
        });
    }
    let containerClass = "flexDrop listbox_panel";
    if (mode==='list')
    {
       containerClass = "listbox_panel listMode";
    }
    let groupsPageSize=4;
    let sliderHeight=352;
    let sliderShowItens=5;
    let sliderImgSize='';
    if (isMobile.any)
    {
      groupsPageSize=3;
      sliderHeight=225;
      sliderShowItens=4;
      sliderImgSize='x50';
    }

    let relatedList = [];
    list.itemIds.map((item,index)=>{
        let itemData = MyAppPool.session.samples[list.resourcetype][''+item];
        let isHentai=false;
        let gradeable=false;
        let grade = undefined;
        if (list.resourcetype===animeFastResourceType || list.resourcetype===mangaFastResourceType
            || list.resourcetype===companyFastResourceType || list.resourcetype===magazineFastResourceType)
        {
            gradeable=true;
            let userOb = MyAppPool.session.userPage.profiles[listsOwner];
            if (isSet(userOb))
            {
                if (isSet(userOb.evals[list.resourcetype]))
                {
                    if (isSet(userOb.evals[list.resourcetype][item]))
                    {
                        //console.log("USER GRADE LISTBOX:::",userOb.evals[list.resourcetype][item]);
                        grade={actualVal:userOb.evals[list.resourcetype][item],minVal:6,maxVal:10};
                    }
                }
            }
            //listsOwner
        }
        if (isSet(itemData))
        {
            let name = itemData.title;
            let playItem = undefined;
            let alwaysShowName = (list.resourcetype===musicFastResourceType);
            let url = getResourceRoute(list.resourcetype,itemData.id,itemData.title,"#top");
            let useImg = getImgAddress(itemData.mainimg);
            if (list.resourcetype===userFastResourceType)
                useImg = getUserAvatar(itemData.mainimg,itemData.mailhash);
            
            if (list.resourcetype===musicFastResourceType)
            {
                alwaysShowName = true;
                url = '';
                playItem = itemData;
                name = itemData.title+' ' + lng("Por") +' '+ itemData.band;
                useImg = getYoutubeThumbnail(playItem.ytid);
            }
            relatedList.push({
                url:  url,
                imgurl:useImg, 
                title:name,
                icon: getRelatedTypeIcon(itemData.type),
                iconText: lng(itemData.type),
                resourceType:list.resourcetype,
                resourceId:itemData.id,
                grade:grade,
                playItem:playItem,
                alwaysShowName:alwaysShowName,
                isHentai:itemData.ishentai,
                genres:[],
                date:itemData.airfrom,
                gradeable:gradeable,
                mode:mode
            });
        }
    })

    relatedList = relatedList.sort((a,b)=>{
        if (typeof a.grade === 'undefined')
            return 1;
        if (typeof b.grade === 'undefined')
            return -1;
        if (typeof a.grade.actualVal !== 'number')
            return -1;
        if (typeof b.grade.actualVal !== 'number')
            return 1;
        return b.grade.actualVal -a.grade.actualVal;
    });
    
    
    return (
        <div className="uk-card uk-card-default comp_favoritebox">
            <div id ={onlyLettersAndNumbers(list.name)} className={"listbox_bar"}>
             <span  className={"title "}>{list.name}</span>
             <span> ({relatedList.length})</span>
                {isMusicType?<div className="fas fa-ellipsis-v alignBtnRight iconButton clickable uk-align-right" 
                      onClick={(e)=>{
                        MyAppPool.cPopMenu.set(
                          <div>
                            <ul className="pop_ytmenulist">
                              <li className="pop_ytmenuitem" onClick={()=>this.addAllToQueue(-1)}>
                                <span className={"fas pop_ytmenuitemicon fa-music"} ></span><div className="ytmenuoptiontext">{lng("Adicionar tudo à fila de reprodução")}</div>
                              </li>
                              <li className="pop_ytmenuitem" onClick={()=>MyAppPool.cModal.set(
                                lng("Adicionar Lista de Músicas"),
                                <MusicPlaylistCreator items={musicItens}/>,
                                (ok)=>{},'',(cleanup)=>{})}
                              >
                                <span className={"fas pop_ytmenuitemicon fa-plus"} ></span><div className="ytmenuoptiontext">{lng("Criar Lista com essas músicas")}</div>
                              </li>
                          </ul>
                          </div>
                        ,'')}}
                      >
                </div>:<></>}
            </div>
            {mode!=='scroll'?
            <div className={containerClass}>
            {
                relatedList.map((item,index)=>{
                        return <div className="searchCardsArea">
                        <SearchCard 
                        {...item}
                        />
                        </div>
                    })
            }
            </div>
            :
            <RelatedWorks 
            title=''
            notFoundText={lng('Carregando')+'...'}
            notFoundButtonText={''}
            showItemNumber={sliderShowItens}
            hideIfEmpty={1}
            imgSize={sliderImgSize}
            ready={relatedList.length>0}
            height={sliderHeight}
            items={relatedList}/>
            }
        </div>);
  }
}

LFavoriteBox.propTypes = {
    height: PropTypes.number,
    listsOwner:PropTypes.number.isRequired,
    mode:PropTypes.string.isRequired,
    list: PropTypes.shape({
        name: PropTypes.string.isRequired,
        listid: PropTypes.string.isRequired,
        essentialid: PropTypes.string.isRequired,
        ordering: PropTypes.string.isRequired,
        resourcetype:PropTypes.number.isRequired,
        visibility:PropTypes.number.isRequired,
        itemIds:PropTypes.array.isRequired
    })
};
LFavoriteBox.defaultProps = {
    height:338,
    list:{
        name: '',
        listid: -1,
        essentialid: null,
        ordering: 0,
        resourcetype:0,
        visibility:0,
        itemIds:[]
    },
    listsOwner:0,
    mode:'card'
};

//id={item.listid} itens={item.itens} title={item.name} resourceType={item.resourcetype} essentialId={item.essentialid}
export default LFavoriteBox;
