
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { getIfIsSetElems, isSet } from '../util/typeu';
import ThreadPreview from "../view_component/ThreadPreview";
import InfiniteScrollBlock from "../view_component/InfiniteScrollBlock";
import { serverRegisterFetch } from "../util/updateManager";
import './ForumThreadsByTagBox.css';

let pageSizeGlob = 10;
@observer
class ForumThreadsByTagBox extends Component {

  constructor(props) {
    super(props);

    this.state = {currentPage:1,pageSize:pageSizeGlob, lastFetchSize:1};
    serverRegisterFetch(()=>{
      let tagName = this.props.tag;
      let p1 = MyAppPool.cNeoComu.getThreadsByTagName(tagName,1,pageSizeGlob);
      return p1;
    });
  }
  fetchFirstThreads = () =>{
    let tagName = this.props.tag;
    let p1 = MyAppPool.cNeoComu.getThreadsByTagName(tagName,1,pageSizeGlob);
    //console.log("TNAME:",tagName);
    return p1;
  };

  componentDidMount()
  {
    this.fetchFirstThreads();
  }

  componentWillReceiveProps()
  {
    this.fetchFirstThreads();
  }

  loadNextPage = ()=>{
    let tagName = this.props.tag;
    if (isSet(tagName))
    {
        let {lastFetchSize,currentPage,pageSize} = this.state;
        if (lastFetchSize>0)
        {
            return MyAppPool.cNeoComu.getThreadsByTagName(tagName,currentPage+1,pageSize).then(data=>{
                this.setState({lastFetchSize:data.length,currentPage:currentPage+1});
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    }
    return Promise.resolve();
  }

  render(){
    let tag = this.props.tag;
    if (tag==="lnkres_0_0")
    {
        return <></>
    }
    let tagThreadIDPages = getIfIsSetElems(MyAppPool.session.neocomu.tagThreadsByName,[""+tag,""+pageSizeGlob]);
    let allPages=[];
    let name='';
    if (isSet(tagThreadIDPages)) 
    {
        allPages = Object.values(tagThreadIDPages).flat();
        if (allPages.length>0)
        {
          let threadData = MyAppPool.session.neocomu.threads[allPages[0]];
          name= threadData.tags[0].properties.name;
        }
    }

      return (
        <div>
          <div>
            <div className="threadsSectionBox">
                <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                {allPages.map((elemId,index)=>{
                let elem = MyAppPool.session.neocomu.threads[elemId];
                return  <ThreadPreview key={index} thread={elem} /> 
                })}
                </InfiniteScrollBlock>
            </div>
            </div>
          </div>
      );
}

}

export default ForumThreadsByTagBox;
