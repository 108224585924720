import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { extractYoutubeId } from '../util/text';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import TrailerYoutubePlayBig from './TrailerYoutubePlayBig';
import loadable from "@loadable/component";
const YouTube = loadable(() => import(/* webpackChunkName: "react-youtube" */ 'react-youtube'));

class DataVideoUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purpose: 'trailer',
      link: '',
      subtype: 'youtube'
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    let {resourceType,resourceId} = this.props;
    let {purpose,link,subtype} = this.state;
    //console.log("RAW LINK:",this.state.link);
    let youtube = extractYoutubeId(this.state.link);
    if (youtube==='')
        return MyAppPool.cAlert.notifyError(lng("Link inválido"),'','data video upload form');
        
    await MyAppPool.cResourceMedia.saveResourceMediaVideo(resourceType,resourceId,purpose,youtube,subtype);
    MyAppPool.cModal.deactivate();
    //console.log('Form submitted:', this.state);
  };

  render() {

    const trailerOpts = {
      height:"200px",
      width: '100%',
      playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      enablejsapi:1,
      controls:1
      }
  };

    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Link:
          <input
            type="text"
            name="link"
            value={this.state.link}
            onChange={this.handleChange}
            required
          />
        </label>
        {this.state.link!=""?
        <YouTube
          videoId={extractYoutubeId(this.state.link)}
          id='trailerplayer'
          opts={trailerOpts}
        />:<></>}
        
        <br />
        <label>
          Purpose:
          <select
            name="purpose"
            value={this.state.purpose}
            onChange={this.handleChange}
            required
          >
            <option value="trailer">trailer</option>
          </select>
        </label>
        <br />

        

        <label>
          Subtype:
          <select
            name="subtype"
            value={this.state.subtype}
            onChange={this.handleChange}
            required
          >
            <option value="youtube">youtube</option>
          </select>
        </label>
        <br /><br />

        <button className="uk-button uk-button-primary" type="submit">Save</button>
      </form>
    );
  }
}

DataVideoUploadForm.propTypes = {
    resourceType: PropTypes.number.isRequired,
    resourceId: PropTypes.number.isRequired,
};
DataVideoUploadForm.defaultProps = {
    resourceType:0,
    resourceId:0
};

export default DataVideoUploadForm;