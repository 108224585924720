
import { merge } from 'lodash';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { fetchAndProcess } from '../util/net';
import { getDefaultStarter, isSet, mobToObj } from '../util/typeu';
import { toJS } from 'mobx';
import { lng } from '../ulanguages/language';

export default class ImageController
{
    uploadUserImage
    (purpose,imageBase64,provider)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            

           //data0.data.userGrade (number), data0.data.resourceGrades (array of objects)
           let linkUrl = data0.data;
           let userImageArray = MyAppPool.session.user.images[purpose];
           if (!isSet(userImageArray))
            userImageArray = [];
           userImageArray.pushDistinct(linkUrl);
           MyAppPool.session.user.images[purpose]=userImageArray;
           MyAppPool.cAlert.notifyOk(lng("Imagem Gravada com Sucesso!"));
           return Promise.resolve(linkUrl);
        }
        ,
        routes.userUploadImage,false,
        {purpose,imageBase64,provider})
        .catch(e => {
            console.error(e);
            if (e.toString().indexOf('gif not allowed')!==-1)
                MyAppPool.cAlert.notifyUser(lng('_errgif'),'',400);
            else
                MyAppPool.cAlert.notifyGenericError(e,"image, upload user image");

            console.error(("Error Uploading image"),e); 
            return Promise.reject(e)});
    }

    deleteUserImage(link)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);

            let data = data0.data;

            let userImageArray = MyAppPool.session.user.images[data.purpose];
            if (!isSet(userImageArray))
                userImageArray = [];
            userImageArray=userImageArray.filter((item)=>item!==link);
            MyAppPool.session.user.images[data.purpose]=userImageArray;
            //console.log(data.purpose,toJS(MyAppPool.session.user.images[data.purpose]));
            MyAppPool.cAlert.notifyOk(lng("Imagem Deletada com Sucesso!"));
            return Promise.resolve(userImageArray);
        },
        routes.userDeleteImage,
        false,
        {link}
        )
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(e,"image, delete user image");
            console.error("Error deleting User Images:",e)
        });
    }

    fetchUserImages(purpose='avatar')
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);

            let data = data0.data;
            if (data.length>0)
            {
                let userImageArray = MyAppPool.session.user.images[purpose];
                if (!isSet(userImageArray))
                    userImageArray = [];
                MyAppPool.session.user.images[purpose]=data;
                return Promise.resolve(data);
            }
            return Promise.resolve([]);
        },
        routes.userGetImagesPrivate,
        false,
        {purpose}
        )
        .catch(e => console.error("Error Getting User Images:",purpose,e));
    }

}