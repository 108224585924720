
import { computed } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { getDefaultSession, getDefaultUser } from '../store/defaultSessionVals';
import { MD5 } from '../util/secUtil';
import { isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { fetchAndProcess } from '../util/net';
import { animeFastResourceType, isSet, mobToObj } from '../util/typeu';
import { getSEOUriPath } from '../util/text';
import { getObraRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';

export default class AffiliateController
{
    getMangaQuery(manga){
        let useType=manga.type;
        if (!isSet(useType) || useType==="null")
            useType="";
        if (!isSet(manga.engTitle))
            return '';
        let useTitle = manga.engTitle.split(" ").slice(0,3).join(" ");

        return useType+" "+useTitle;
    }
    getAnimeQuery(anime){
        if (!isSet(anime.engTitle))
            return '';
        let useTitle = anime.engTitle.split(" ").slice(0,3).join(" ");

        return useTitle;
    }
    getFigureQuery(name){
        if (!isSet(name))
            return '';
        let useTitle = name.split(" ").slice(0,3).join(" ");
        return "figure "+useTitle;
    }
    
    getAmazonAffiliate(query,isRetry=false)
    {
        let shouldFetch=(!isServer());
        if (query==='' || !isSet(query))
            return Promise.resolve();
        
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            //set the user country if it is unknow
            if (MyAppPool.session.country==="u")
                MyAppPool.session.country=data0.country;

            MyAppPool.session.affiliate.amazon[query]={
                searchLink:data0.searchQuery,
                links:resData
            };
            if (resData.length==0 && (!isServer()) && isRetry===false)
            {//is search is empty, it will probably be searched async, it will take along 30 seconds to be available
            //in that case we retry, we only retry once
                setTimeout(()=>{MyAppPool.cAffiliate.getAmazonAffiliate(query,true)},1000 * 60);
            }
            return Promise.resolve();
        },
        routes.getAmazonAffiliateLinks,true,
        {query:query,country:MyAppPool.session.country,fetch:shouldFetch})
        .catch(e => console.error(("Error Getting Amazon Affiliate Links"),e));
    }
    async getAnimeAmazonAffiliate(anime){
        if (isSet(anime) && !anime.empty)
        {//get affiliates            
            if (isServer())
            {
                await MyAppPool.cAffiliate.getAmazonAffiliate(this.getFigureQuery(anime.engTitle));
                await MyAppPool.cAffiliate.getAmazonAffiliate(this.getAnimeQuery(anime));
            }else
            {
                MyAppPool.cAffiliate.getAmazonAffiliate(this.getFigureQuery(anime.engTitle));
                MyAppPool.cAffiliate.getAmazonAffiliate(this.getAnimeQuery(anime));
            }
        }
        return Promise.resolve();
    }
    async getMangaAmazonAffiliate(manga){
        if (isSet(manga) && !manga.empty)
        {//get affiliates
            if (isServer())
            {
                await MyAppPool.cAffiliate.getAmazonAffiliate(this.getFigureQuery(manga.engTitle));
                await MyAppPool.cAffiliate.getAmazonAffiliate(this.getMangaQuery(manga));
            }else
            {
                MyAppPool.cAffiliate.getAmazonAffiliate(this.getFigureQuery(manga.engTitle));
                MyAppPool.cAffiliate.getAmazonAffiliate(this.getMangaQuery(manga));
            }
        }
        return Promise.resolve();
    }

    async getCharacterAmazonAffiliate(character){
        if (isSet(character) && !character.empty)
        {//get affiliates
            if (isServer())
                await MyAppPool.cAffiliate.getAmazonAffiliate(this.getFigureQuery(character.fullName));
            else
                MyAppPool.cAffiliate.getAmazonAffiliate(this.getFigureQuery(character.fullName));
        }
        return Promise.resolve();
    }
    
}