
import { computed, toJS } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { getDefaultSession, getDefaultUser } from '../store/defaultSessionVals';
import { baseHash, MD5 } from '../util/secUtil';
import { isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { isSet, mobToObj, userFastResourceType } from '../util/typeu';
//import { googleLogout } from '@react-oauth/google';

let googleLogout=undefined;
let refreshCallStack=0;

export default class AuthenticationController
{
    temporalyDisableLogout(timeInSeconds){
        MyAppPool.session.disableLogout=true;
        setTimeout(()=>{MyAppPool.session.disableLogout=false;},(1000*timeInSeconds));
    }
    tokenLogin(token,socialType)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let {userClaims,titles} = data0.data.user;
            let {userid,mainimg,nickname,email} = data0.data.user.userInfo;
            let newUser = {
                token:data0.data.access_token,
                refreshToken:data0.data.refresh_token,
                tokenExpiry:data0.data.expires_in-20,
                userid:userid,
                roles:userClaims,
                img:mainimg,
                name:nickname,
                mailHash:email,
                titles:titles
            };
            MyAppPool.currentUser =  {...MyAppPool.currentUser,...newUser};

            if (data0.data.recover_token)
                MyAppPool.currentUser.recoverToken = data0.data.recover_token;
            //console.log("user:",data0.data);
            MyAppPool.saveUserInfoToLocalStorage();
            this.fetchUserData();
            if (!(MyAppPool.currentUser.recoverToken))
                setTimeout(()=>this.deactivateGlobalLoginBox(),100);
            else
                this.temporalyDisableLogout(20);

            MyAppPool.cAlert.notifyOk(lng("Logado com sucesso!"));
            return Promise.resolve();
        }
        ,
        routes.tokenLogin,false,
        {token,socialType})
        .catch(e => 
            {
                MyAppPool.cAlert.notifyUser(lng('O login falhou! Podemos estar passando por algum tipo de problema, tente novamente!'),JSON.stringify(e),500);
                console.error(("Error Sending token login"),e);
            });
    }

    

    async refreshLogin()
    {
        //console.log("REFRESH LOGIN START");
        if (refreshCallStack!==0)
            return;
        if (this.isGuest())
            return Promise.resolve();
        let initiatingTime = Math.ceil(Date.now()/1000);

        if (MyAppPool.currentUser.tokenExpiry>initiatingTime)
            return Promise.resolve();
        MyAppPool.recoverUserInfo();
        if (MyAppPool.currentUser.tokenExpiry>initiatingTime)
            return Promise.resolve();
        refreshCallStack=1;
        //alright, token is definitely still expired.
        let token = '';
        let device = 'browser';
        if (!isServer())
        {
            const DeviceUUIDLib = await import('device-uuid');
            let {DeviceUUID} = DeviceUUIDLib;
            var uuid = new DeviceUUID().get();
            var du = new DeviceUUID().parse();
            device = du.browser + ' - '+ du.os +"(" + uuid+")";
        }
        
        token=MyAppPool.currentUser.refreshToken;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.data.ok)
            {
                MyAppPool.recoverUserInfo();
                if (MyAppPool.currentUser.tokenExpiry<initiatingTime)
                {
                    refreshCallStack=0;
                    MyAppPool.cAuth.logout();
                    return Promise.reject("Failed to update token");
                }
            }
            let {userClaims,titles} = data0.data.user;
            //console.log("USER ROLES::",userClaims);
            let {userid,mainimg,nickname,email} = data0.data.user.userInfo;
            let newUser = {
                token:data0.data.access_token,
                refreshToken:data0.data.refresh_token,
                tokenExpiry:data0.data.expires_in - 20,
                userid:userid,
                roles:userClaims,
                img:mainimg,
                name:nickname,
                mailHash:email,
                refreshTime:Math.ceil(Date.now()/1000),
                titles:titles
            };
            MyAppPool.currentUser =  {...MyAppPool.currentUser,...newUser};
            MyAppPool.saveUserInfoToLocalStorage();
            refreshCallStack=0;
            return Promise.resolve();
        }
        ,
        routes.refreshLogin,false,
        {token,device})
        .catch(e => {
            refreshCallStack=0;
            console.error(("Error Sending refresh login"),e)
        });
    }

    tokenSetNewPassword(pass)
    {
        let nickName = MyAppPool.currentUser.name;
        let token = MyAppPool.currentUser.recoverToken;
        return this.confirmSetNewPassword(nickName,pass,token).then((res)=>{
            setTimeout(() => {
            MyAppPool.currentUser.recoverToken=undefined;
            return;
        }, 1000);
        return Promise.resolve(res);
        });
    }

    passwordLogin(user,pass)
    {
        let disfiguredPass = baseHash(pass);
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0);

                //console.log("LOGIN EXPIRY TIME::",data0.data.expires_in)
                //console.log("UserTitles::",data0)

            let {userClaims,titles} = data0.data.user;
            let {userid,mainimg,nickname,email} = data0.data.user.userInfo;
            let newUser = {
                token:data0.data.access_token,
                refreshToken:data0.data.refresh_token,
                tokenExpiry:data0.data.expires_in-20,
                userid:userid,
                roles:userClaims,
                img:mainimg,
                name:nickname,
                mailHash:email,
                titles:titles
            };
            MyAppPool.currentUser =  {...MyAppPool.currentUser,...newUser};
            MyAppPool.saveUserInfoToLocalStorage();
            this.fetchUserData();
            setTimeout(()=>this.deactivateGlobalLoginBox(),100);
            this.temporalyDisableLogout(15);
            MyAppPool.cAlert.notifyOk(lng("Logado com sucesso!"));
            return Promise.resolve();
        }
        ,
        routes.passLogin,false,
        {user,pass:disfiguredPass})
        .catch(e => Promise.reject(e.response.status));
    }

    forgotLogin(user)
    {
        let lang = getCurrentLanguage();
        return fetchAndProcess ((data0,ready)=>{
            //console.log("Data0:",data0);
            if (!data0.ok)
                return Promise.reject(data0);
            MyAppPool.cAlert.notifyOk(lng("Um e-mail foi enviado para você, com instruções de como trocar sua senha."));
            return Promise.resolve("ok");
        },
        routes.forgotLogin,false,
        {email:user,lang})
        .catch(e => 
        {
            return Promise.reject(e.response.status);
        });
    }
    createUser(email,name,nickName,pass)
    {
        let lang = getCurrentLanguage();
        let disfiguredPass = baseHash(pass);
        return fetchAndProcess ((data0,ready)=>{
            //console.log("Data0:",data0);
            if (!data0.ok)
                return Promise.reject(data0);
            MyAppPool.cAlert.notifyOk(lng("Usuário criado com sucesso! Siga as instruções para confirmar sua conta!"));
            return Promise.resolve("ok");
        },
        routes.createUser,false,
        {email,name,nickName,pass:disfiguredPass,lang})
        .catch(e => 
        {
            if (e.response.status===409)
                return Promise.reject(e.response.data.data.msg);
            return Promise.reject(e.response.status);
        });
    }
    confirmSetNewPassword(nickName,pass,token)
    {
        let minPassLength=7;
        if (pass.length<minPassLength)
            return Promise.reject(400);

        let lang = getCurrentLanguage();
        let disfiguredPass = baseHash(pass);
        return fetchAndProcess ((data0,ready)=>{
            //console.log("Data0:",data0);
            if (!data0.ok)
                return Promise.reject(data0);
            MyAppPool.cAlert.notifyOk(lng("Senha de backup registrada com sucesso!"));
            return Promise.resolve("ok");
        },
        routes.confirmNewPassword,false,
        {nickName,pass:disfiguredPass,token,lang})
        .catch(e => 
        {
            return Promise.reject(e.response.status);
        });
    }


    tryConfirmUserAccount(nickName,token)
    {
        return fetchAndProcess ((data0,ready)=>{
            //console.log("Data0:",data0);
            if (!data0.ok)
                return Promise.reject(data0);
                MyAppPool.cAlert.notifyOk(lng("Conta Verificada com Sucesso!"));
            return Promise.resolve("ok");
        },
        routes.confirmAccount,false,
        {nickName,token})
        .catch(e => 
        {
            return Promise.reject(e.response.status);
        });
    }

    async fetchUserData()
    {
        await this.refreshLogin();
        if (MyAppPool.currentUser.userid>0)
        {
            MyAppPool.cSamples.getSamplesByArrayIdsList(userFastResourceType,[MyAppPool.currentUser.userid]);
            MyAppPool.cVotes.fetchUserVotes();
            MyAppPool.cGrade.fetchUserGrades();
            MyAppPool.cLists.fetchUserLists();
            MyAppPool.cNeoComu.ensureUserHasComunityNode();
            MyAppPool.cNeoComu.getUserPermissions();
        }
    }

    logout()
    {
        import(/* webpackChunkName: "google-oauth" */ '@react-oauth/google').then((googleStuff)=>{
            googleLogout = googleStuff.googleLogout;
            googleLogout();
        });
        this.logoutCleanup();
        MyAppPool.cAlert.notifyOk(lng("Usuário Desconectado..."));
        setTimeout(()=>this.deactivateGlobalLoginBox(),100);
    }

    logoutCleanup()
    {
        MyAppPool.currentUser = getDefaultUser();
        MyAppPool.session = getDefaultSession();
        MyAppPool.clearLocalStorage();
    }
    
    isGuest()
    {
        let userId = MyAppPool.currentUser.userid;
        return (userId===0 || !isSet(userId));
    }
    isSupporter()
    {
        //console.log("LOOKSUP:",mobToObj(MyAppPool.currentUser));
        if (isSet(MyAppPool.currentUser.titles))
        {
            for(let i=0;i<MyAppPool.currentUser.titles.length;i++)
            {
                let current = MyAppPool.currentUser.titles[i];
                if (current.title>=1 && current.title<=7)
                    return true;
            }
        }
        return false;
    }
    activateGlobalLoginBox()
    {
        MyAppPool.session.loginBoxActive=true;
    }
    deactivateGlobalLoginBox()
    {
        MyAppPool.session.loginBoxActive=false;
    }

    isDataMod(){
        let modClaim = MyAppPool.currentUser.roles.mabdata_mod;
        return (modClaim===true);
    }
    isNeoComuMod(){

        let modClaim = MyAppPool.currentUser.roles.neocomu_mod;

        return (modClaim===true);
    }

}


