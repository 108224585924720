import merge from 'lodash.merge';
import React, { Component } from 'react';
import { computed, observable, toJS } from 'mobx';
//import AuthenticationController from 'controllers/authentication';
//import NotificationController from 'controllers/notification';
import ct from './appConstants';
import AnimeController from './controller/Anime';
import CharacterController from './controller/Character';
import CompanyController from './controller/Company';
import GlobalPageLoadingBarController from './controller/GlobalPageLoadingBar';
import GradeController from './controller/Grade';
import ListsController from './controller/Lists';
import LocationController from './controller/Location';
import MangaController from './controller/Manga';
import Modal from './controller/Modal';
import MusicController from './controller/Music';
import PersonController from './controller/Person';
import PopMenu from './controller/PopMenu';
import PublisherController from './controller/Publisher';
import ReactionPostController from './controller/ReactionPost';
import ReviewController from './controller/Review';
import SearchAnimeMangaController from './controller/SearchAnimeManga';
import SearchOthersController from './controller/SearchOthers';
import TextInteractionController from './controller/TextInteraction';
import UserController from './controller/User';
import UserVotesController from './controller/UserVote';
import { defaultUserInfo, getDefaultSession, getDefaultUser } from './store/defaultSessionVals';
import { isSet } from './util/typeu';
import { getCurrentDomain, getCurrentPathInAnotherLang, getCurrentUrl, isServer } from './util/windowUtil';
import LatestContentController from './controller/LatestContent';
import TopsController from './controller/Tops';
import PageJsonSchemaController from './controller/PageJsonSchema';
import CurrentSeasonController from './controller/CurrentSeason';
import TermsController from './controller/Terms';
import SamplesController from './controller/Samples';
import AuthenticationController from './controller/Authentication';
import AlertController from './controller/Alert';
import Preference from './controller/Preferences';
import ImageController from './controller/Image';
import ContentBannerController from './controller/ContentBanner';
import TasksController from './controller/Tasks';
import AffiliateController from './controller/Affiliate';
import CampaignController from './controller/Campaign';
import ResourceMediaController from './controller/ResourceMedia';
import NeoComuController from './controller/NeoComu';

var USING_LOCAL_CACHE = false;
if (!isServer())
{
	if (getCurrentUrl().indexOf(".brasi.li")!==-1)
	{//production
		USING_LOCAL_CACHE=true;
		//console.log("Using Cache");
	}
}

//const ONE_HOUR_IN_MILIS = 1000 * 60 * 60;
//const ONE_DAY_IN_MILIS = ONE_HOUR_IN_MILIS*24;
//const TIME_FOR_SESSION_TO_EXPIRE_MILIS = ONE_DAY_IN_MILIS;

const ONE_HOUR_IN_SECONDS =  60 * 60;
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS*24;

const TIME_FOR_SESSION_TO_EXPIRE_SECONDS = ONE_DAY_IN_SECONDS;



class AppPool {
	cModal = new Modal();
	cTextInteraction = new TextInteractionController();
	cMusic = new MusicController();
	cAnime = new AnimeController();
	cManga = new MangaController();
	cCompany = new CompanyController();
	cPublisher = new PublisherController();
	cPerson = new PersonController();
	cCharacter = new CharacterController();
	cReview = new ReviewController();
	cVotes = new UserVotesController();
	cGrade = new GradeController();
	cAuth = new AuthenticationController();
	cUser = new UserController();
	cLocation = new LocationController();
	cReactionPosts = new ReactionPostController();
	cGlobalLoadingBar = new GlobalPageLoadingBarController();
	cLists = new ListsController();
	cSearchAnimeManga = new SearchAnimeMangaController();
	cSearchOthers =  new SearchOthersController();
	cPopMenu = new PopMenu();
	cLatestContent = new LatestContentController();
	cTops = new TopsController();
	cPageJsonSchema = new PageJsonSchemaController();
	cCurrentSeason = new CurrentSeasonController();
	cTerms = new TermsController();
	cSamples = new SamplesController();
	cAlert = new AlertController();
	cPreference = new Preference();
	cImage = new ImageController();
	cContentBanner = new ContentBannerController();
	cTasks = new TasksController();
	cAffiliate = new AffiliateController();
	cCampaign = new CampaignController();
	cResourceMedia = new ResourceMediaController();
	cNeoComu = new NeoComuController();
	/*
    @oberservable annotation, makes the variable oberservable, 
    meaning that any update done to this variable will 
    reach any @computed or @observer function/class
    */
	//should only have simple types, saved encrypted in localstorage
	@observable session = getDefaultSession();
	//for object types with functions, not saved in localstorage
	@observable jsObjectStore = {
		notifications:[],
		bannerComponent:<></>,
		lastBannerComponent:<></>,
		modal:{
			title:'Modal',
			content:'Lorem Ipslom',
			active:false,
			className:'',
			cleanupFunction:()=>console.log('modalcleanup'),
			okFunction:() => {console.log('Empty Modal')},
			render:0
		},
		popMenu:{
			content:'Lorem Ipslom',
			active:0,
			x:0,
			y:0,
			className:'',
			cleanupFunction:()=>console.log('popmenucleanup'),
			render:0
		}
	}
	@observable currentUser = getDefaultUser();

	requestCache = {

	};

	jsonSchema= {};

	@computed
	get isLoggedIn()
	{
		if (this.session.authJWTToken !=='Bearer ')
			return true;
		return false;
	}

	@computed get scrollPosition()
	{
		return this.session.scrollPosition;
	}

	setJsonSchema(val){this.jsonSchema=val;}
	getJsonSchema(){return this.jsonSchema;}

	@computed
	get getAlternativeUrlsForThiPage(){
		let retUrls = {
			"pt":"https://nossoanime.net"+this.session.locationExtra.ptPath,
			"de":"https://unseranime.net"+this.session.locationExtra.dePath,
			"en":"https://ouranime.net"+this.session.locationExtra.enPath,
			"es":"https://nueanime.net"+this.session.locationExtra.esPath
		};
		return retUrls;
	}

	setCurrentHttpStatus(val){this.session.httpStatus=val;}
	getCurrentHttpStatus(){return this.session.httpStatus;}

	setScrollPosition(scrollPos)
	{
		this.session.scrollPosition = scrollPos;
	}
	setCurrentLocation(location)
	{
		this.session.location = location;
	}
	@computed get currentUrlEncoded()
	{
		return encodeURIComponent( getCurrentDomain() + this.session.location.pathname );
	}
	@computed get currentSearchParams()
	{
		return this.session.location.search;
	}

	recoverItemFromLocalStorage(itemName)
	{
		if (isServer())
			return;
		try
		{	
		let cachedItem = localStorage.getItem(itemName) ;
		
		if (!isSet(cachedItem))
			return;

        let oldItem = {};
		oldItem =  JSON.parse(cachedItem);

		//console.log(oldItem);
		if (ct.session.version!==oldItem.sessionVersion)
			throw new Error('Mismatching Session Version');

			return oldItem.val;
		}catch(e)
		{
			console.error('Error restoring item from previous session ',e);
			localStorage.removeItem(itemName);
			return;
		}
	}
	saveItemToLocalStorage(itemName,item)
	{
		if (isServer())
			return;
		try
		{
		let saveItem = {sessionVersion:ct.session.version,val:item};

        localStorage.setItem(itemName,JSON.stringify(saveItem));
        
		}catch(e)
		{
			console.error('Unable to save session', e);
		}
	}


	clearLocalStorage()
	{
		localStorage.removeItem('requestCache');
		localStorage.removeItem('userInfo');
		localStorage.removeItem('userSession');
	}

	recoverUserInfo()
	{
		let oldUser = this.recoverItemFromLocalStorage('userInfo');
		//console.log("OLDUSER:::",oldUser);
		if (oldUser!=null && typeof oldUser!=='undefined')
			merge(this.currentUser, oldUser); 
	}
	saveUserInfoToLocalStorage()
	{
		this.saveItemToLocalStorage('userInfo',this.currentUser);
	}

	saveToLocalStorage()
	{
		//this.saveItemToLocalStorage('userSession',this.session);
		//this.saveItemToLocalStorage('requestCache',this.requestCache);
		this.saveItemToLocalStorage('userInfo',this.currentUser);
	}

	getPreloadedState()
	{
		let state = JSON.stringify({session:this.session,requestCache:this.requestCache});
		return state;
	}

	recoverFromServer()
	{

		//console.log(JSON.stringify(this.session));
		if (!isServer() && typeof window.__PRELOADED_STATE__ != 'undefined')
		{
			try{
			let preloadedState = JSON.parse(window.__PRELOADED_STATE__);
			//console.log(preloadedState);
			let serverContent = preloadedState.session;
			//let newSession = {};
			merge(this.session, serverContent);
			//this.session = newSession;

			let serverRequestCache = preloadedState.requestCache;
			let newRequestCache = {...this.requestCache,...serverRequestCache};
			this.requestCache = newRequestCache;
			}catch(e)
			{
				console.error("Failed to load preloaded state");
			}
		}
		this.recoverUserInfo();
	}
	setEntryState(newEntryState) {this.session.entryState = newEntryState;};
	getEntryState(){return this.session.entryState;};
}

//jsdoc example
/** @description Deletes UsersSession from storage.
 * @param {void} radius The radius of the circle.
 * @return {void}
 */

	/*
	mobx sample
    //constructor() {
        //autorun(() => console.log(this.report));
    //}

    
    @computed annotation automatically calculates the new return value based on changes 
	in the data store (obersables)
	@computed get completedTodosCount() {
    	return this.todos.filter(
			todo => todo.completed === true
		).length;
    }
	*/
var MyAppPool = new AppPool();

if (!isServer())
{
	MyAppPool.recoverFromServer();
	window.addEventListener('resize', ()=>
	{
		let x = window.matchMedia("(max-width: 680px)");
		MyAppPool.session.isMobile = x.matches;
	});
	let x = window.matchMedia("(max-width: 680px)");
	MyAppPool.session.isMobile = x.matches;
}

if (USING_LOCAL_CACHE)
{
	if (!isServer())
	{
		window.onbeforeunload = function(){
			MyAppPool.saveToLocalStorage();
			return;
			//return 'Are you sure you want to leave?';
		};
	}
}else
{
	/*
	if (!isServer())
	{
		window.onbeforeunload = function(){
			MyAppPool.saveUserInfoToLocalStorage();
			return;
			//return 'Are you sure you want to leave?';
		};
	}
	*/
}


export default MyAppPool;