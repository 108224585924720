
import { computed, toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getCharacterRoute, getMagazineRoute, getMangaPeopleRoute, getMangaPersonagensRoute, getMangaRelatedRoute, getObraRoute, getPersonRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { convertDataToShowable, getAnchorName, getSEOUriPath } from '../util/text';
import { contentFastTypeToType, contentTypeToFastType, isSet, mobToObj } from '../util/typeu';

let validTypes = {};
validTypes[contentTypeToFastType("music")]=true;
validTypes[contentTypeToFastType("anime")]=true;
validTypes[contentTypeToFastType("manga")]=true;
validTypes[contentTypeToFastType("producer")]=true;
validTypes[contentTypeToFastType("publisher")]=true;
validTypes[contentTypeToFastType("person")]=true;
validTypes[contentTypeToFastType("character")]=true;
validTypes[contentTypeToFastType("user")]=true;

export default class SamplesController
{
    getSamplesByArrayIdsList(resourceType,idsInArrayArg)
    {
        if(validTypes[resourceType]!==true)
            return;
        const chunkSize = 200;
        if (idsInArrayArg.length>chunkSize)
        {
            let p1 = Promise.resolve();
            for (let i = 0; i < idsInArrayArg.length; i += chunkSize) {
                const chunk = idsInArrayArg.slice(i, i + chunkSize);
                p1 = p1.then(()=>this.getSamplesByArrayIdsList(resourceType,chunk));
            }
            return p1;
        }
        let chosenRoute = routes.getDataSamplesByIdsLng;
        if (resourceType===contentTypeToFastType("user"))
            chosenRoute=routes.getUserSamplesByIdsLng;

        //optimization - avoiding asking for data we already got in this session
        if (isSet(MyAppPool.session.samples[''+resourceType]))
        {
            let filterList = Object.values(MyAppPool.session.samples[''+resourceType]);
            idsInArrayArg = idsInArrayArg.filter(id=>!filterList.find(item=>item.id===id));
        }
        if (idsInArrayArg.length===0)
            return Promise.resolve([]);
        //end optimiziation
        
        let idsSet = [...new Set([...idsInArrayArg])];
        idsSet.sort((a,b)=>(a<b?-1:a===b?0:1)); //sort so requests will look the same, we can use cache
        let idsInCommaSeparatedList = idsSet.join('-');

        //console.log("CALLING SAMPLES:::",contentFastTypeToType(resourceType),idsInCommaSeparatedList);
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            for(let i=0;i<resData.length;i++)
            {
                let item = resData[i];
                //console.log("SAMPLE ITEM",item);
                MyAppPool.session.samples[''+resourceType][''+item.id] = item;
                //console.log("SANPLES RES:::",mobToObj(MyAppPool.session.samples[''+resourceType]));
            }
            return Promise.resolve(resData);
        },
        chosenRoute,true,
        {ids:idsInCommaSeparatedList,resourceType:resourceType,lang:getCurrentLanguage()})
        .catch(e => console.error(("Error Getting Data Samples"),e));
    }
    
}