
import { computed, toJS } from 'mobx';
import { brasRequest, fetchAndProcess } from '../util/net';
import MyAppPool from '../AppPool';
import { shuffleArray } from '../util/array';
import { routes } from '../store/ApiRoutes';
import { contentTypeToFastType, isSet, isValidIndex, musicFastResourceType } from '../util/typeu';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { getMusicAnimeRoute, getMusicRoute, getObraRoute } from '../store/AppRoutes';
import { isServer } from '../util/windowUtil';
import { getAnchorName, getSEOUriPath } from '../util/text';
import { getRelatedTypeIcon } from '../util/icon';


function compareText (a, b) {
    var nameA = a.toUpperCase(); // ignore upper and lowercase
    var nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  };
function compareByBand(a,b)
{
    return compareText(a.band,b.band);
}

function compareByTitle(a,b)
{
    return compareText(a.title,b.title);
}

function uniqBy(a, key) {
    var seen = {};
    return a.filter(function(item) {
        var k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
}

export default class MusicController
{

    fetchMusicById(index)
    {
        let loadId;
        if (!isSet(index) || !isValidIndex(index))
            return Promise.resolve(true);
        if (Number(index)===Number(MyAppPool.session.character.currentCharacter))
        {   
            loadId = MyAppPool.cGlobalLoadingBar.start();
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(true);
        }
        
        MyAppPool.session.music.musics[index] = {ready:0};
        MyAppPool.session.music.currentMusic= index;
        loadId = MyAppPool.cGlobalLoadingBar.start(1500);
        if (!isServer())
            brasRequest(routes.resourcePopularCount,false,{resourceType:contentTypeToFastType('music'),resourceId:index});
      
        return fetchAndProcess(this.setSessionMusic,
            routes.getMusicByIdLng,true,{id:index,lng: getCurrentLanguage()})
            .then((data) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,true);
                let music = MyAppPool.session.music.musics[MyAppPool.session.music.currentMusic];
                return Promise.resolve(data);
            })
            .catch((e) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,false);
                MyAppPool.session.musicFetchEmpty[''+index]=true;
                //MyAppPool.cAlert.notifyGenericError(JSON.stringify(e),"fetch music");
                console.error("Error Fetching music",e)
            });
    }

    setSessionMusic(data0,ready)
    {
        if (!data0.ok)
            return Promise.reject();
        
        let data = data0.data;
        
        if (!data.empty)
        {
            data.otherTitles="";
            if (data.alternativename!=='' && isSet(data.alternativename))
                data.otherTitles=data.alternativename;
            data.remainingTitles='';
            data.columItems = [];
            let seoUriName = getSEOUriPath(data.name+ '-' + data.band);
            
            let relatedAnimeTypes = {};
            for(let i =0; i<data.anime.length; i++)
            {
                let element = data.anime[i];
                let anchor = '#'+getAnchorName(element.title);
                element.url=getMusicAnimeRoute(data.id,seoUriName,anchor);
                element.directUrl=getObraRoute(element.id,getSEOUriPath(element.title),element.pagetype,'#top');
                element.imgurl=element.mainimg;
                element.icon=getRelatedTypeIcon(element.type);
                element.iconText= lng(element.type);
                element.resourceType=contentTypeToFastType(element.pagetype);
                element.resourceId=element.id;

                relatedAnimeTypes[element.type]=1;
            }
            relatedAnimeTypes = Object.keys(relatedAnimeTypes);
            
            data.relatedAnimeTypes = relatedAnimeTypes;
            data.baseUrl = getMusicRoute(data.id,seoUriName);
            data.resourceType = contentTypeToFastType('music');
            data.resourceId=data.id;

        //console.log(data);
        MyAppPool.session.grades[contentTypeToFastType('music')][data.id] = data.grades;
        MyAppPool.session.music.musics[data.id] = {...MyAppPool.session.music.musics[data.id],...data} ;
        MyAppPool.session.music.musics[data.id].ready = 1;//ready?1:0;
        console.log("LOOK2:",data);
        
        MyAppPool.session.music.currentMusic=data.id;
        MyAppPool.session.musicFetchEmpty[''+data.id]=false;
        return Promise.resolve(true);
        }
        

        return Promise.reject("empty");
    }

    updateMusicVideoUrl(videoId,url){
        if (isSet(videoId))
        {
            return fetchAndProcess ((data0,ready)=>{
                if (!data0.ok)
                    return Promise.reject(data0.msg);
                MyAppPool.cAlert.notifyOk(lng("Sucesso!"));
                return Promise.resolve();
            },
            routes.userUpdateMusicUrl,
            false,
            {videoUrl:url,musicId:videoId}
            )
            .catch(e => {
           //     console.log("Error updating user profile:",e); 
                MyAppPool.cAlert.notifyGenericError(e,"user, update music videourl");
            return Promise.reject(e);});
        }

        return Promise.reject("videoId or url not set");
    }
    loadMusicListToQueue(itens,name)
    {
        //console.log("Loading music list:",name);
        let fetchIds = [];
        for(let i=0;i<itens.length;i++)
        {
            fetchIds[i]=itens[i].resourceid;
        }
        return MyAppPool.cSamples.getSamplesByArrayIdsList(musicFastResourceType,fetchIds).then(
            (data) =>{
                //console.log(data);
                if (data.length>0)
                {
                    let newPlayQueue = data;
                    MyAppPool.session.musicPlayer.currentPlayQueue = newPlayQueue;
                    this.setCurrentMusic(0);
                }
            }
        ).catch(e => {
            MyAppPool.cAlert.notifyGenericError(e,"load music to queue");
            console.error(("Error Loading Music List Samples"),e);
        });
    }

    setCurrentMusic(index)
    {
        if (index<MyAppPool.session.musicPlayer.currentPlayQueue.length && index>=0)
        {
            MyAppPool.session.musicPlayer.currentPlayItem = MyAppPool.session.musicPlayer.currentPlayQueue[index];
            MyAppPool.session.musicPlayer.currentPlayIndex = index;
        }
    }
    stopAndShouldResumeWhenPossible()
    {
        
        MyAppPool.session.musicPlayer.shouldResume=1;
        this.setStopPlease(1);
    }
    startIfShouldResume()
    {
        if (MyAppPool.session.musicPlayer.shouldResume)
        {
            
            MyAppPool.session.musicPlayer.shouldResume=0;
            if (isSet(MyAppPool.session.musicPlayer.currentPlayItem) && isSet(MyAppPool.session.musicPlayer.currentPlayItem.id)
            && MyAppPool.session.musicPlayer.currentPlayItem.id!==0)
            {
                this.setPlayPlease(1);
            }
        }
    }
    playMusicObject(musicItem)
    {
        if (musicItem.id===0 || typeof musicItem.title === 'undefined')
            return;
        MyAppPool.session.musicPlayer.currentPlayItem = musicItem;
        MyAppPool.session.musicPlayer.playPlease = 1;

        let {currentPlayQueue} = MyAppPool.session.musicPlayer;
        let index =  currentPlayQueue.indexOf(musicItem);
        if (index!==-1)
        {
            this.setCurrentMusic(index);
        }
    }
    
    addToPlayQueue(musicItem)
    {
        if (musicItem.ytid!=='' && isSet(musicItem.ytid))
        {
            let {currentPlayQueue} = MyAppPool.session.musicPlayer;
            let newPlayQueue = [...currentPlayQueue, ...[musicItem]];
            let uniqueArray = uniqBy(newPlayQueue,(elem)=>elem.id);
            MyAppPool.session.musicPlayer.currentPlayQueue = uniqueArray;
            MyAppPool.cAlert.notifyUser(musicItem.title +" "+ lng("Adicionado a lista de reprodução"),'',200);
        }
    }
    

    removeFromPlayQueue(musicItem)
    {
        let {currentPlayQueue} = MyAppPool.session.musicPlayer;

        let index =  currentPlayQueue.indexOf(musicItem);
        if (index!==-1)
        {
            //console.log("music index to remove: ", index)

            currentPlayQueue.splice(index, 1);

            let newPlayQueue = [...currentPlayQueue];
            MyAppPool.session.musicPlayer.currentPlayQueue = newPlayQueue;
        }
    }

    clearPlayQueue()
    {
        MyAppPool.session.musicPlayer.currentPlayQueue=[];
        MyAppPool.session.musicPlayer.currentPlayIndex = 0;
    }


    goNext()
    {
        
        let {currentPlayIndex,currentPlayQueue, replay, shuffle} = MyAppPool.session.musicPlayer;
        let nextIndex = currentPlayIndex+1;
        if (nextIndex<currentPlayQueue.length)
        {
            let checkMusic = MyAppPool.session.musicPlayer.currentPlayQueue[nextIndex];
            while(!isSet(checkMusic["ytid"]) || checkMusic["ytid"] === ""){
                nextIndex++;
                if (nextIndex>=currentPlayQueue.length)
                    break;
                checkMusic = MyAppPool.session.musicPlayer.currentPlayQueue[nextIndex];
            }
            if (nextIndex<currentPlayQueue.length)
            {
                MyAppPool.session.musicPlayer.playPlease = 1;
                this.setCurrentMusic(nextIndex);
                this.setPlayPlease(1);
            }else
            if (replay)
            {
                if (shuffle)
                    this.doShuffle();
                else
                    this.doNormalOrder();
                MyAppPool.session.musicPlayer.playPlease = 1;
                this.setCurrentMusic(0);
                this.setPlayPlease(1);
            }
        }
        else
            if (replay)
            {
                if (shuffle)
                    this.doShuffle();
                else
                    this.doNormalOrder();
                    MyAppPool.session.musicPlayer.playPlease = 1;
                    this.setCurrentMusic(0);
                    this.setPlayPlease(1);
            }
        
    }
    goBack(){
        let {currentPlayIndex} = MyAppPool.session.musicPlayer;
        let nextIndex = currentPlayIndex-1;
        if (nextIndex>=0)
        {
            let checkMusic = MyAppPool.session.musicPlayer.currentPlayQueue[nextIndex];
            while(checkMusic["ytid"] === undefined || checkMusic["ytid"] === ""){
                nextIndex--;
                if (nextIndex<=0)
                    break;
                checkMusic = MyAppPool.session.musicPlayer.currentPlayQueue[nextIndex];
            }

            MyAppPool.session.musicPlayer.playPlease = 1;
            if (nextIndex>=0 && nextIndex!=currentPlayIndex)
                this.setCurrentMusic(nextIndex);   
        }
    }
    doShuffle()
    {
        let {currentPlayQueue} = MyAppPool.session.musicPlayer;
        let newPlayQueue = shuffleArray(currentPlayQueue);
        MyAppPool.session.musicPlayer.currentPlayQueue = newPlayQueue;
    }
    doNormalOrder()
    {
        let {currentPlayQueue,ordering} = MyAppPool.session.musicPlayer;
        let newPlayQueue = [];
        if (ordering==='title')
            newPlayQueue = currentPlayQueue.sort(compareByTitle);
        else
            newPlayQueue = currentPlayQueue.sort(compareByBand);
        MyAppPool.session.musicPlayer.currentPlayQueue = newPlayQueue;
    }
    organizeMusic()
    {
        let {shuffle} = MyAppPool.session.musicPlayer;
        if (shuffle)
            this.doShuffle();
        else
            this.doNormalOrder();
    }
    toggleShuffle()
    {
        let {shuffle} = MyAppPool.session.musicPlayer;
        MyAppPool.session.musicPlayer.shuffle = !shuffle;
        this.organizeMusic();
    }
    toggleReplay()
    {
        MyAppPool.session.musicPlayer.replay = !MyAppPool.session.musicPlayer.replay;
    }
    toggleOpen()
    {
        MyAppPool.session.musicPlayer.open = !MyAppPool.session.musicPlayer.open;
    }
    setVolume(newVol)
    {
        MyAppPool.session.musicPlayer.currentVol = newVol;
    }
    setPlayPlease(playing)
    {
        MyAppPool.session.musicPlayer.playPlease =playing;
    }
    setStopPlease(playing)
    {
        MyAppPool.session.musicPlayer.stopPlease =playing;
    }

    setIsPlaying(playing)
    {
        MyAppPool.session.musicPlayer.isPlaying =playing;
    }
    @computed get shouldPlay()
    {
        return MyAppPool.session.musicPlayer.playPlease;
    }
    @computed get shouldStop()
    {
        return MyAppPool.session.musicPlayer.stopPlease;
    }
    @computed get volume()
    {
        return MyAppPool.session.musicPlayer.currentVol;
    }
    @computed get open()
    {
        return MyAppPool.session.musicPlayer.open;
    }
    @computed get shuffle()
    {
        return MyAppPool.session.musicPlayer.shuffle;
    }
    @computed get ordering()
    {
        return MyAppPool.session.musicPlayer.ordering;
    }
    @computed get replay()
    {
        return MyAppPool.session.musicPlayer.replay;
    }
    @computed get currentMusic()
    {
        return MyAppPool.session.musicPlayer.currentPlayItem;
    }
    @computed get currentPlayQueue()
    {
        return MyAppPool.session.musicPlayer.currentPlayQueue;
    }
    @computed get currentPlayIndex()
    {
        return MyAppPool.session.musicPlayer.currentPlayIndex;
    }
    @computed get isPlaying()
    {
        return MyAppPool.session.musicPlayer.isPlaying;
    }
    

    @computed get currentMusicPage()
    {
        let data = toJS(MyAppPool.session.music.musics[MyAppPool.session.music.currentMusic]);
        if (!isSet(data))
            return {ready:0};
        data.grades = MyAppPool.session.grades[contentTypeToFastType('music')][MyAppPool.session.music.currentMusic];
        return data;//MyAppPool.session.music.musics[MyAppPool.session.company.currentMusic];
    }
    
}