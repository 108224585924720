import React, { Component } from 'react';
import "./FileSelect.css";
import { PropTypes } from 'prop-types';
import { brasRequest } from '../util/net';
import { lng } from '../ulanguages/language';
import { routes } from '../store/ApiRoutes';
import Spinner from './Spinner';
import MyAppPool from '../AppPool';

let kbyte = 1024;
let mbyte = 1024*kbyte;
let fileSizeLimit=1; //in MBs

export function handleFileSelectUploadImgur(evt) //not used anymore
{
    return new Promise((resolve, reject) => {
    evt.stopPropagation();
    evt.preventDefault();
    var files = [];
    if (evt.target)
        files = evt.target.files;
    if (evt.dataTransfer)
        files = evt.dataTransfer.files; // FileList object.
    
    // files is a FileList of File objects. List some properties.
    var output = [];
    var reader;
    //show upload spinner
    for (var i = 0, f; f = files[i]; i++) 
    {
        if (!f.type.match('image.*'))
            continue;
            //console.log(f.type);
        if (f.size > (fileSizeLimit*mbyte) && f.type!=='image/gif')
            return reject(lng("Imagem acima do limite")+"("+fileSizeLimit +" MBs)");
        if (f.size > (fileSizeLimit*mbyte))
            return reject(lng("Imagem acima do limite")+"("+fileSizeLimit +" MBs)");
        reader = new FileReader();

        reader.onload = function (e) 
        {
        let pos = e.target.result.indexOf(',');
        let myimg = e.target.result.substr(pos+1);
        //console.log(myimg);
        
        return brasRequest( routes.postImageToImgur, false, {image: myimg, type:'base64'})
        .then(resp => {
            //console.log(resp.data.data);
            let data = resp.data.data;
            return resolve({
                uploadData:data,
                fileName:e.name,
                evt:evt,
                files:files
            });
            //data.link
            //e.name
        }).catch(e => reject(e));
      };


        output.push('<li><strong>', escape(f.name), '</strong> (', f.type || 'n/a', ') - ',
                    f.size, ' bytes, last modified: ',
                    f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : 'n/a',
                    '</li>');
        reader.readAsDataURL(f);
    }
});
};



export function handleFileSelectUploadMabUser(evt,purpose) 
{
    return new Promise((resolve, reject) => {
    evt.stopPropagation();
    evt.preventDefault();
    var files = [];
    if (evt.target)
        files = evt.target.files;
    if (evt.dataTransfer)
        files = evt.dataTransfer.files; // FileList object.
    
    // files is a FileList of File objects. List some properties.
    var output = [];
    var reader;
    //show upload spinner
    for (var i = 0, f; f = files[i]; i++) 
    {
        if (!f.type.match('image.*'))
            continue;
            //console.log(f.type);
            /*
        if (f.size > (fileSizeLimit*mbyte) && f.type!=='image/gif')
            return reject(lng("Imagem acima do limite")+"("+fileSizeLimit +" MBs)");
        if (f.size > (fileSizeLimit*mbyte))
            return reject(lng("Imagem acima do limite")+"("+fileSizeLimit +" MBs)");
            */
        reader = new FileReader();

        reader.onload = function (e) 
        {
        let pos = e.target.result.indexOf(',');
        let myimg = e.target.result.substr(pos+1);
        //console.log(myimg);
        
        return MyAppPool.cImage.uploadUserImage(purpose,myimg,'imgur').then((link)=>{
            return resolve(link);
        })
        .catch(e => reject(e));
      };
        output.push('<li><strong>', escape(f.name), '</strong> (', f.type || 'n/a', ') - ',
                    f.size, ' bytes, last modified: ',
                    f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : 'n/a',
                    '</li>');
        reader.readAsDataURL(f);
    }
});
};


export function handleFileSelectUploadMabData(evt,purpose,resourceType,resourceId) 
{
    return new Promise((resolve, reject) => {
    evt.stopPropagation();
    evt.preventDefault();
    var files = [];
    if (evt.target)
        files = evt.target.files;
    if (evt.dataTransfer)
        files = evt.dataTransfer.files; // FileList object.
    
    // files is a FileList of File objects. List some properties.
    var output = [];
    var reader;
    //show upload spinner
    for (var i = 0, f; f = files[i]; i++) 
    {
        if (!f.type.match('image.*'))
            continue;
            //console.log(f.type);
            /*
        if (f.size > (fileSizeLimit*mbyte) && f.type!=='image/gif')
            return reject(lng("Imagem acima do limite")+"("+fileSizeLimit +" MBs)");
        if (f.size > (fileSizeLimit*mbyte))
            return reject(lng("Imagem acima do limite")+"("+fileSizeLimit +" MBs)");
            */
        reader = new FileReader();

        reader.onload = function (e) 
        {
        let pos = e.target.result.indexOf(',');
        let myimg = e.target.result.substr(pos+1);
        //console.log(myimg);
        
        return MyAppPool.cResourceMedia.uploadResourceMediaImage(resourceType,resourceId,purpose,myimg,'brasifiles').then((link)=>{
            return resolve(link);
        })
        .catch(e => reject(e));
      };
        output.push('<li><strong>', escape(f.name), '</strong> (', f.type || 'n/a', ') - ',
                    f.size, ' bytes, last modified: ',
                    f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : 'n/a',
                    '</li>');
        reader.readAsDataURL(f);
    }
});
};


class FileSelect extends Component {
constructor(props)
{
    super(props);
    this.state={uploading:false};
    this.uploadFile = this.uploadFile.bind(this);
}
  uploadFile(e)
  {
    let {purpose} = this.props;
    let {service,resourceType,resourceId} = this.props;
    this.setState({uploading:true});
    let uploadFunc = handleFileSelectUploadMabUser;
    if (service==='mabdata')
        uploadFunc = handleFileSelectUploadMabData;

    return uploadFunc(e,purpose,resourceType,resourceId).then((e)=>{
        this.setState({uploading:false});
        return this.props.onUpload(e);
    }).catch((e)=>{
        //console.log(e);
        //MyAppPool.cAlert.notifyGenericError(e,"file select upload file");
        this.setState({uploading:false});
    })
  };
  componentDidMount()
  {

  }


  render(){
    let {text,onChange,accept,service,resourceType,resourceId} = this.props;
    let {uploading} = this.state;
    return (
        <div className="uk-button uk-button-primary  comp_fileselect">
        {uploading?
            <Spinner />
            :<span>{text}</span>
        }
        
        {!uploading?<input type="file" onChange={this.uploadFile} className=" clickable custom-file-input" name="file" accept={accept}/>:<></>}
      </div>);
}
}

FileSelect.propTypes = {
    text: PropTypes.string.isRequired,
    onUpload:PropTypes.func.isRequired,
    accept:PropTypes.string.isRequired,
    purpose:PropTypes.string.isRequired,
    service:PropTypes.string.isRequired,
    resourceType:PropTypes.number,
    resourceId:PropTypes.number,
};
FileSelect.defaultProps = {
  text:"Choose a file...",
  onUpload:(event)=>{console.log(event); return Promise.resolve(event)},
  accept:"image/gif, image/jpeg, image/png, image/webp",
  purpose:'avatar',
  service:'mabuser',
  resourceType:0,
  resourceId:0
};

export default FileSelect;
