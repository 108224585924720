import langPt from './langPt';
import langAll from './langAll';
import { getCurrentPath, getCurrentUrl } from '../util/windowUtil';
import { isSet } from '../util/typeu';

export function getCurrentLanguage()
{
   let url = getCurrentUrl();
   //console.log("URL:",url);
   if (url.indexOf('nueanime.net')!==-1)
    return 'es';
   if (url.indexOf('unseranime.net')!==-1)
    return 'de';
   if (url.indexOf('ouranime.net')!==-1)
    return 'en';
   if (url.indexOf('nossoanime.net')!==-1)
    return 'pt';

   return 'pt';
}

let localeConfig = {
    "en":{
        "locale":"en",
        "language":"en"
    },
    "pt":{
        "locale":"pt-br",
        "language":"pt"
    },
    "de":{
        "locale":"de",
        "language":"de"
    },
    "es":{
        "locale":"es",
        "language":"es"
    }
}

export function getCurrentLocale()
{
    var currentLanguage = getCurrentLanguage();
    return localeConfig[currentLanguage].locale;
}

var currentLanguage = getCurrentLanguage();
var languages = {};
languages['pt'] = langPt;
export function setLanguage(newLang)
{
    currentLanguage = newLang;
}

export function getUserPreferredLanguages() {
    return navigator.languages || [navigator.language || navigator.userLanguage];
}

export function matchUserLanguagesWithOptions() {
    const preferredLanguages = getUserPreferredLanguages();
    let matchedLanguages = [];

    preferredLanguages.forEach(lang => {
        let baseLang = lang.split('-')[0];
        if (localeConfig[baseLang]) {
            matchedLanguages.push(localeConfig[baseLang]);
        } else if (alternateLocales[baseLang]) {
            let alternateMatches = alternateLocales[baseLang].filter(alt => lang.startsWith(alt));
            if (alternateMatches.length > 0) {
                matchedLanguages.push(localeConfig[baseLang]);
            }
        }
    });
    return matchedLanguages;
}

export function lng(text,noerror=false,specificLang='') 
{
    //let ret = languages[currentLanguage][text];
    if (!isSet(text))
    {
        console.error("undefined passed to lng");
        return "";
    }
    let pt1 = langAll[text];
    if (typeof pt1 ==='undefined' || pt1===null)
    {
        if (noerror==false)
        {
        var err = new Error();
        console.error("ERROR: Language Text Not Defined! ("+text+")",err.stack);
        }
        return "-.-";
    }
    let choosenLanguage=currentLanguage;
    if (specificLang!=='')
        choosenLanguage=specificLang;
    let ret = pt1[choosenLanguage];
    if (typeof ret ==='undefined' || ret===null)
    {
        if (noerror==false)
        {
        var err = new Error();
        console.error("ERROR: Language Text Not Defined! ("+text+")",err.stack);
        }
        return '-.-';
    }
    if (ret==='-')
        return text;
    return ret;
}

export function websiteLanguageNotMatchingUserLanguage() {
    const preferredLanguages = getUserPreferredLanguages();
    const currentLanguage = getCurrentLanguage();

    for (const lang of preferredLanguages) {
        const baseLang = lang.split('-')[0];
        if (baseLang === currentLanguage) {
            return false;
        }
    }
    return true;
}

export function translateText(sourceLang, targetLang, text) {
    // Find the key that has the source text
    let entries = Object.entries(langAll);
    for (const [key, value] of entries) {
      if (value[sourceLang] === text) {
        return value[targetLang];
      }
    }
    // Return the original text if no translation is found
    return '-.-';
}

let mainLocales = {};
mainLocales['pt'] = 'pt_BR';
mainLocales['en'] = 'en_US';
mainLocales['es'] = 'es_ES';
mainLocales['de'] = 'de_DE';

let hostPerLanguage = {};
hostPerLanguage['pt'] = 'nossoanime.net';
hostPerLanguage['en'] = 'ouranime.net';
hostPerLanguage['es'] = 'nueanime.net';
hostPerLanguage['de'] = 'unseranime.net';

export function getMainLocale()
{
    return mainLocales[currentLanguage];
}

let alternateLocales = {};
alternateLocales['pt'] = ['pt-PT','pt-AO','pt-GW','pt-MZ','pt-ST','pt-CV'];
alternateLocales['en'] = ['en-029','en-AU','en-BZ','en-CA','en-GB','en-IE','en-IN','en-JM','en-MY','en-NZ','en-PH','en-SG','en-TT','en-US','en-ZA','en-ZW'];
alternateLocales['es'] = ['es-AR','es-BO','es-CL','es-CO','es-CR','es-DO','es-EC','es-ES','es-GT','es-HN','es-MX','es-NI','es-PA','es-PE','es-PR','es-PY','es-SV','es-US','es-UY','es-VE'];
alternateLocales['de'] = ['de-AT','de-CH','de-DE','de-LI','de-LU'];

let twitterUsers = [];
twitterUsers['pt'] = '@brasilibro';
twitterUsers['en'] = '@brasilibro';
twitterUsers['es'] = '@brasilibro';
twitterUsers['de'] = '@brasilibro';

export function getAlternateLocales()
{
    return alternateLocales[currentLanguage];
}

export function getUrlAlternatesWithLocale()
{
    let pairs = Object.entries(hostPerLanguage);
    let ret = [];
    let currentPath = getCurrentPath();

    for (let i=0; i < pairs.length; i++)
    {
        let pathKeys = currentPath.split('/');
        for(let k=0;k<pathKeys.length;k++)
        {
            let replace = lng(pathKeys[k],true,pairs[i][0]);
          //  console.log(pathKeys[k],replace);
            
            if (replace==='-.-')
                continue;
            pathKeys[k] = replace;
        }
        let newPath = pathKeys.join('/');
        let item = {url:'https://'+pairs[i][1]+newPath,hreflang:pairs[i][0].replace('_','-')};
        ret[ret.length] = item;
    }
    return ret;
}

export function getTwitterUser()
{
    return twitterUsers[currentLanguage];
}


