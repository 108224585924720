import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../../AppPool';
import { lng } from '../../ulanguages/language';
import { getImgAddress, getRouteParamAtIndex } from '../../util/text';
import { getIfIsSetElems, isSet } from '../../util/typeu';
//import LoginBox from '../view_component/LoginBox';
import { getCurrentUrl, isServer } from '../../util/windowUtil';
import SpinnerBox from '../../view_component/SpinnerBox';

import './home.css';
import ContentBannerSetter from '../../view_component/ContentBannerSetter';
import ThreadPreview from "../../view_component/ThreadPreview";
import InfiniteScrollBlock from "../../view_component/InfiniteScrollBlock";
import { serverRegisterFetch } from "../../util/updateManager";
import ThreadPinned from "../../view_component/ThreadPinned";
import ThreadTag from "../../view_component/ThreadTag";
import { getForumSectionPath } from "../../store/AppRoutes";
import { toJS } from "mobx";
import Erro404 from "../../view_component/Erro404";
import ScrollToTopButtom from "../../view_component/ScrollToTopButton";
import UrlParamPageSelector from "../../view_component/UrlParamPageSelector";

let pageSizeGlob = 10;
@observer
class ForumSection extends Component {

  constructor(props) {
    super(props);

    this.state = {currentPage:1,pageSize:pageSizeGlob, lastFetchSize:1};
    serverRegisterFetch(ForumSection.getInitialValues);
  }

  componentDidMount()
  {
    ForumSection.getInitialValues();
  }
  static async getInitialValues()
  {
    let sectionId = getRouteParamAtIndex(getCurrentUrl(),3);
    if (isSet(sectionId))
    {
        let p1 = MyAppPool.cNeoComu.getSectionThreads(sectionId,1,pageSizeGlob);
        let p3 = MyAppPool.cNeoComu.getSectionPinnedThreads(
            sectionId,1,10); 
        let p4 = MyAppPool.cNeoComu.getSection(sectionId);
        return Promise.all([p1,p3,p4]);
    }else
    {
    return Promise.resolve();
}
  }
  componentWillReceiveProps()
  {
    ForumSection.getInitialValues();
  }

  loadNextPage = ()=>{
    let sectionId = getRouteParamAtIndex(getCurrentUrl(),3);
    if (isSet(sectionId))
    {
        let {lastFetchSize,currentPage,pageSize} = this.state;
        if (lastFetchSize>0)
        {
            return MyAppPool.cNeoComu.getSectionThreads(sectionId,currentPage+1,pageSize).then(data=>{
                this.setState({lastFetchSize:data.length,currentPage:currentPage+1});
                return Promise.resolve();
            });
        }
        return Promise.resolve();
    }
  }

  render(){
    let {pageSize,currentPage} = this.state;
    let meta = {title:lng('Forum')+" "+lng('Home')+' | NANI',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    let section = Number(getRouteParamAtIndex(getCurrentUrl(),3));
    if (isNaN(section))
    {
        //console.log("SECTION IS NaN");
        return <Erro404 />
    }
    let sectionThreadIDPages = getIfIsSetElems(MyAppPool.session.neocomu.sectionThreads,[""+section,""+pageSize]);
    let allPages=[];
    if (isSet(sectionThreadIDPages)) 
    {
        allPages = Object.values(sectionThreadIDPages).flat();
    }
    let sectionData = MyAppPool.session.neocomu.sections[section];
    if (MyAppPool.session.neocomu.sectionFetchStatus[section]===1 && !isSet(sectionData))
      return <Erro404 />;
    if (!isSet(sectionData)){
      sectionData = {
        subsections:[],
        admins: [],
        mods: [],
        owner: 0
      };
    };
    let name = "";
    let description = "";
    if (isSet(sectionData.properties))
    {
        name = sectionData.properties.name;
        description = sectionData.properties.description;
    }
    //console.log("SECTIONDATA",toJS(sectionData));
    let pinnedThreads = [];
    if (isSet(MyAppPool.session.neocomu.sectionPinnedThreads[""+section]))
    {
        pinnedThreads = MyAppPool.session.neocomu.sectionPinnedThreads[""+section];
    }
    //console.log("LOOKTHREADS:::", pinnedThreads);
    //console.log("Section:::", section);
    
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div >
            <div className=' contentArea uk-flex uk-flex-row'>
            <ContentBannerSetter />
            <div className="threadsSection">
            <div style={{marginBottom:"4px"}}>
              {sectionData.subsections.map((elemId,index)=>{
                return <ThreadTag backgroundColor={"#33ccff"} name={elemId.properties.name} url={getForumSectionPath(elemId.id,elemId.properties.name)}/>
              })}
            </div>
                <h2>/{name}</h2>
                <h5>{description}</h5>
                <div className="pinSection">
                  {pinnedThreads.map((elemId,index)=>{
                  let elem = MyAppPool.session.neocomu.threads[elemId];
                  return  <div style={{"width":"48%", "marginRight":"1%","marginBottom":"10px"}}>
                    <ThreadPinned key={index} thread={elem} /> 
                    </div>
                  })}
                </div>
                
                
                <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                {allPages.map((elemId,index)=>{
                let elem = MyAppPool.session.neocomu.threads[elemId];
                return  <ThreadPreview key={index} thread={elem} /> 
                })}
                </InfiniteScrollBlock>
            </div>
            
            <div className="scrollSection">
              <ScrollToTopButtom />
            </div>

            </div>
          </div>
        </div>
      );
}

}

export default ForumSection;
