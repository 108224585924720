import React, { Component } from 'react';
import PropTypes, { element } from 'prop-types';
import './ThreadNewsPreview.css'; // Ensure this file exists for styling
import { isSet, mobToObj, userFastResourceType } from '../util/typeu';
import MyAppPool from '../AppPool';
import { getImgAddress } from '../util/text';
import { getUserAvatar } from '../util/icon';
import { observer } from 'mobx-react';
import htmlToReact from 'html-to-react';
import { lng } from '../ulanguages/language';
import { timeSinceString } from '../util/timeUtil';
import ThreadTag from './ThreadTag';
import LinkV2 from './LinkV2';
import { getForumSectionPath, getForumTagPath, getForumThreadPage, getUserRoute } from '../store/AppRoutes';
import TitleBadge from './TitleBadge';
import YoutubeVideo from './YoutubeVideo';
import { toJS } from 'mobx';
import ResourceLinkBox from './ResourceLinkBox';
import { isServer } from '../util/windowUtil';
import { getYoutubeThumbnail } from '../util/youtube';

function getPreview(displayContent, query='') {
  let pos=-1;
  let text = '';
  pos = displayContent.indexOf('<br');
  if (pos!=-1)
    text = displayContent.substr(0,pos);
  else
    text = displayContent;
  text = text.replace(/<[^>]+>/g, '');

  if (query) {
    const index = text.toLowerCase().indexOf(query.toLowerCase());
    if (index !== -1) {
      const start = Math.max(0, index - 100);
      const end = Math.min(text.length, start + 200);
      const preview = text.substring(start, end);
      const highlighted = preview.replace(new RegExp(`(${query})`, 'ig'), '<span style="color: var(--highLightedText);">$1</span>');
      return highlighted + (end === text.length ? '' : '...');
    }
  }
  return text.substring(0, 200) + (text.length > 200 ? '...' : '');
}

@observer
class ThreadNewsPreview extends Component {
  // Helper function to format the relative time
  constructor(props) {
    super(props);
    this.state = {
      revealContent: false,
      revealHovered: false,
      linkMax:isServer()?99:3
    };
  }
  

  renderMedia(content) {
    const youtubeMatch = content.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]+)/
    );
    
    if (youtubeMatch) {
      let ytThumb = getYoutubeThumbnail(youtubeMatch[1]);
      return <div className="news-image" alt="Thread Media" style={{
        backgroundImage: `url(${ytThumb})`
      }} />;
    }

    const imgMatch = content.match(/<img.*?src="(.*?)"/);
    if (imgMatch) {
      return <div className="news-image" alt="Thread Media" style={{
        backgroundImage: `url(${imgMatch[1]})`
      }} />;
    }
    return <div className="news-image" alt="Thread Media" style={{
      backgroundColor: 'black'
    }} />;
  }

  render() {
    const { thread, query } = this.props;
    const { title, content, datecreated, repliesAmount, views,isNSFW,isInappropriate,isSpoiler } = thread.thread.properties;
    const { id } = thread.thread;
    const { tags,user,highlightedReply } = thread;
    let {revealContent, revealHovered, linkMax} = this.state;
    const {disableNSFWProtection, disableSpoilerProtection, disableInapropriateContentProtection} = MyAppPool.session.browserOptions;

    let addParamToLink = '';
    let highLightedPreviewText = '';
    let highLighted = false;
    if (isSet(highlightedReply))
    {
      addParamToLink = highlightedReply.id;
      highLightedPreviewText = getPreview(highlightedReply.properties.content,query);
      highLighted=true;
    }

    let linkCount = 0;
    let linkAvailable = 0;
    
    //let sectionName = thread.section.properties.name;
    //let sectionId = thread.section.id;
    let useTitle = title.slice(0,64);
    if (useTitle.length !== title.length)
      useTitle+='...';
    let useTags = [];
    if (Array.isArray(tags)) {
      useTags = tags;
    }
    let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+userFastResourceType][''+user.id]);
    sampleAuthor.titles = sampleAuthor.titles.slice(0,2);
    let useName = sampleAuthor.name.slice(0,32);
    if (useName.length !== sampleAuthor.name.length)
      useName+='...';
    
    const displayContentPreviewText = getPreview(content,query);
    const displayContentPreview = htmlToReact.Parser().parse(displayContentPreviewText);

    let hideContent = '';
    let tagMax=3;
    let tagPos=0;


    return (
      <div className="compthreadnews-preview uk-card uk-card-default">
        <LinkV2 to={getForumThreadPage(id,title,addParamToLink,"#threadTop")}>
        {this.renderMedia(content)}
        </LinkV2>
        <div className="mainbody">
            <LinkV2 to={getForumThreadPage(id,title,addParamToLink,"#threadTop")}>
            <div className="thread-header">
                <h2 className="thread-title" data-uk-tooltip={title}>{useTitle} </h2> 
            </div>
            </LinkV2>
            <div className={"thread-content-section"} >
            <LinkV2 to={getForumThreadPage(id,title,addParamToLink,"#threadTop")}>
            <div className={"thread-content "+hideContent}>
            {displayContentPreview}
            </div></LinkV2>
            </div>
            <div className="thread-footer">
                <div className="thread-counters">
                <span className="thread-date">{timeSinceString(datecreated * 1000)}</span>
                </div>
                <div className="uk-flex uk-flex-row">
                {useTags.map((element, index) => {
                    if (element.properties.name.indexOf("lnkres_")!==-1)
                    return <></>;
                    tagPos++;
                    if (tagPos>tagMax)
                        return <></>;
                    return <ThreadTag name={element.properties.name} url={getForumTagPath(element.id,element.properties.name)}/>;
                })}
                </div>
            </div>
        </div>
        
      </div>
    );
  }
}

ThreadNewsPreview.propTypes = {
  query: PropTypes.string,
  thread: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    datecreated: PropTypes.number.isRequired,
    repliesAmount: PropTypes.number.isRequired,
    views: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      properties: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })).isRequired,
  }).isRequired,
};

ThreadNewsPreview.defaultProps = {
  query: ''
};

export default ThreadNewsPreview;